import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import { getDoc,setDoc,doc } from "firebase/firestore";
import { useNavigate } from "react-router";

export const OAuth = () => {
  const navigate = useNavigate();
async function onGoogleCLick(){
  try {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth,provider);
    const user = result.user;
    // console.log(user);

    //check for the user
    const docRef = doc(db,"users",user.uid);
    const docSnap = await getDoc(docRef);
    if(!docSnap.exists()){
      await setDoc(docRef,{
        name:user?.displayName,
        email:user?.email,
        timestamp:serverTimestamp()
      })
    }
    navigate("/")
  } catch (error) {
    toast.error("Could not authorize with google");
    // console.log(error)
  }
}




  return (
    <button type="button" onClick={onGoogleCLick} className="flex items-center justify-center w-full bg-red-700 text-white px-7 py-3 uppercase text-sm font-medium hover:bg-red-800 active:bg-red-900 shadow-md hover:shadow-lg active:shadow-lg transition duration-150 ease-in-out rounded">
      Continue with google <FcGoogle className="ml-2 text-2xl bg-white rounded-full"/>
    </button>
  );
};
