import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { Spinner } from "../components/Spinner";

const AllCollaboration = () => {
  const auth = getAuth();
  const [collaborationData, setCollaborationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setEmail(user.email);
      }
    });
    return unsubscribe;
  }, [auth]);

  useEffect(() => {
    const fetchCollaborations = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "collaboration"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCollaborationData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
      }
    };
    fetchCollaborations();
  }, []);

  const handleEdit = (id) => {
    // Implement your edit functionality here
    console.log(`Edit collaboration with ID: ${id}`);
  };

  const handleDelete = (id) => {
    // Implement your delete functionality here
    console.log(`Delete collaboration with ID: ${id}`);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl font-bold my-4 text-center">All Collaboration</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Collaboration Id</th>
              <th className="px-4 py-2 border">Partners</th>
              <th className="px-4 py-2 border">Duration</th>
              <th className="px-4 py-2 border">Zero Date</th>
              <th className="px-4 py-2 border">Signing Date</th>
              <th className="px-4 py-2 border">Expiring Date</th>
              <th className="px-4 py-2 border">Attachments</th>
              <tr>
            {/* ... (previous code remains unchanged) */}
            <th className="px-4 py-2 border">Actions</th>
          </tr>
            </tr>
          </thead>
          <tbody>
            {collaborationData.map((item) => (
              <tr key={item.id}>
                <td className="border px-4 py-2">{item.CollaborationId}</td>
                <td className=" border px-4 py-2">
                  {item.Partner &&
                    (Array.isArray(item.Partner)
                      ? item.Partner.map((partner, index) => (
                          <span key={index}>{partner}</span>
                        ))
                      : Object.keys(item.Partner)
                          .sort()
                          .map((key) => (
                            <span key={key}>
                              {key}: {item.Partner[key]}
                              <br />
                            </span>
                          )))}
                </td>
                <td className="border px-4 py-2">{item.Duration}</td>
                <td className="border px-4 py-2">{item.ZeroDate}</td>
                <td className="border px-4 py-2">{item.SigningDate}</td>
                <td className="border px-4 py-2">{item.ExpiringDate}</td>
                <td className="border px-4 py-2">
                  {item.CollaborationAttachmentsDocumentsUrls && (
                    <p>
                      Links{" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        {item.CollaborationAttachmentsDocumentsUrls.map(
                          (url, index) => (
                            <a
                              key={index}
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                              }}
                            >
                              <AiOutlineCloudDownload
                                style={{ display: "inline" }}
                                className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                              />
                            </a>
                          )
                        )}
                      </span>
                    </p>
                  )}
                </td>

                <td className="border px-4 py-2">
                <button
                  onClick={() => handleEdit(item.id)}
                  className="text-blue-500 hover:text-blue-700 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(item.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  Delete
                </button>
              </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllCollaboration;
