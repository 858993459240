import React, { useState } from "react";
import {
  getStorage,
  ref as storageReff,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase"; // Import your Firebase configuration
import { read as readExcel, utils as xlsxUtils } from "xlsx";

function UploadExcel() {
  const [excelFile, setExcelFile] = useState(null);

  const handleExcelFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setExcelFile(selectedFile);
  };

  // const handleExcelDataUpload = async () => {
  //   if (!excelFile) return;

  //   try {
  //     // Upload the Excel file to Firebase Storage
  //     const storageRef = storageReff(
  //       getStorage(),
  //       "excel_uploads/" + excelFile.name
  //     );
  //     await uploadBytes(storageRef, excelFile);

  //     // Get the downloaded Excel file data as an ArrayBuffer
  //     const fileData = await excelFile.arrayBuffer();

  //     // Process the Excel data
  //     const workbook = readExcel(fileData, { type: "array" });

  //     // Process the Excel data (assuming the data is in the first sheet)
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const jsonData = xlsxUtils.sheet_to_json(sheet);

  //     // Push the extracted data to the Firebase Realtime Database
  //     const dataRef = collection(db, "employee_details");
  //     jsonData.forEach(async (row) => {
  //       // Set the required fields from the Excel data
  //       const employeeData = {
  //         EmployeeID: row.EmployeeID,
  //         EmployeeName: row.EmployeeName,
  //         EmployeeEmail: row.EmployeeEmail,
  //         ContactNumber: row.ContactNumber,
  //         Designation: row.Designation,
  //         HighestQualification: row.HighestQualification,
  //         YearOfExperience: row.YearOfExperience,
  //         Department: row.Department,
  //         ReportingManager: row.ReportingManager,
  //         Basic: row.Basic,
  //         HRA: row.HRA,
  //         MedicalAllowance: row.MedicalAllowance,
  //         SpecialAllowance: row.SpecialAllowance,
  //         EmployeePfContribution: row.EmployeePfContribution,
  //         EmployerPfContribution: row.EmployerPfContribution,
  //         Gratuity: row.Gratuity,
  //         GroupInsurance: row.GroupInsurance,
  //         MedicalInsurance: row.MedicalInsurance,
  //         JoiningDate: row.JoiningDate,
  //         LeavingDate: row.LeavingDate,

  //         // ... (Add other fields based on your Excel structure)
  //       };

  //       const copyData ={
  //         ...employeeData,
  //         isSubmitted:false
  //       }
  //       try {
  //         // Add the employee data to the Firebase Realtime Database
  //         const docRef = await addDoc(dataRef, copyData);
  //         // console.log("Document written with ID: ", docRef.id);
  //       } catch (error) {
  //         console.error("Error adding document: ", error);
  //       }
  //     });

  //     // Success message
  //     toast.success("Data imported successfully.");
  //   } catch (error) {
  //     console.error("Error importing data:", error);
  //     toast.error("Failed to import data from the Excel file.");
  //   }
  // };

  // ...

  const handleExcelDataUpload = async () => {
    if (!excelFile) return;

    try {
      // Upload the Excel file to Firebase Storage
      const storageRef = storageReff(
        getStorage(),
        "excel_uploads/" + excelFile.name
      );
      await uploadBytes(storageRef, excelFile);

      // Get the downloaded Excel file data as an ArrayBuffer
      const fileData = await excelFile.arrayBuffer();

      // Process the Excel data
      const workbook = readExcel(fileData, { type: "array" });

      // Process the Excel data (assuming the data is in the first sheet)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = xlsxUtils.sheet_to_json(sheet);

      // Push the extracted data to the Firebase Realtime Database
      const dataRef = collection(db, "employee_details");
      jsonData.forEach(async (row) => {
        // Set the required fields from the Excel data
        const employeeData = {
          EmployeeID: row.EmployeeID || "",
          EmployeeName: row.EmployeeName || "",
          EmployeeEmail: row.EmployeeEmail || "",
          ContactNumber: row.ContactNumber || "",
          Designation: row.Designation || "",
          HighestQualification: row.HighestQualification || "",
          YearOfExperience: row.YearOfExperience || "",
          Department: row.Department || "",
          ReportingManager: row.ReportingManager || "",
          Basic: row.Basic || "",
          HRA: row.HRA || "",
          MedicalAllowance: row.MedicalAllowance || "",
          SpecialAllowance: row.SpecialAllowance || "",
          EmployeePfContribution: row.EmployeePfContribution || "",
          EmployerPfContribution: row.EmployerPfContribution || "",
          Gratuity: row.Gratuity || "",
          GroupInsurance: row.GroupInsurance || "",
          MedicalInsurance: row.MedicalInsurance || "",
          JoiningDate: row.JoiningDate || "",
          LeavingDate: row.LeavingDate || "",
          isSubmitted: false,
          // ... (Add other fields based on your Excel structure)
        };

        try {
          // Add the employee data to the Firebase Realtime Database
          const docRef = await addDoc(dataRef, employeeData);
          // console.log("Document written with ID: ", docRef.id);
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      });

      // Success message
      toast.success("Data imported successfully.");
    } catch (error) {
      console.error("Error importing data:", error);
      toast.error("Failed to import data from the Excel file.");
    }
  };

  return (
    <main className="max-w-5xl px-3 mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">Upload Excel</h1>
      <form>
        {/* File input for Excel file selection */}
        <div className="w-full">
          <input
            type="file"
            accept=".xlsx"
            onChange={handleExcelFileChange}
            className="w-md py-2 border border-gray-300 rounded transition duration-150 ease-in-out focus:border-slate-600 mb-6"
          />
          <button
            type="button"
            onClick={handleExcelDataUpload}
            className="mb-6 w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            Upload Excel Data
          </button>
        </div>
      </form>
    </main>
  );
}

export default UploadExcel;