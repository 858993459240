import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  deleteDoc,
  updateDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import {
  getStorage,
  ref as storageReff,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  listAll,
} from "firebase/storage";

import { v4 as uuidv4 } from "uuid";

import XLSX from "xlsx/dist/xlsx.full.min.js";

import Swal from "sweetalert2";

import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

import { AiOutlineCloudDownload } from "react-icons/ai";
import { BsPencil, BsTrash } from "react-icons/bs";
import { Spinner } from "../components/Spinner";
import { toast } from "react-toastify";

import { getAuth } from "firebase/auth";
import DownloadProjects from "../components/DownloadProjects";

const ProjectsDashboard = () => {
  const auth = getAuth();

  const [projectData, setProjectData] = useState([]);
  const [email, setEmail] = useState(null);

  const [proposalAttachmentsDocuments, setProposalAttachmentsDocuments] =
    useState([]);
  const [conceptNotesDocuments, setConceptNotesDocuments] = useState([]);
  const [grantAgreementsDocuments, setGrantAgreementsDocuments] = useState([]);
  const [budgetsDocuments, setBudgetsDocuments] = useState([]);
  // const [loading, setLoading] = useState(false);

  const proposalAttachmentsDocumentsNames = [];
  const proposalAttachmentsDocumentsUrls = [];

  const conceptNotesAttachmentsDocumentsNames = [];
  const conceptNotesAttachmentsDocumentsUrls = [];

  const grantAgreementAttachmentsDocumentsNames = [];
  const grantAgreementAttachmentsDocumentsUrls = [];

  const budgetDocumentsNames = [];
  const budgetDocumentsUrls = [];

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setEmail(user?.email);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchprojectData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "project_details"));
        const data = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        const submittedProjects = data.filter(
          (project) => !project.isSubmitted
        );

        setProjectData(submittedProjects);
        // setProjectData(data);
        // console.log(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
      }
    };

    fetchprojectData();
  }, []);

  const itemsPerPage = 3;
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  // const [proposalAttachment, setProposalAttachment] = useState(null);
  // const [conceptNote, setConceptNote] = useState(null);
  // const [grantAgreement, setGrantAgreement] = useState(null);
  // const [budget, setBudget] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [isDownloadExcelClicked, setIsDownloadExcelClicked] = useState(false);

  // console.log(projectData);

  const filteredData = projectData
    .map((project) => {
      if (
        project.ProjectName &&
        project.ProjectName.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return project;
      } else if (
        project.ProjectId &&
        project.ProjectId.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return project;
      } else if (
        project.ProjectManager &&
        project.ProjectManager.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return project;
      }

      return null;
    })
    .filter((project) => project !== null);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the index range of projects to be displayed on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Function to handle search term change
  const handleSearchTermChange = (event) => {
    setCurrentPage(1); // Reset to first page when search term changes
    setSearchTerm(event.target.value);
  };

  // Slice the filteredData array based on the current page
  const projectsToShow = filteredData.slice(startIndex, endIndex);

  async function handleEditProject(
    id,
    projectId,
    BudgetName,
    BudgetURL,
    ProposalAttachmentName,
    ProposalAttachmentURL,
    conceptNoteName,
    conceptNoteURL,
    grantAgreementName,
    grantAgreementURL
  ) {
    localStorage.setItem("IdOfDataToBeEdited", id);
    localStorage.setItem("projectIdOfDataToBeEdited", projectId);
    localStorage.setItem("BudgetName", BudgetName);
    localStorage.setItem("BudgetURL", BudgetURL);
    localStorage.setItem("ProposalAttachmentName", ProposalAttachmentName);
    localStorage.setItem("ProposalAttachmentURL", ProposalAttachmentURL);
    localStorage.setItem("conceptNoteName", conceptNoteName);
    localStorage.setItem("conceptNoteURL", conceptNoteURL);
    localStorage.setItem("grantAgreementName", grantAgreementName);
    localStorage.setItem("grantAgreementURL", grantAgreementURL);
    try {
      const docRef = doc(db, "project_details", projectId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const projectData = docSnap.data();
        setDataToEdit(projectData);
        // console.log("Project data:", projectData);
        setIsEditing(true);
      } else {
        // console.log("Project not found");
      }
    } catch (error) {
      console.error("Failed to fetch Project data:", error);
    }
  }

  async function handleDeleteProject(Id, projectID, projectName) {
    // console.log(Id, projectID);

    try {
      setLoading(true); // Set isLoading to true

      const activity = {
        action: "delete",
        nameOfLoggedInUser: auth.currentUser.displayName,
        dateAndTime: serverTimestamp(),
        forWhat: "ProjectBeforeSubmit",
        ID: projectID,
        Name: projectName,
      };

      await addDoc(collection(db, "activity"), activity);

      // Log project reference
      const projectRef = doc(db, "project_details", Id);
      const projectSnapshot = await getDoc(projectRef);
      const projectData = projectSnapshot.data();

      const reportingTimelineKeys = Object.keys(projectData).filter(
        (key) =>
          key.startsWith("ReportingTimeline") &&
          Object.keys(projectData[key]).length > 0
      );

      if (reportingTimelineKeys.length > 1) {
        await Swal.fire({
          title: "Please delete the reporting attachment",
          text: "You cannot delete the project until the reporting attachment is deleted.",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }

      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this project?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (!confirmation.isConfirmed) {
        setLoading(false); // Set isLoading to false
        return;
      }

      // Show file deletion confirmation alert
      const fileDeletionConfirmation = await Swal.fire({
        title: "Are you sure?",
        text: "Deleted files cannot be retrieved. Are you sure you want to proceed?",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Yes, delete them!",
        cancelButtonText: "Cancel",
      });

      if (!fileDeletionConfirmation.isConfirmed) {
        setLoading(false); // Set isLoading to false
        return;
      }

      // Delete project document
      await deleteDoc(projectRef);
      setProjectData((prevData) =>
        prevData.filter((project) => project.id !== Id)
      );

      const storage = getStorage();
      const subfolders = [
        "budgetDocuments/",
        "conceptNotesAttachments/",
        "grantAgreementAttachments/",
        "proposalAttachments/",
      ];

      for (const subfolder of subfolders) {
        const storageRef = storageReff(storage, `${projectID}/${subfolder}`);
        await deleteFolder(storageRef);
        // console.log(`Deleted ${subfolder} folder and its contents`);
      }

      toast.success("Project deleted successfully");
      // console.log("Project deleted successfully:", Id);
    } catch (error) {
      console.error("Failed to delete project:", error);
    } finally {
      setLoading(false); // Set isLoading to false in the finally block
    }
  }

  async function deleteFolder(storageRef) {
    const listResult = await listAll(storageRef);
    const fileDeletions = listResult.items.map((item) => deleteObject(item));
    await Promise.all(fileDeletions);
    const subfolderDeletions = listResult.prefixes.map((prefix) =>
      deleteFolder(prefix)
    );
    await Promise.all(subfolderDeletions);
  }

  const UploadEditedDataToDatabase = async (e) => {
    const projectIdOfDataToBeEdited = localStorage.getItem(
      "projectIdOfDataToBeEdited"
    );

    const IdOfDataToBeEdited = localStorage.getItem("IdOfDataToBeEdited");

    await handleUpload();

    e.preventDefault();
    setLoading(true);

    const dataToUpload = {
      ...dataToEdit,
      Total: calculateTotal(),
    };

    if (proposalAttachmentsDocumentsNames.length > 0) {
      dataToUpload.proposalAttachmentsDocumentsNames =
        proposalAttachmentsDocumentsNames;
      dataToUpload.proposalAttachmentsDocumentsUrls =
        proposalAttachmentsDocumentsUrls;
    }

    if (grantAgreementAttachmentsDocumentsNames.length > 0) {
      dataToUpload.grantAgreementAttachmentsDocumentsNames =
        grantAgreementAttachmentsDocumentsNames;
      dataToUpload.grantAgreementAttachmentsDocumentsUrls =
        grantAgreementAttachmentsDocumentsUrls;
    }

    if (conceptNotesAttachmentsDocumentsNames.length > 0) {
      dataToUpload.conceptNotesAttachmentsDocumentsNames =
        conceptNotesAttachmentsDocumentsNames;
      dataToUpload.conceptNotesAttachmentsDocumentsUrls =
        conceptNotesAttachmentsDocumentsUrls;
    }

    if (budgetDocumentsNames.length > 0) {
      dataToUpload.budgetDocumentsNames = budgetDocumentsNames;
      dataToUpload.budgetDocumentsUrls = budgetDocumentsUrls;
    }

    try {
      console.log(dataToEdit);

      const activity = {
        action: "edit",
        nameOfLoggedInUser: auth.currentUser.displayName,
        dateAndTime: serverTimestamp(),
        forWhat: "ProjectBeforeSubmit",
        ID: dataToEdit.ProjectId,
        Name: dataToEdit.ProjectName,
      };

      await addDoc(collection(db, "activity"), activity);

      const docRef = doc(db, "project_details", projectIdOfDataToBeEdited);
      await updateDoc(docRef, dataToUpload);
      setDataToEdit({});
      setIsEditing(false);
      localStorage.removeItem("projectIdOfDataToBeEdited");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error("Failed to upload edited project data:", error);
    }
  };

  const handleAddField = (e) => {
    e.preventDefault();
    const newKey = `ReportingTimeline${
      Object.keys(dataToEdit.ReportingTimeline).length + 1
    }`;
    setDataToEdit({
      ...dataToEdit,
      ReportingTimeline: {
        ...dataToEdit.ReportingTimeline,
        [newKey]: "",
      },
    });
  };

  const handleDeleteField = (e) => {
    e.preventDefault();
    const keys = Object.keys(dataToEdit.ReportingTimeline);
    const highestKey = keys[keys.length - 1];

    if (keys.length > 0) {
      const updatedTimeline = { ...dataToEdit.ReportingTimeline };
      delete updatedTimeline[highestKey];

      setDataToEdit({
        ...dataToEdit,
        ReportingTimeline: updatedTimeline,
      });
    }
  };

  const handleChange = (e, key) => {
    setDataToEdit({
      ...dataToEdit,
      ReportingTimeline: {
        ...dataToEdit.ReportingTimeline,
        [key]: e.target.value,
      },
    });
  };

  const FinalSubmit = async (id, projectid, projectname) => {
    console.log(id);

    const IdToEdit = id;
    try {
      const activity = {
        action: "submit",
        nameOfLoggedInUser: auth.currentUser.displayName,
        dateAndTime: serverTimestamp(),
        forWhat: "ProjectBeforeSubmit",
        ID: projectid,
        Name: projectname,
      };

      await addDoc(collection(db, "activity"), activity);
      const docRef = doc(db, "project_details", IdToEdit);
      const dataToUpload = {
        isSubmitted: true,
      };
      await updateDoc(docRef, dataToUpload);
      window.location.reload();
    } catch (error) {
      console.error("Failed to upload edited project data:", error);
    }
  };

  const handleFileChange = (event, setDocuments) => {
    const files = event.target.files;
    const documentsArray = Array.from(files);
    setDocuments((prevDocuments) => [...prevDocuments, ...documentsArray]);
  };

  const handleUpload = async () => {
    try {
      setLoading(true);

      const storage = getStorage();

      // -----------------
      const IdOfDataToBeEdited = localStorage.getItem("IdOfDataToBeEdited");
      const storageRef = storageReff(storage, `${IdOfDataToBeEdited}`);

      const deleteFilesInFolder = async (folderName, documentsArray) => {
        if (documentsArray.length === 0) {
          // If the array is empty, no need to delete the folder
          return;
        }

        // Create a reference to the specified folder
        const folderRef = storageReff(storageRef, folderName);

        // List all files in the folder
        const listResult = await listAll(folderRef);

        // Delete each file in the folder
        await Promise.all(
          listResult.items.map(async (item) => {
            await deleteObject(item); // Delete the file
          })
        );
      };

      // ...

      // Modify the deleteFilesInFolder calls to pass the corresponding documents arrays
      await deleteFilesInFolder(
        "proposalAttachments",
        proposalAttachmentsDocuments
      );
      await deleteFilesInFolder(
        "conceptNotesAttachments",
        conceptNotesDocuments
      );
      await deleteFilesInFolder(
        "grantAgreementAttachments",
        grantAgreementsDocuments
      );
      await deleteFilesInFolder("budgetDocuments", budgetsDocuments);

      // ...

      const uploadDocument = async (documents, folderName, namesArray) => {
        const uploadPromises = documents.map(async (file) => {
          const fileId = uuidv4();
          const fileExtension = file.name.split(".").pop();
          const fileName = `${fileId}.${fileExtension}`;
          const storageRef = storageReff(
            storage,
            `${IdOfDataToBeEdited}/${folderName}/${fileName}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Calculate the percentage of the upload
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const message = `Uploading ${file.name}: ${progress.toFixed(
                  2
                )}%`;
              },
              (error) => {
                console.error(`Error uploading ${folderName} document:`, error);
                reject(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref)
                  .then((downloadURL) => {
                    // Store the file name and URL in the corresponding variables
                    namesArray.push(fileName);
                    if (folderName === "proposalAttachments") {
                      proposalAttachmentsDocumentsUrls.push(downloadURL);
                    } else if (folderName === "conceptNotesAttachments") {
                      conceptNotesAttachmentsDocumentsUrls.push(downloadURL);
                    } else if (folderName === "grantAgreementAttachments") {
                      grantAgreementAttachmentsDocumentsUrls.push(downloadURL);
                    } else if (folderName === "budgetDocuments") {
                      budgetDocumentsUrls.push(downloadURL);
                    }

                    resolve();
                  })
                  .catch((error) => {
                    console.error("Error getting download URL:", error);
                    reject(error);
                  });
              }
            );
          });
        });

        await Promise.all(uploadPromises);
      };

      // Upload proposalAttachmentsDocuments
      await uploadDocument(
        proposalAttachmentsDocuments,
        "proposalAttachments",
        proposalAttachmentsDocumentsNames
      );

      // Upload conceptNotesDocuments
      await uploadDocument(
        conceptNotesDocuments,
        "conceptNotesAttachments",
        conceptNotesAttachmentsDocumentsNames
      );

      // Upload grantAgreementsDocuments
      await uploadDocument(
        grantAgreementsDocuments,
        "grantAgreementAttachments",
        grantAgreementAttachmentsDocumentsNames
      );

      // Upload budgetsDocuments
      await uploadDocument(
        budgetsDocuments,
        "budgetDocuments",
        budgetDocumentsNames
      );

      // Reset the input fields to null
      setProposalAttachmentsDocuments([]);
      setConceptNotesDocuments([]);
      setGrantAgreementsDocuments([]);
      setBudgetsDocuments([]);

      toast.success("Documents uploaded successfully!");
    } catch (error) {
      console.error("Error uploading documents:", error);
      toast.error("Error uploading documents");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  // Function to calculate the total budget
  const calculateTotal = () => {
    let sum = 0;
    dataToEdit.budget.forEach((item) => {
      sum += parseFloat(item.value);
    });
    return sum;
  };

  // Function to add a new budget field
  const addBudgetField = () => {
    setDataToEdit({
      ...dataToEdit,
      budget: [...dataToEdit.budget, { name: "", value: "0" }],
    });
  };

  const deleteBudgetField = (index) => {
    const updatedBudget = [...dataToEdit.budget];
    updatedBudget.splice(index, 1);
    setDataToEdit({
      ...dataToEdit,
      budget: updatedBudget,
    });
  };

  const handleNoCostExtensionDateChange = (e, index) => {
    const updatedDates = [...dataToEdit.no_cost_extension_dates];
    updatedDates[index] = e.target.value;
    setDataToEdit({ ...dataToEdit, no_cost_extension_dates: updatedDates });
  };

  const handleRemoveNoCostExtensionDate = (index) => {
    const updatedDates = [...dataToEdit.no_cost_extension_dates];
    updatedDates.splice(index, 1);
    setDataToEdit({ ...dataToEdit, no_cost_extension_dates: updatedDates });
  };

  const handleAddNoCostExtensionDate = () => {
    const updatedDates = [...(dataToEdit.no_cost_extension_dates || []), ""];
    setDataToEdit({ ...dataToEdit, no_cost_extension_dates: updatedDates });
  };

  return (
    <div>
      <h1 className="text-3xl text-center mt-6 m-6 font-bold">
        All Projects Before Submit
      </h1>

      {/* Search bar */}
      {projectsToShow.length >= 0 && (
        <div className="flex justify-start mb-4">
          {/* <div>
            <button
              onClick={() => generateExcel()}
              className="bg-blue-500 text-white py-2 px-4 rounded ml-2"
            >
              Download excel
            </button>
          </div> */}
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Search by Project Name, projectID or Project incharge"
            className="px-2 ml-auto mr-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 w-full sm:w-1/2 lg:w-3/10 xl:w-1/3"
          />
        </div>
      )}

      {isDownloadExcelClicked && (
        <div className="fixed inset-0 flex items-center justify-center z-50 ">
          <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
          <div className="bg-white p-6 rounded-lg shadow-md relative">
            <button
              className="absolute top-0 right-0 m-2 text-red-900 hover:text-gray-800 z-10"
              onClick={() => setIsDownloadExcelClicked(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 11-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            {/* Your DownloadEmployee component content */}
            {/* Make sure it doesn't have a position that causes it to be behind the modal background */}
            <div className="mb-6 mt-10">
              <DownloadProjects />
            </div>
          </div>
        </div>
      )}

      {projectsToShow.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y mx-auto divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Project Details
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Reporting Timeline
                </th>
                {(email == "rachit@vasudhaindia.org" ||
                  email == "srinivas@vasudhaindia.org" ||
                  email == "it@vasudhaindia.org" ||
                  email == "amar@vasudhaindia.org" ||
                  email == "mohit@vasudhaindia.org" ||
                  email == "preethi@vasudhaindia.org") && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Budget
                  </th>
                )}

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Project Incharge
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Attachments
                </th>

                {email == "srinivas@vasudhaindia.org" && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {projectsToShow.map((project, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <p className="text-sm text-gray-900">
                      Project Name : {project.ProjectName}
                    </p>
                    <p className="text-sm text-gray-900">
                      Project Id : {project.ProjectId}
                    </p>
                    <p className="text-sm text-gray-900">
                      Proposal Id : {project.ProposalId}
                    </p>
                    <p className="text-sm text-gray-900">
                      Start Date : {project.StartDate}
                    </p>
                    <p className="text-sm text-gray-900">
                      End Date : {project.EndDate}
                    </p>
                    <p className="text-sm text-gray-900">
                      Project Status: {project.ProjectStatus}
                    </p>

                    <p className="text-sm text-gray-900">
                      Project Funder Name: {project.FunderName}
                    </p>

                    {project.no_cost_extension_dates &&
                      project.no_cost_extension_dates.length > 0 && (
                        <p className="text-sm text-gray-900">
                          Last No Cost Extension Date :{" "}
                          {
                            project.no_cost_extension_dates[
                              project.no_cost_extension_dates.length - 1
                            ]
                          }
                        </p>
                      )}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    {Object.keys(project.ReportingTimeline)
                      .sort()
                      .map((key, index) => (
                        <div key={key}>
                          <p className="text-sm text-gray-900">
                            Reporting Timeline {index + 1}:{" "}
                            {project.ReportingTimeline[key]}
                          </p>
                        </div>
                      ))}
                  </td>

                  {(email == "rachit@vasudhaindia.org" ||
                    email == "srinivas@vasudhaindia.org" ||
                    email == "it@vasudhaindia.org" ||
                    email == "amar@vasudhaindia.org" ||
                    email == "mohit@vasudhaindia.org" ||
                    email == "preethi@vasudhaindia.org") && (
                    <td className="px-6 py-4 whitespace-nowrap">
                      {/* <p className="text-sm text-gray-900">Professional Fees:</p> */}
                      {project.budget.map((item, index) => (
                        <div key={index}>
                          <p className="text-sm text-gray-900">{`${item.name}: ${item.value} ${project.currency}`}</p>
                        </div>
                      ))}
                      <p className="text-sm">
                        {" "}
                        Total: {project.Total} {project.currency}
                      </p>
                    </td>
                  )}

                  <td className="px-6 py-4 whitespace-nowrap">
                    <p className="text-sm text-gray-900">
                      {project.ProjectManager}
                    </p>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    <p
                      className="text-sm text-gray-900"
                      style={{ display: "inline" }}
                    >
                      Proposal attachments:
                      {project.proposalAttachmentsDocumentsUrls.map(
                        (url, index) => (
                          <a
                            key={index}
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <AiOutlineCloudDownload
                              style={{ display: "inline" }}
                              className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                            />
                          </a>
                        )
                      )}
                    </p>

                    <br />
                    <p
                      className="text-sm text-gray-900"
                      style={{ display: "inline" }}
                    >
                      Grant Agreement attachments:
                      {project.grantAgreementAttachmentsDocumentsUrls.map(
                        (url, index) => (
                          <a
                            key={index}
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <AiOutlineCloudDownload
                              style={{ display: "inline" }}
                              className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                            />
                          </a>
                        )
                      )}
                    </p>

                    <br />
                    <p
                      className="text-sm text-gray-900"
                      style={{ display: "inline" }}
                    >
                      Concept Notes attachments:
                      {project.conceptNotesAttachmentsDocumentsUrls.map(
                        (url, index) => (
                          <a
                            key={index}
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <AiOutlineCloudDownload
                              style={{ display: "inline" }}
                              className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                            />
                          </a>
                        )
                      )}
                    </p>

                    <br />
                    {(email == "rachit@vasudhaindia.org" ||
                      email == "srinivas@vasudhaindia.org" ||
                      email == "it@vasudhaindia.org" ||
                      email == "amar@vasudhaindia.org" ||
                      email == "mohit@vasudhaindia.org" ||
                      email == "preethi@vasudhaindia.org") && (
                      <p
                        className="text-sm text-gray-900"
                        style={{ display: "inline" }}
                      >
                        Budget Document attachments:
                        {project.budgetDocumentsUrls.map((url, index) => (
                          <a
                            key={index}
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <AiOutlineCloudDownload
                              style={{ display: "inline" }}
                              className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                            />
                          </a>
                        ))}
                      </p>
                    )}
                  </td>

                  {/* <td className="px-6 py-4 whitespace-nowrap">
                    <p className="text-sm text-gray-900">{project.id}</p>
                  </td> */}

                  {(email == "srinivas@vasudhaindia.org" ||
                    email == "rachit@vasudhaindia.org" ||
                    email == "amar@vasudhaindia.org" ||
                    email == "mohit@vasudhaindia.org" ||
                    email == "it@vasudhaindia.org") && (
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <button
                          className="text-blue-600 hover:text-blue-900 mr-2"
                          onClick={() =>
                            handleEditProject(
                              project.ProjectId,
                              project.id,
                              project.BudgetName,
                              project.BudgetURL,
                              project.ProposalAttachmentName,
                              project.ProposalAttachmentURL,
                              project.conceptNoteName,
                              project.conceptNoteURL,
                              project.grantAgreementName,
                              project.grantAgreementURL
                            )
                          }
                        >
                          <BsPencil />
                        </button>
                        <button
                          className="text-red-600 hover:text-red-900"
                          onClick={() =>
                            handleDeleteProject(
                              project.id,
                              project.ProjectId,
                              project.ProjectName
                            )
                          }
                        >
                          <BsTrash />
                        </button>
                        <button
                          className="bg-blue-500 text-white py-2 px-4 rounded ml-2"
                          onClick={() =>
                            FinalSubmit(
                              project.id,
                              project.ProjectId,
                              project.ProjectName
                            )
                          }
                        >
                          SUBMIT
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-lg">No Project found.</p>
      )}

      {/* popup */}
      {isEditing && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg overflow-y-auto overflow-x-auto max-h-96">
            <h2 className="text-lg font-bold mb-4">Edit Project</h2>
            <form>
              {/* Render the input fields here */}

              <div className="flex flex-col md:flex-row">
                <div className="mr-1 flex-1">
                  <p className="text-l mt-6 font-semibold">Proposal ID</p>
                  <input
                    type="text"
                    id="ProposalId"
                    value={dataToEdit.ProposalId}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        ProposalId: e.target.value,
                      })
                    }
                    placeholder="Proposal ID"
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>

                <div className="mr-1 flex-1">
                  <p className="text-l mt-6 font-semibold">Funder Name</p>
                  <input
                    type="text"
                    id="FunderName"
                    value={dataToEdit.FunderName}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        FunderName: e.target.value,
                      })
                    }
                    placeholder="Proposal ID"
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>

                <div className="ml-1 flex-1">
                  <p className="text-l mt-6 font-semibold">Project ID</p>
                  <input
                    type="text"
                    id="ProjectId"
                    value={dataToEdit.ProjectId}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        ProjectId: e.target.value,
                      })
                    }
                    placeholder="Project ID"
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>

                <div className="ml-1 flex-1">
                  <p className="text-l mt-6 font-semibold">Project Name</p>
                  <input
                    type="text"
                    id="ProjectName"
                    value={dataToEdit.ProjectName}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        ProjectName: e.target.value,
                      })
                    }
                    placeholder="Project Name"
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>

                <div className="ml-1 flex-1">
                  <p className="text-l mt-6 font-semibold">Start Date</p>
                  <input
                    type="date"
                    id="StartDate"
                    value={dataToEdit.StartDate}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        StartDate: e.target.value,
                      })
                    }
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>

                <div className="ml-1 flex-1">
                  <p className="text-l mt-6 font-semibold">End Date</p>
                  <input
                    type="date"
                    id="EndDate"
                    value={dataToEdit.EndDate}
                    onChange={(e) =>
                      setDataToEdit({ ...dataToEdit, EndDate: e.target.value })
                    }
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>
              </div>

              {/* ---------extension dates----------- */}

              <div className="flex flex-wrap">
                {dataToEdit.no_cost_extension_dates?.map((date, index) => (
                  <div className="ml-1 flex-1 w-1/5" key={index}>
                    <p className="text-l mt-6 font-semibold">
                      No Cost Extension Date - {index + 1}
                    </p>
                    <div className="flex items-center">
                      <input
                        type="date"
                        id={`no_cost_extension_date-${index}`}
                        value={date}
                        onChange={(e) =>
                          handleNoCostExtensionDateChange(e, index)
                        }
                        className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                      />
                      {index >= 0 && (
                        <AiOutlineMinusCircle
                          className="inline-block text-red-600 ml-2 cursor-pointer"
                          onClick={() => handleRemoveNoCostExtensionDate(index)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div>
                <AiOutlinePlusCircle
                  className="inline-block text-green-600 cursor-pointer"
                  onClick={handleAddNoCostExtensionDate}
                />
              </div>

              {/* Reporting timeline */}
              <div>
                <p className="text-l font-semibold">Reporting Timeline</p>

                <div className="flex flex-wrap">
                  {Object.keys(dataToEdit.ReportingTimeline).map((key) => (
                    <div key={key} className="w-full md:w-1/4 pt-1 pr-1">
                      <p className="text-sm font-semibold">{key}</p>
                      <input
                        type="date"
                        id={key}
                        value={dataToEdit.ReportingTimeline[key]}
                        onChange={(e) => handleChange(e, key)}
                        placeholder={`Enter value for ${key}`}
                        className="w-full p-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
                      />
                    </div>
                  ))}
                </div>

                <button onClick={handleDeleteField}>
                  {" "}
                  <AiOutlineMinusCircle className="text-4xl border rounded-full bg-red-500" />
                </button>
                <button onClick={handleAddField}>
                  {" "}
                  <AiOutlinePlusCircle className="text-4xl border rounded-full bg-white" />
                </button>
              </div>

              {/* Budget */}

              <div className="mt-6">
                <p className="text-l font-semibold">Budget</p>
                <select
                  value={dataToEdit.currency}
                  onChange={(e) =>
                    setDataToEdit({
                      ...dataToEdit,
                      currency: e.target.value,
                    })
                  }
                  className="w-4/12 px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                >
                  <option value="">--Choose Currency--</option>
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                  <option value="EURO">EURO</option>
                  {/* Add more currency options as needed */}
                </select>
                {dataToEdit.budget.map((item, index) => (
                  <div key={index} className="flex">
                    <div className="">
                      <input
                        type="text"
                        value={item.name}
                        onChange={(e) =>
                          setDataToEdit({
                            ...dataToEdit,
                            budget: dataToEdit.budget.map((b, i) =>
                              i === index ? { ...b, name: e.target.value } : b
                            ),
                          })
                        }
                        placeholder="Name"
                        className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                      />
                    </div>

                    <div className="ml-1">
                      <input
                        type="number"
                        value={item.value}
                        onChange={(e) =>
                          setDataToEdit({
                            ...dataToEdit,
                            budget: dataToEdit.budget.map((b, i) =>
                              i === index ? { ...b, value: e.target.value } : b
                            ),
                          })
                        }
                        placeholder="Value"
                        className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                      />
                    </div>

                    {dataToEdit.budget.length > 1 && (
                      <button
                        type="button"
                        onClick={() => deleteBudgetField(index)}
                        className="rounded ml-1"
                        title="Delete this Budget"
                      >
                        <AiOutlineMinusCircle className="text-4xl mb-6 text-white border rounded-full bg-red-500 hover:bg-red-700" />
                      </button>
                    )}
                  </div>
                ))}
                <div className="flex items-center mb-6">
                  <input
                    value={`Total: ${calculateTotal()} ${dataToEdit.currency}`}
                    className="w-md px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mr-2"
                  />
                  <button
                    onClick={addBudgetField}
                    type="button"
                    title="Add more Budgets"
                    className="py-2"
                  >
                    <AiOutlinePlusCircle className="text-white hover:bg-blue-700 text-4xl border rounded-full bg-blue-500" />
                  </button>
                </div>
              </div>

              {/* Project manager */}

              <p className="text-l font-semibold">Project Incharge</p>
              <input
                type="text"
                id="ProjectManager"
                value={dataToEdit.ProjectManager}
                onChange={(e) =>
                  setDataToEdit({
                    ...dataToEdit,
                    ProjectManager: e.target.value,
                  })
                }
                placeholder="Project Incharge"
                className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
              />

              <td className="p py-4 whitespace-nowrap">
                <p
                  className="text-sm text-gray-900"
                  style={{ display: "inline" }}
                >
                  Proposal attachments:
                  {dataToEdit.proposalAttachmentsDocumentsUrls.map(
                    (url, index) => (
                      <a
                        key={index}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiOutlineCloudDownload
                          style={{ display: "inline" }}
                          className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                        />
                      </a>
                    )
                  )}
                </p>

                <br />
                <p
                  className="text-sm text-gray-900"
                  style={{ display: "inline" }}
                >
                  Grant Agreement attachments:
                  {dataToEdit.grantAgreementAttachmentsDocumentsUrls.map(
                    (url, index) => (
                      <a
                        key={index}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiOutlineCloudDownload
                          style={{ display: "inline" }}
                          className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                        />
                      </a>
                    )
                  )}
                </p>

                <br />
                <p
                  className="text-sm text-gray-900"
                  style={{ display: "inline" }}
                >
                  Concept Notes attachments:
                  {dataToEdit.conceptNotesAttachmentsDocumentsUrls.map(
                    (url, index) => (
                      <a
                        key={index}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiOutlineCloudDownload
                          style={{ display: "inline" }}
                          className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                        />
                      </a>
                    )
                  )}
                </p>

                <br />
                <p
                  className="text-sm text-gray-900"
                  style={{ display: "inline" }}
                >
                  Budget Document attachments:
                  {dataToEdit.budgetDocumentsUrls.map((url, index) => (
                    <a
                      key={index}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AiOutlineCloudDownload
                        style={{ display: "inline" }}
                        className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                      />
                    </a>
                  ))}
                </p>
              </td>

              <>
                <div className="flex flex-wrap mb-4">
                  <div className="w-1/2 pr-2 mb-4">
                    <p className="mb-2">Proposal Attachments</p>
                    <input
                      className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                      type="file"
                      multiple
                      onChange={(e) =>
                        handleFileChange(e, setProposalAttachmentsDocuments)
                      }
                    />
                    {/* Render the uploaded proposal attachments files */}
                    {proposalAttachmentsDocuments.map((file) => (
                      <div key={file.name}>{file.name}</div>
                    ))}
                  </div>

                  <div className="w-1/2 pl-2 mb-4">
                    <h2 className="mb-2">Concept Notes</h2>
                    <input
                      className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                      type="file"
                      multiple
                      onChange={(e) =>
                        handleFileChange(e, setConceptNotesDocuments)
                      }
                    />
                    {/* Render the uploaded concept notes files */}
                    {conceptNotesDocuments.map((file) => (
                      <div key={file.name}>{file.name}</div>
                    ))}
                  </div>
                </div>

                <div className="flex flex-wrap mb-4">
                  <div className="w-1/2 pr-2 mb-4">
                    <h1 className="mb-2">Grant Agreements</h1>
                    <input
                      className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                      type="file"
                      multiple
                      onChange={(e) =>
                        handleFileChange(e, setGrantAgreementsDocuments)
                      }
                    />
                    {/* Render the uploaded grant agreements files */}
                    {grantAgreementsDocuments.map((file) => (
                      <div key={file.name}>{file.name}</div>
                    ))}
                  </div>

                  <div className="w-1/2 pl-2 mb-4">
                    <h1 className="mb-2">Budgets</h1>
                    <input
                      className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                      type="file"
                      multiple
                      onChange={(e) => handleFileChange(e, setBudgetsDocuments)}
                    />
                    {/* Render the uploaded budgets files */}
                    {budgetsDocuments.map((file) => (
                      <div key={file.name}>{file.name}</div>
                    ))}
                  </div>
                </div>
              </>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 mr-2 text-gray-600 border rounded hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={UploadEditedDataToDatabase}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <br></br>
      <br></br>
      <hr></hr>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="fixed bottom-1 flex w-full justify-center mt-4">
          <nav className="inline-flex rounded-md shadow">
            <ul className="flex space-x-2">
              {/* Previous page button */}
              <li>
                <button
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  className={`px-3 py-1 rounded-md ${
                    currentPage === 1
                      ? "bg-gray-200 cursor-not-allowed"
                      : "bg-white hover:bg-gray-100"
                  }`}
                >
                  Previous
                </button>
              </li>

              {/* Page buttons */}
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (pageNumber) => (
                  <li key={pageNumber}>
                    <button
                      onClick={() => handlePageChange(pageNumber)}
                      className={`px-3 py-1 rounded-md ${
                        currentPage === pageNumber
                          ? "bg-gray-200 cursor-not-allowed"
                          : "bg-white hover:bg-gray-100"
                      }`}
                    >
                      {pageNumber}
                    </button>
                  </li>
                )
              )}

              {/* Next page button */}
              <li>
                <button
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                  className={`px-3 py-1 rounded-md ${
                    currentPage === totalPages
                      ? "bg-gray-200 cursor-not-allowed"
                      : "bg-white hover:bg-gray-100"
                  }`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default ProjectsDashboard;
