import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  updateDoc,
  doc,
  setDoc,
  getDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import { Spinner } from "../components/Spinner";
import { getAuth } from "firebase/auth";


const ProjectWiseOverviewBeforeSubmit = () => {
  const auth = getAuth();

  const [loading, setLoading] = useState(false);
  const [taskAllocationDetails, setTaskAllocationDetails] = useState([]);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  const [selectedProjectEmployeeNames, setSelectedProjectEmployeeNames] =
    useState([]);
  const [selectedProjectMonths, setSelectedProjectMonths] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeNames, setEmployeeNames] = useState([]);

  const [count, setCount] = useState(0);

  // Additional state variables for dynamic columns
  const [lastMonth, setLastMonth] = useState("");
  const [columnCount, setColumnCount] = useState(selectedProjectMonths.length);

  const [showDropdownPopup, setShowDropdownPopup] = useState(false);

  const [searchTerm, setSearchTerm] = useState(""); // New state for the search term
  const [filteredProjects, setFilteredProjects] = useState([]); // New state for the filtered projects

  // Function to filter projects based on search term
  const searchProjects = (searchTerm) => {
    const filtered = taskAllocationDetails.filter((project) => {
        return (
            // Check if isSubmitted is falsy
            (project.ProjectName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
              project.id?.toLowerCase().includes(searchTerm?.toLowerCase()))
          );
    });
    setFilteredProjects(filtered);
  };

  // Update the filtered projects whenever the search term changes
  useEffect(() => {
    searchProjects(searchTerm);
  }, [searchTerm, taskAllocationDetails]);

  // Update the selected project details based on the search result
  const handleProjectSelection = (selectedId) => {
    const selectedDetails = filteredProjects.find(
      (details) => details.id === selectedId
    );
    setInputValues({});
    setSelectedProjectEmployeeNames([]);
    setSelectedProjectDetails(selectedDetails);
  };

  // Fetch task allocation data and set loading state
  const fetchTaskAllocation = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "taskAllocation"));
      const data = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setTaskAllocationDetails(data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch employee data:", error);
    }
  };

  useEffect(() => {
    fetchTaskAllocation();
  }, []);

  useEffect(() => {
    // Extract employee names from employeeData and set them in employeeNames state
    const names = employeeData.map((employee) => employee.EmployeeName);
    setEmployeeNames(names);
  }, [employeeData]);

  // console.log(employeeData);
  // console.log(employeeNames);

  useEffect(() => {
    // Check if selectedProjectDetails is not null before processing
    if (selectedProjectDetails) {
      const allEmployeeNames = [];
      const allMonths = [];

      // Extract unique employee names from the keys in selectedProjectDetails
      Object.keys(selectedProjectDetails).forEach((key) => {
        // Check if the key ends with a number (indicating a month value)
        const isMonthKey = !isNaN(parseInt(key.slice(-2)));
        if (!isMonthKey) {
          // Extract the employee name from the key and add it to the list
          const employeeName = key.split("_")[1];
          if (!allEmployeeNames.includes(employeeName)) {
            allEmployeeNames.push(employeeName);
          }
        }
      });

      // Extract unique months from the "completedTill" array in selectedProjectDetails
      const { completedTill } = selectedProjectDetails;
      if (completedTill) {
        completedTill.forEach((month) => {
          if (!allMonths.includes(month)) {
            allMonths.push(month);
          }
        });
      }

      // setSelectedProjectEmployeeNames(allEmployeeNames);
      const filteredEmployeeNames = allEmployeeNames.filter(
        (name) => name !== undefined
      );
      setSelectedProjectEmployeeNames(filteredEmployeeNames);
      setSelectedProjectMonths(allMonths);
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "employee_details"));
        const data = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setEmployeeData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
      }
    };

    fetchEmployeeData();
  }, []);
  // console.log(employeeData);

  const handleDropdownChange = (event) => {
    const selectedId = event.target.value;
    const selectedDetails = taskAllocationDetails.find(
      (details) => details.id === selectedId
    );

    setInputValues({});
    // Reset the selectedProjectEmployeeNames to an empty array when the project changes
    setSelectedProjectEmployeeNames([]);

    setSelectedProjectDetails(selectedDetails);
  };

  //   -------------------------------

  // Initialize the inputValues state with the values from selectedProjectDetails when the component mounts
  useEffect(() => {
    if (selectedProjectDetails) {
      const initialValues = {};
      selectedProjectMonths.forEach((month) => {
        selectedProjectEmployeeNames.forEach((employeeName) => {
          const inputId = `${month}_${employeeName}`;
          initialValues[inputId] = selectedProjectDetails[inputId] || "";
        });
      });
      setInputValues(initialValues);
      setLastMonth(selectedProjectMonths[selectedProjectMonths.length - 1]);
    }
  }, [
    selectedProjectDetails,
    selectedProjectMonths,
    selectedProjectEmployeeNames,
  ]);

  // Event handler to update input field values
  const handleInputChange = (event, month, employeeName) => {
    const { value } = event.target;
    const inputId = `${month}_${employeeName}`;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [inputId]: value,
    }));
  };

  // console.log(inputValues);

  // Add a new column dynamically
  const handleAddColumn = () => {
    if (lastMonth !== "") {
      const nextMonth = getNextMonth(lastMonth);
      setSelectedProjectMonths((prevMonths) => [...prevMonths, nextMonth]);

      // Update inputValues for the new column
      const newInputValues = { ...inputValues };
      selectedProjectEmployeeNames.forEach((employeeName) => {
        const inputId = `${nextMonth}_${employeeName}`;
        // console.log(
        //   "------------------=============-----------------",
        //   inputId
        // );
        newInputValues[inputId] = "";
      });
      // console.log(newInputValues);
      setInputValues(newInputValues);

      setColumnCount(columnCount + 1);
      setLastMonth(nextMonth);
    }
  };

  // Delete the last column dynamically
  const handleDeleteColumn = () => {
    if (lastMonth !== "") {
      const prevMonth = getPrevMonth(lastMonth);
      setSelectedProjectMonths((prevMonths) =>
        prevMonths.slice(0, prevMonths.length - 1)
      );

      // Remove inputValues for the deleted column
      const newInputValues = { ...inputValues };
      selectedProjectEmployeeNames.forEach((employeeName) => {
        const inputId = `${lastMonth}_${employeeName}`;
        // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", inputId);
        delete newInputValues[inputId];
        //  newInputValues[inputId]=" ";
        // console.log(newInputValues);
      });
      setInputValues(newInputValues);

      setColumnCount(columnCount - 1);
      setLastMonth(prevMonth);
    }
  };
  const getNextMonth = (currentMonth) => {
    const [month, year] = currentMonth.split("-");
    const nextMonth = {
      JAN: "FEB",
      FEB: "MAR",
      MAR: "APR",
      APR: "MAY",
      MAY: "JUN",
      JUN: "JUL",
      JUL: "AUG",
      AUG: "SEP",
      SEP: "OCT",
      OCT: "NOV",
      NOV: "DEC",
      DEC: "JAN",
    };
    const nextMonthName = nextMonth[month];
    const nextYear = nextMonthName === "JAN" ? Number(year) + 1 : year;
    return `${nextMonthName}-${nextYear}`;
  };
  // Function to get the previous month from the given month (e.g., "MAR-24" -> "FEB-24")
  const getPrevMonth = (currentMonth) => {
    const [month, year] = currentMonth.split("-");
    const prevMonth = {
      JAN: "DEC",
      FEB: "JAN",
      MAR: "FEB",
      APR: "MAR",
      MAY: "APR",
      JUN: "MAY",
      JUL: "JUN",
      AUG: "JUL",
      SEP: "AUG",
      OCT: "SEP",
      NOV: "OCT",
      DEC: "NOV",
    };
    const prevMonthName = prevMonth[month];
    const prevYear = prevMonthName === "DEC" ? Number(year) - 1 : year;
    return `${prevMonthName}-${prevYear}`;
  };

  const handleAddRow = () => {
    setShowDropdownPopup(true);
  };

  const handleDropdownSelect = (selectedValue) => {
    if (selectedValue === "") {
      setShowDropdownPopup(false);
      setSelectedDropdownValue("");
      return;
    }
    if (selectedProjectEmployeeNames.length > 0) {
      const newRowInputValues = { ...inputValues };
      selectedProjectMonths.forEach((month) => {
        const lastEmployeeName =
          selectedProjectEmployeeNames[selectedProjectEmployeeNames.length - 1];
        const inputId = `${month}_${lastEmployeeName}`;
        newRowInputValues[inputId] = "";
      });

      setCount((prevCount) => prevCount + 1);

      // Use the selected value from the dropdown as the new employee name
      const newEmployeeName = selectedValue;
      setSelectedProjectEmployeeNames((prevNames) => [
        ...prevNames,
        newEmployeeName,
      ]);

      setInputValues(newRowInputValues);
    }

    setShowDropdownPopup(false);
  };

  // ------------------------------------
  // Function to delete the last row dynamically
  const handleDeleteRow = () => {
    setCount((prevCount) => prevCount - 1);
    if (selectedProjectEmployeeNames.length > 0) {
      const newRowInputValues = { ...inputValues };
      selectedProjectMonths.forEach((month) => {
        const lastEmployeeName =
          selectedProjectEmployeeNames[selectedProjectEmployeeNames.length - 1];
        const inputId = `${month}_${lastEmployeeName}`;
        delete newRowInputValues[inputId];
      });

      // Remove the last employee from the selectedProjectEmployeeNames array
      setSelectedProjectEmployeeNames((prevNames) =>
        prevNames.slice(0, prevNames.length - 1)
      );

      setInputValues(newRowInputValues);
    }
  };

  const uploadToServer = async () => {
    try {
      // console.log("------------------------------------------------");
      // console.log("Input Values:", inputValues);
      // console.log("Selected Project Details:", selectedProjectDetails);

      // Create a new object to store the merged values
      const mergedValues = {
        FunderName: selectedProjectDetails.FunderName || "",
        ProjectName: selectedProjectDetails.ProjectName || "",
        ...inputValues,
        completedTill: selectedProjectMonths,
        isSubmitted:true
      };

      // console.log("Selected Project Months:", selectedProjectMonths);
      // console.log("Merged Values:", mergedValues);


      const activity = {
        action: "submit",
        nameOfLoggedInUser: auth.currentUser.displayName,
        dateAndTime: serverTimestamp(),
        forWhat: "ProjectWiseOverviewBeforeSubmit",
        ID: selectedProjectDetails.id,
        Name: selectedProjectDetails.ProjectName,
      };

      await addDoc(collection(db, "activity"), activity);


      const docRef = doc(db, "taskAllocation", selectedProjectDetails.id);

      // console.log("Updating document:", docRef.path);

      await setDoc(docRef, mergedValues); // Assuming mergedValues is the updated data to be uploaded

      // console.log("Task allocation updated successfully");
      // Reset the edited data and editing state
      // setDataToEdit({});
      // setIsEditing(false);
      // localStorage.removeItem("IdOfDataToBeEdited");
      window.location.reload();
    } catch (error) {
      console.error("Failed to upload edited employee data:", error);
    }
  };

  const uploadToServer2 = async () => {
    try {
      // console.log("------------------------------------------------");
      // console.log("Input Values:", inputValues);
      // console.log("Selected Project Details:", selectedProjectDetails);
      // Create a new object to store the merged values
      const mergedValues = {
        FunderName: selectedProjectDetails.FunderName || "",
        ProjectName: selectedProjectDetails.ProjectName || "",
        ...inputValues,
        completedTill: selectedProjectMonths,
        isSubmitted:false
      };

      // console.log("Selected Project Months:", selectedProjectMonths);
      // console.log("Merged Values:", mergedValues);

      const activity = {
        action: "edit",
        nameOfLoggedInUser: auth.currentUser.displayName,
        dateAndTime: serverTimestamp(),
        forWhat: "ProjectWiseOverviewBeforeSubmit",
        ID: selectedProjectDetails.id,
        Name: selectedProjectDetails.ProjectName,
      };

      await addDoc(collection(db, "activity"), activity);

      const docRef = doc(db, "taskAllocation", selectedProjectDetails.id);

      // console.log("Updating document:", docRef.path);

      await setDoc(docRef, mergedValues); // Assuming mergedValues is the updated data to be uploaded

      // console.log("Task allocation updated successfully");
      // Reset the edited data and editing state
      // setDataToEdit({});
      // setIsEditing(false);
      // localStorage.removeItem("IdOfDataToBeEdited");
      window.location.reload();
    } catch (error) {
      console.error("Failed to upload edited employee data:", error);
    }
  };


  if (loading) {
    return <Spinner />;
  }

  //   console.log("selectedProjectDetails:", selectedProjectDetails);

  // console.log(selectedProjectEmployeeNames);
  // console.log(selectedProjectMonths);

  return (
    <div className="max-w-6xl mx-auto">
      <div className="overflow-x-hidden mr-1 ml-1">
        <h1 className="text-3xl text-center mt-6 m-6 font-bold">
          Manpower allocation before submit
        </h1>

        <div className="flex items-center justify-between">
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search for a Project"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                // setSelectedProjectDetails(null);
                setSelectedProjectEmployeeNames([]);
                // Set selectedProjectDetails to null
              }}
              className="border rounded py-2 px-3 w-full"
            />
          </div>

          <div className="mb-4">
            {/* <select
              id="projectDropdown"
              onChange={(e) => handleProjectSelection(e.target.value)}
              value={selectedProjectDetails ? selectedProjectDetails.id : ""}
              className="border rounded py-2 px-3 w-full"
            >
              <option value="">---Select a Project---</option>
              {filteredProjects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.id} ({project.ProjectName})
                </option>
              ))}
            </select> */}

<select
  id="projectDropdown"
  onChange={(e) => handleProjectSelection(e.target.value)}
  value={selectedProjectDetails ? selectedProjectDetails.id : ""}
  className="border rounded py-2 px-3 w-full"
>
  <option value="">---Select a Project---</option>
  {filteredProjects
    .filter((project) => !(project.isSubmitted)) // Filter out projects with falsy isSubmitted
    .map((project) => (
      <option key={project.id} value={project.id}>
        {project.id} ({project.ProjectName})
      </option>
    ))}
</select>


          </div>
        </div>

        <div className="w-full">
          {/* <div className="">
            <select
              id="projectDropdown"
              onChange={handleDropdownChange}
              value={selectedProjectDetails ? selectedProjectDetails.id : ""}
            >
              <option value="">---Select a Project</option>
              {taskAllocationDetails.map((details) => (
                <option key={details.id} value={details.id}>
                  {details.id} ({details.ProjectName})
                </option>
              ))}
            </select>
          </div> */}

          <div className=" ">
            <div className="mt-3">
              {selectedProjectDetails && (
                <div className="flex flex-row items-center justify-between">
                  <div className="">
                    {selectedProjectDetails.ProjectName && (
                      <p>
                        Project Name is: {selectedProjectDetails.ProjectName}
                      </p>
                    )}
                    {selectedProjectDetails.FunderName && (
                      <p>
                        Project Funding Agency is:{" "}
                        {selectedProjectDetails.FunderName}
                      </p>
                    )}
                  </div>
                  <div className=" flex justify-end ">
                    <button
                      onClick={handleAddColumn}
                      disabled={lastMonth === ""}
                      className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                    >
                      Add Column
                    </button>
                    <button
                      onClick={handleDeleteColumn}
                      //   disabled={columnCount === 1}
                      className="bg-red-500 text-white py-2 px-4 rounded"
                    >
                      Delete Column
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className=" flex items-center justify-start overflow-x-auto">
              {/* Render the table if selectedProjectEmployeeNames and selectedProjectMonths are available */}
              {selectedProjectEmployeeNames.length > 0 &&
                selectedProjectMonths.length > 0 && (
                  <div className="">
                    <table className="table-auto mt-5 border-collapse">
                      <thead>
                        <tr>
                          <th className="border border-gray-300 bg-gray-200 px-4 py-2">
                            Employee Name
                          </th>
                          {selectedProjectMonths.map((month) => (
                            <th
                              key={month}
                              className="border border-gray-300 bg-gray-200 px-4 py-2"
                            >
                              {month}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProjectEmployeeNames.map(
                          (employeeName, index) => (
                            <tr
                              key={employeeName}
                              className={`${
                                index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                              } hover:bg-white transition-colors`}
                            >
                              <td className="border border-gray-2 px-4 py-2 font-medium w-[200px] text-justify">
                                {employeeName.charAt(0)?.toUpperCase() +
                                  employeeName.slice(1)?.toLowerCase()}
                              </td>

                              {selectedProjectMonths.map((month) => (
                                <td
                                  key={`${employeeName}-${month}`}
                                  className="border border-gray-2"
                                >
                                  <input
                                    type="number"
                                    id={`${month}_${employeeName}`}
                                    name={`${month}_${employeeName}`}
                                    value={
                                      inputValues[`${month}_${employeeName}`] ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleInputChange(e, month, employeeName)
                                    }
                                    className="w-full text-center border-none bg-transparent active:bg-white outline-none focus:ring focus:ring-blue-300"
                                  />
                                </td>
                              ))}
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
          </div>
        </div>

        {selectedProjectDetails && (
          <div>
            <div className="">
              {/* Add Row Button */}
              <button
                onClick={handleAddRow}
                className="bg-green-500 text-white py-2 px-4 rounded mr-2 mt-3"
              >
                Add Row
              </button>
              {/* Delete Row Button */}
              <button
                onClick={handleDeleteRow}
                className="bg-red-500 text-white py-2 px-4 rounded mt-3"
              >
                Delete Row
              </button>
            </div>

            <div className="flex items-center justify-center">

            <button
                className="bg-green-500 text-white py-2 px-4 rounded mr-2 mt-3"
                onClick={uploadToServer2}
              >
                Save
              </button>
              <button
                className="bg-green-500 text-white py-2 px-4 rounded mr-2 mt-3"
                onClick={uploadToServer}
              >
                Submit
              </button>


            </div>
          </div>
        )}

        {showDropdownPopup && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
            <div className="bg-white p-6 rounded-lg shadow-md relative">
              <button
                className="absolute top-0  right-0 m-2 text-gray-600 hover:text-gray-800"
                onClick={() => setShowDropdownPopup(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 11-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <div className="mb-6 mt-10">
                <select
                  className="w-full border rounded p-2"
                  value={selectedDropdownValue}
                  onChange={(e) => setSelectedDropdownValue(e.target.value)}
                >
                  <option value="">Select employee</option>
                  {employeeNames.map((name) => (
                    <option
                      key={name}
                      value={name}
                      disabled={selectedProjectEmployeeNames.includes(name)}
                    >
                      {name}
                    </option>
                  ))}
                </select>
              </div>

              <button
                className="bg-blue-500 text-white py-2 px-4 rounded"
                onClick={() => handleDropdownSelect(selectedDropdownValue)}
              >
                Select
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectWiseOverviewBeforeSubmit;

// ----------------------------------------
