import { useState } from "react";
import { Spinner } from "../components/Spinner";
import { toast } from "react-toastify";
import { push, ref } from "firebase/database";
import { getAuth } from "firebase/auth";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

export default function AddEmployee() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [salary, setSalary] = useState([{ name: "", value: "0" }]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    EmployeeID: "",
    EmployeeName: "",
    EmployeeEmail: "",
    Department: "",
    JoiningDate: "",
    LeavingDate: "",
    Basic: "",
    HRA: "",
    MedicalAllowance: "",
    SpecialAllowance: "",
    EmployeePfContribution: "",
    EmployerPfContribution: "",
    Gratuity: "",
    GroupInsurance: "",
    MedicalInsurance: "",
    Designation: "",
    ReportingManager: "",
    ContactNumber: "",
    HighestQualification: "",
    YearOfExperience: "",
  });

  const {
    EmployeeID,
    EmployeeName,
    EmployeeEmail,
    Department,
    JoiningDate,
    LeavingDate,
    Basic,
    HRA,
    MedicalAllowance,
    SpecialAllowance,
    EmployeePfContribution,
    EmployerPfContribution,
    Gratuity,
    GroupInsurance,
    MedicalInsurance,
    Designation,
    ReportingManager,
    ContactNumber,
    HighestQualification,
    YearOfExperience,
  } = formData;

  // function onChange(e) {
  //   // text/boolean or number
  //   if (!e.target.files) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [e.target.id]: e.target.value,
  //     }));
  //   }
  // }

  function onChange(e) {
    // text/boolean or number
    if (!e.target.files) {
      const { id, value } = e.target;

      let updatedValue = value;

      if (id === "Basic") {
        const basicValue = parseFloat(value);
        const hraValue = isNaN(basicValue) ? "" : basicValue * 0.5;
        const employeePfContributionValue = isNaN(basicValue)
          ? ""
          : basicValue * 0.12;
        const employerPfContributionValue = isNaN(basicValue)
          ? ""
          : basicValue * 0.12;

        updatedValue = isNaN(basicValue) ? "" : basicValue.toString();

        setFormData((prevState) => ({
          ...prevState,
          [id]: updatedValue,
          HRA: hraValue,
          EmployeePfContribution: employeePfContributionValue,
          EmployerPfContribution: employerPfContributionValue,
        }));
      }

      setFormData((prevState) => ({
        ...prevState,
        [id]: updatedValue,
      }));
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const formDataCopy = {
      ...formData,
      EmployeeID: formData.EmployeeID.toLowerCase(),
      EmployeeName: formData.EmployeeName.toLowerCase(),
      EmployeeEmail: formData.EmployeeEmail.toLowerCase(),
      salary,
      Total: calculateTotal(),
      isSubmitted:false
      // Department: formData.Department.toLowerCase(),
    };

    const activity = {
      action: "create",
      nameOfLoggedInUser: auth.currentUser?.displayName,
      dateAndTime: serverTimestamp(),
      forWhat: "Employee",
      ID: formData.EmployeeID,
      Name: formData.EmployeeName,
    };

    try {
      const docRef = await addDoc(
        collection(db, "employee_details"),
        formDataCopy
      );

      await addDoc(collection(db, "activity"), activity);

      setLoading(false);
      toast.success("Employee created successfully");

      setFormData({
        EmployeeID: "",
        EmployeeName: "",
        EmployeeEmail: "",
        Department: "",
        JoiningDate: "",
        LeavingDate: "",
      });
      setSalary([{ name: "", value: "0" }]);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to save employee data to the database");
    }
  }

  // const calculateTotal = () => {
  //   let total = 0;
  //   for (let i = 0; i < salary.length; i++) {
  //     total += parseFloat(salary[i].value);
  //   }
  //   return total;
  // };

  const calculateTotal = () => {
    let total = 0;
    const fieldsToSum = [
      Basic,
      HRA,
      MedicalAllowance,
      SpecialAllowance,
      EmployeePfContribution,
      EmployerPfContribution,
      Gratuity,
      GroupInsurance,
      MedicalInsurance,
    ];

    for (let i = 0; i < fieldsToSum.length; i++) {
      const fieldValue = parseFloat(fieldsToSum[i]);
      if (!isNaN(fieldValue)) {
        total += fieldValue;
      }
    }

    for (let i = 0; i < salary.length; i++) {
      total += parseFloat(salary[i].value);
    }

    return total;
  };

  calculateTotal();

  const changeSalaryName = (index, newName) => {
    const updatedSalary = [...salary];
    updatedSalary[index] = { ...updatedSalary[index], name: newName };
    setSalary(updatedSalary);
  };

  const changeSalaryValue = (index, newValue) => {
    const updatedSalary = [...salary];
    updatedSalary[index] = { ...updatedSalary[index], value: newValue };
    setSalary(updatedSalary);
  };

  const addSalaryField = (e) => {
    e.preventDefault();
    setSalary([...salary, { name: "", value: "0" }]);
  };

  const deleteSalaryField = (index) => {
    // e.preventDefault();
    const updatedSalary = [...salary];
    updatedSalary.splice(index, 1);
    setSalary(updatedSalary);
  };

  const show = () => {
    // console.log(salary);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="max-w-5xl px-3 mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">Create Employee</h1>
      <form onSubmit={onSubmit}>
        <div className="flex space-x-2">
          <div className="w-1/2">
            <p className="text-l mt-6 font-semibold">Employee ID</p>
            <input
              type="text"
              id="EmployeeID"
              value={EmployeeID}
              onChange={onChange}
              placeholder="Employee ID"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
          <div className="w-1/2">
            <p className="text-l mt-6 font-semibold">Name</p>
            <input
              type="text"
              id="EmployeeName"
              value={EmployeeName}
              onChange={onChange}
              placeholder="Name"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          <div className="w-1/2">
            <p className="text-l mt-6 font-semibold"> Email</p>
            <input
              type="email"
              id="EmployeeEmail"
              value={EmployeeEmail}
              onChange={onChange}
              placeholder="Email"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
          <div className="w-1/2">
            <p className="text-l mt-6 font-semibold">Contact Number</p>
            <input
              type="text"
              id="ContactNumber"
              value={ContactNumber}
              onChange={onChange}
              placeholder="Contact Number"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          <div className="w-1/2">
            <p className="text-l mt-6 font-semibold"> Designation</p>
            <input
              type="text"
              id="Designation"
              value={Designation}
              onChange={onChange}
              placeholder="Designation"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
        </div>

        {/* <p>testtttttttttttttttttttttttt</p> */}

        <div className="flex space-x-2">
          <div className="w-1/2">
            <p className="text-l mt-6 font-semibold"> Highest Qualification</p>
            <input
              type="text"
              id="HighestQualification"
              value={HighestQualification}
              onChange={onChange}
              placeholder="Highest Qualification"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          <div className="w-1/2">
            <p className="text-l mt-6 font-semibold"> Year of Experience</p>
            <input
              type="number"
              id="YearOfExperience"
              value={YearOfExperience}
              onChange={onChange}
              placeholder="Year Of Experience"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
          <div className="w-1/2">
            <p className="text-l mt-6 font-semibold"> Department</p>
            <select
              id="Department"
              value={Department}
              onChange={onChange}
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            >
              <option value="" disabled>
                --Department--
              </option>
              <option value="Climate">Climate</option>
              <option value="Communication">Communication</option>
              <option value="Communication">Consultant</option>
              <option value="Energy & Power">Energy & Power</option>
              <option value="Finance">Finance</option>
              <option value="GIS">GIS</option>
              <option value="HR">HR</option>
              <option value="IT">IT</option>
              <option value="IT">Logistic & Others</option>
              <option value="SMT">SMT (Senior Management Team)</option>
            </select>
          </div>

          <div className="w-1/2">
            <p className="text-l mt-6 font-semibold">Reporting Manager</p>
            <input
              type="text"
              id="ReportingManager"
              value={ReportingManager}
              onChange={onChange}
              placeholder="Reporting Manager"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
        </div>

        {/* <p>testttttttttttttttttttttttttttttttttttt</p>  */}
        <div>
          <p className="text-l font-semibold">Salary</p>

          <div className="flex space-x-2">
            <div className="w-1/2">
              <p className="text-l mt-3  text-gray-600  font-semibold">Basic</p>
              <input
                type="text"
                id="Basic"
                value={Basic}
                onChange={onChange}
                placeholder="Basic"
                className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
              />
            </div>
            <div className="w-1/2">
              <p className="text-l mt-3  text-gray-600 font-semibold">HRA</p>
              <input
                type="text"
                id="HRA"
                disabled
                value={HRA}
                onChange={onChange}
                placeholder="HRA"
                className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
              />
            </div>
            <div className="w-1/2">
              <p className="text-l mt-3  text-gray-600 font-semibold">
                Medical Allowance
              </p>
              <input
                type="text"
                id="MedicalAllowance"
                value={MedicalAllowance}
                onChange={onChange}
                placeholder="Medical Allowance"
                className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
              />
            </div>
          </div>
        </div>

        {/* <div className="flex space-x-2">
  <div className="flex items-center">
    <label htmlFor="Basic" className="text-lg text-gray-600 font-semibold">Basic</label>
    <input
      type="text"
      id="Basic"
      value={Basic}
      onChange={onChange}
      placeholder="Basic"
      className="w-full px-4 py-2 text-lg text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
    />
  </div>
  <div className="flex items-center">
    <label htmlFor="HRA" className="text-lg text-gray-600 font-semibold">HRA</label>
    <input
      type="text"
      id="HRA"
      disabled
      value={HRA}
      onChange={onChange}
      placeholder="HRA"
      className="w-full px-4 py-2 text-lg text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
    />
  </div>
  <div className="flex items-center">
    <label htmlFor="MedicalAllowance" className="text-lg text-gray-600 font-semibold">
      Medical Allowance
    </label>
    <input
      type="text"
      id="MedicalAllowance"
      value={MedicalAllowance}
      onChange={onChange}
      placeholder="Medical Allowance"
      
      className="w-full px-4 py-2 text-lg text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
    />
  </div>
</div> */}

        <div className="flex space-x-2">
          <div className="w-1/2">
            <p className="text-l   text-gray-600 font-semibold">
              Special Allowance
            </p>
            <input
              type="text"
              id="SpecialAllowance"
              value={SpecialAllowance}
              onChange={onChange}
              placeholder="Special Allowance"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
          <div className="w-1/2">
            <p className="text-l   text-gray-600 font-semibold">Employee PF </p>
            <input
              type="text"
              id="EmployeePfContribution"
              disabled
              value={EmployeePfContribution}
              onChange={onChange}
              placeholder="Employee PF Contribution"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
          <div className="w-1/2">
            <p className="text-l   text-gray-600 font-semibold">Employer PF</p>
            <input
              type="text"
              id="EmployerPfContribution"
              value={EmployerPfContribution}
              onChange={onChange}
              disabled
              placeholder="Employer PF Contribution"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
        </div>

        <div className="flex space-x-2">
          <div className="w-1/2">
            <p className="text-l   text-gray-600 font-semibold">Gratuity</p>
            <input
              type="text"
              id="Gratuity"
              value={Gratuity}
              onChange={onChange}
              placeholder="Gratuity"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
          <div className="w-1/2">
            <p className="text-l   text-gray-600 font-semibold">
              Group Insurance{" "}
            </p>
            <input
              type="text"
              id="GroupInsurance"
              value={GroupInsurance}
              onChange={onChange}
              placeholder="Group Insurance"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
          <div className="w-1/2">
            <p className="text-l   text-gray-600 font-semibold">
              Medical Insurance
            </p>
            <input
              type="text"
              id="MedicalInsurance"
              value={MedicalInsurance}
              onChange={onChange}
              placeholder="Medical Insurance"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
        </div>

        {/* <p className="text-l mt-6 font-semibold">Salary</p>
            <input
              type="email"
              id="EmployeeEmail"
              value={EmployeeEmail}
              onChange={onChange}
              placeholder="Email"
              
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            /> */}

        <div className="mt-0 w-full mx-auto">
          {salary.length > 0 && (
            <p className="text-l text-gray-600 font-semibold">Others</p>
          )}

          {salary.map((item, index) => (
            <div key={index} className="flex">
              <div className="w-1/2">
                <input
                  type="text"
                  value={item.name}
                  onChange={(e) => changeSalaryName(index, e.target.value)}
                  placeholder="Head"
                  className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                />
              </div>

              <div className=" w-1/2 ml-1">
                <input
                  type="number"
                  value={item.value}
                  min="0"
                  onChange={(e) => changeSalaryValue(index, e.target.value)}
                  placeholder="Value"
                  className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                />
              </div>

              {salary.length > 0 && (
                <button
                  type="button"
                  onClick={() => deleteSalaryField(index)}
                  className="rounded ml-1"
                  title="Delete this Budget"
                >
                  <AiOutlineMinusCircle className="text-4xl mb-6 text-white border rounded-full bg-red-500 hover:bg-red-700" />
                </button>
              )}
            </div>
          ))}

          {/* Total: {calculateTotal()} {currency} */}
          <div className="flex items-center mb-6">
            <input
              value={`Total: ${calculateTotal()} `}
              className="w-1/2 px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mr-2"
            />
            <button
              onClick={addSalaryField}
              title="Add more Budgets"
              className="py-2"
            >
              <AiOutlinePlusCircle className="text-white hover:bg-blue-700 text-4xl border rounded-full bg-blue-500" />
            </button>
          </div>

          {/* <button onClick={show}>Show</button> */}
        </div>

        <div className="flex">
          <div className="flex-1">
            <p className="text-l mt-6 font-semibold">Joining Date</p>
            <input
              type="date"
              id="JoiningDate"
              value={JoiningDate}
              onChange={onChange}
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          <div className="flex-1 ml-2">
            <p className="text-l mt-6 font-semibold">Leaving Date</p>
            <input
              type="date"
              id="LeavingDate"
              value={LeavingDate}
              onChange={onChange}
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
        </div>

        <button
          type="submit"
          className="mb-6 w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        >
          Save
        </button>
      </form>
    </main>
  );
}
