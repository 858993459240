import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { BsPencil, BsTrash } from "react-icons/bs";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { db } from "../firebase";
import {
  getStorage,
  ref as storageReff,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Spinner } from "../components/Spinner";

const UploadNCEAttachments = () => {
  const [projectData, setProjectData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedNCEDate, setSelectedNCEDate] = useState(" ");
  const NCEAttachmentURLs = [];
  const NarrativeAttachmentURLs = [];

  const [NCEDocuments, setNCEDocuments] = useState([]);

  const projectId = selectedProject.ProjectId;
  const id = selectedProject.id;

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "project_details"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProjectData(data);
        console.log(projectData);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };
    fetchProjectData();
  }, []);

  const handleSearchInputChange = (event) => {
    const input = event.target.value.toLowerCase();
    // console.log("searchInput:", input);
    setSearchInput(input);
    setSelectedProject(""); // Clear the selected project when search input changes
    setSelectedNCEDate(" ");
  };

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setSearchInput("");
  };

  const filteredProjects = projectData.filter(
    (project) =>
      project.ProjectId.toLowerCase().includes(searchInput) ||
      project.ProjectName.toLowerCase().includes(searchInput)
  );

  const handleFileChange = (event, setDocuments) => {
    const files = event.target.files;
    const documentsArray = Array.from(files);
    setDocuments((prevDocuments) => [...documentsArray]);
  };

  const handleUpload = async () => {
    try {
      setLoading(true);

      const storage = getStorage();

      const uploadDocument = async (documents, folderName) => {
        const uploadPromises = documents.map(async (file) => {
          const fileId = uuidv4();
          const fileExtension = file.name.split(".").pop();
          const fileName = `${file.name}-${fileId}.${fileExtension}`;
          const storageRef = storageReff(
            storage,
            `${projectId}/${folderName}/${selectedNCEDate}/${fileName}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Calculate the percentage of the upload
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const message = `Uploading ${file.name}: ${progress.toFixed(
                  2
                )}%`;

                console.log(message);

                // Handle progress or monitoring if needed
              },
              (error) => {
                console.error(`Error uploading ${folderName} document:`, error);
                reject(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref)
                  .then((downloadURL) => {
                    // Store the file name and URL in the corresponding variables
                    // namesArray.push(fileName);
                    if (folderName === "NCEDocuments") {
                      NCEAttachmentURLs.push(downloadURL);
                    } else if (folderName === "narrativeDocuments") {
                      NarrativeAttachmentURLs.push(downloadURL);
                    }

                    resolve();
                  })
                  .catch((error) => {
                    console.error("Error getting download URL:", error);
                    reject(error);
                  });
              }
            );
          });
        });

        await Promise.all(uploadPromises);
      };

      // Upload proposalAttachmentsDocuments
      await uploadDocument(NCEDocuments, "NCEDocuments");

      setNCEDocuments([]);

      toast.success("Documents uploaded successfully!");
    } catch (error) {
      console.error("Error uploading documents:", error);
      toast.error("Error uploading documents");
    } finally {
      setLoading(false);
    }
  };

  const updateDocument = async () => {
    try {
      await handleUpload();

      const copiedData = {
        ...selectedProject,
        [`NCEURLs-${selectedNCEDate}`]: [
          ...(selectedProject[selectedNCEDate]?.financialURLs || []),
          ...NCEAttachmentURLs,
        ],
      };

      if (id) {
        const docRef = doc(db, "project_details", id);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          await updateDoc(docRef, copiedData);
          // console.log("Document updated successfully");
          // console.log("Updated data:", copiedData);
          setSelectedProject(copiedData);

          setSelectedNCEDate(" ");
        } else {
          // console.log("Project not found");
        }
      } else {
        // console.log("Invalid project ID");
      }
    } catch (error) {
      console.error("Failed to update document:", error);
    }
  };

  //   const [reportingTimelineKeys, setReportingTimelineKeys] = useState([]);

  //   const getReportingTimelineKeys = () => {
  //     const keys = Object.keys(selectedProject)
  //       .filter((key) => key.startsWith("ReportingTimeline"))
  //       .filter((key) => Object.keys(selectedProject[key]).length > 0);

  //     setReportingTimelineKeys(keys);
  //   };
  
  // console.log(reportingTimelineKeys);

  //   useEffect(() => {
  //     getReportingTimelineKeys();
  //   }, [selectedProject]);

 

   

  async function handleDeleteURL(NCEURLKey, other) {
    try {
      setLoading(true); // Set loading to true
  
      console.log(id)
      const docRef = doc(db, "project_details", id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        console.log(NCEURLKey)
            console.log(data[NCEURLKey])
        // delete data[NCEURLKey];
  
        // await updateDoc(docRef, data);
        if (Array.isArray(data[NCEURLKey])) {
            // Delete the NCEURLKey (array of URLs)
            delete data[NCEURLKey];
    
            await updateDoc(docRef, data);
            setSelectedProject(data);
    
            // Notify the user about successful deletion
            console.log(`NCEURLKey '${NCEURLKey}' (array of URLs) deleted successfully`);
          } else {
            console.log(`NCEURLKey '${NCEURLKey}' (array of URLs) not found in the selected document`);
          }
        setSelectedProject(data);
  
        const storage = getStorage();
        let convertedOther;
        if (other === "financialURLs") {
          convertedOther = "NCEDocuments";
        } else if (other === "narrativeURLs") {
          convertedOther = "narrativeDocuments";
        }
        const storageRef = storageReff(
          storage,
          `${projectId}/${NCEURLKey}/${convertedOther}`
        );
  
        const listResult = await listAll(storageRef);
  
        const fileDeletions = listResult.items.map((item) => {
          return deleteObject(item);
        });
  
        await Promise.all(fileDeletions);
  
        // Notify the user about successful deletion
        console.log('URL deleted successfully');
      } else {
        console.log('Project not found');
      }
    } catch (error) {
      console.error("Failed to delete URL:", error);
    } finally {
      setLoading(false); // Set loading back to false after deletion
    }
  }
  

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="mt-5 max-w-6xl mx-auto">
      <input
        type="text"
        className="w-96"
        value={searchInput}
        onChange={handleSearchInputChange}
        placeholder="Search project by Project Name or Project ID"
      />
      <br></br>
      <br></br>
      <hr></hr>

      {selectedProject ? (
        <div>
          <div key={selectedProject.id} className="flex mt-4 justify-between">
            <div>
              <h3>Project Name is : {selectedProject.ProjectName}</h3>
              <p>Project Incharge is: {selectedProject.ProjectManager}</p>
              <p>Project ID is: {selectedProject.ProjectId}</p>
              <p>Total is: {selectedProject.Total}</p>

              
              {Object.entries(selectedProject)
                .filter(([key]) => key.startsWith("NCEURLs"))
                .map(([key, value]) => {
                  if (Array.isArray(value)) {
                    return (
                      <div key={key}>
                        <div className="flex">
                          <h2>{key}</h2>
                          {value.map((url, index) => (
                              <a
                              href={url}
                              target="_blank"
                              key={key}
                              rel="noopener noreferrer"
                              title={`${key}-${index + 1}`}
                            >
                              <AiOutlineCloudDownload
                                style={{ display: "inline" }}
                                className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-500"
                              />
                            </a>
                              
                            ))}

                            {/* {
                                <button
                                  onClick={() =>
                                    handleDeleteURL(key, "financialURLs")
                                  }
                                  className="ml-2"
                                >
                                    {key}
                                  <BsTrash className="text-2xl border rounded-full bg-white ml-1 hover:bg-red-500" />
                                </button>
                            } */}
                        </div>
                        <hr />
                      </div>
                    );
                  }
                  return null; // Ignore non-array values under "NCEURLs" keys
                })}
            </div>

            <select
              value={selectedNCEDate}
              onChange={(event) => setSelectedNCEDate(event.target.value)}
              className="h-10"
            >
              {
                // !selectedNCEDate &&
                <option value=" ">--choose one NCE date--</option>
              }

              {Object.keys(selectedProject.no_cost_extension_dates)
                .sort()
                .map((key) => (
                  <option
                    key={key}
                    value={selectedProject.no_cost_extension_dates[key]}
                    // disabled={reportingTimelineKeys.includes(key)}
                  >
                    {+key + 1} - {selectedProject.no_cost_extension_dates[key]}
                  </option>
                ))}
            </select>
          </div>
          {/* <hr /> */}

          <div>
            <div>
              {selectedNCEDate.length > 1 && (
                <div className="mt-2">
                  <p>Selected NCE Date : {selectedNCEDate}</p>
                </div>
              )}
            </div>
          </div>

          {/* {selectedNCEDate.length} */}

          {selectedNCEDate.length > 1 && (
            <>
              <div className="flex flex-wrap mb-4">
                <div className="w-1/2 pr-2 mb-4">
                  <p className="mb-2">Attach Files</p>
                  <input
                    className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                    type="file"
                    multiple
                    onChange={(e) => handleFileChange(e, setNCEDocuments)}
                  />
                  {/* Render the uploaded proposal attachments files */}
                  {NCEDocuments.map((file) => (
                    <div key={file.name}>{file.name}</div>
                  ))}
                </div>
              </div>

              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={updateDocument}
              >
                Upload Documents
              </button>
            </>
          )}
        </div>
      ) : (
        searchInput.length > 0 &&
        filteredProjects.map((project) => (
          <div
            key={project.id}
            className="w-full mr-5"
            onClick={() => handleProjectSelect(project)}
            style={{
              cursor: "pointer",
              border: "1px solid #ccc",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <h3>{project.ProjectName}</h3>
            <p>Project Manager: {project.ProjectManager}</p>
            <p>Project ID: {project.ProjectId}</p>
            <p>Total: {project.Total}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default UploadNCEAttachments;
