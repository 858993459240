import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import addEmployee from "../assets/svg/Create Employee.svg";
import addProjects from "../assets/svg/Add Project.svg";
import projectAllocation from "../assets/svg/Shape_17_.svg";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();

  const name = auth.currentUser?.displayName;
  // const email = auth.currentUser.email;
  // const HR = ["gunjan@vasudhaindia.org"];
  // const CEO = ["srinivas@vasudhaindia.org"];
  // const FINANCE = ["amar@vasudhaindia.org,mohit@vasudhaindia.org"];
  // const MANAGER = [
  //   "raghav@vasudhaindia.org",
  //   "priya@vasudhaindia.org",
  //   "jaideep@vasudhaindia.org",
  // ];

  const [email, setEmail] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setEmail(user?.email);
      }
    });

    return () => unsubscribe();
  }, []);

  const currentDate = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <div className="mt-5 w-[73%] mx-auto over ">
      <div className="">
        <div className="flex justify-between">
          <div>
            <h2 className="text-2xl font-bold" style={{ color: "#739b3f" }}>
              Vasudha Foundation - Project Management System
            </h2>
            <p>
              Welcome {name}!{/* {email} */}
            </p>
          </div>
          <div className="font-bold" style={{ color: "rgba(0,0,0,0.5)" }}>
            {currentDate}
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-16">
        <div className="flex space-x-4 ">
          {/* First Column */}
          {(email == "gunjan@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "it@vasudhaindia.org") && (
            <div className="flex flex-col  border p-3 max-w-sm flex-1.2">
              {(email == "gunjan@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <div
                  className="border transform duration-300 group-hover:shadow-2xl p-4 group   rounded-[7px]"
                  // style={{ backgroundColor: "#95c754" }}
                >
                  <Link
                    to=""
                    rel="noopener noreferrer"
                    className={`cursor-pointer flex justify-around  items-center py-1 text-md font-semibold border-b-[3px] border-b-transparent`}
                  >
                    {/* <img
                      src={addEmployee}
                      alt="Add Employee"
                      style={{ width: "15%" }}
                    /> */}
                    <p className=" text-2xl text-[#739b3f]">
                      {" "}
                      Employee Database
                    </p>
                    {/* <AiOutlinePlusCircle className="mt-2 ml-2 text-xl" /> */}
                  </Link>
                </div>
              )}

              {(email == "gunjan@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/create-employee"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent mt-5 bg-[#739b3f]`}
                  style={{
                    border: "2px solid #739b3f",
                    padding: "10px",
                    color: " #ffffff",
                    borderRadius: "5px",
                    // boxShadow:"2px 2px 10px rgba(0,0,0,0.5)"
                  }}
                >
                  Create Employees {" >"}
                </Link>
              )}

              {(email == "gunjan@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/all-employee-before-submit"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent mt-5 bg-[#739b3f]`}
                  style={{
                    border: "2px solid #739b3f",
                    padding: "10px",
                    color: " #ffffff",
                    borderRadius: "5px",
                    // boxShadow:"2px 2px 10px rgba(0,0,0,0.5)"
                  }}
                >
                  Final Submission to Database {" >"}
                </Link>
              )}

              {(email == "gunjan@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/upload-excel"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent mt-5 bg-[#739b3f]`}
                  style={{
                    border: "2px solid #739b3f",
                    padding: "10px",
                    color: " #ffffff",
                    borderRadius: "5px",
                    // boxShadow:"2px 2px 10px rgba(0,0,0,0.5)"
                  }}
                >
                  Upload Excel {" >"}
                </Link>
              )}

              <a
                target="_black"
                href="https://docs.google.com/spreadsheets/d/1icCbHxOKYbWYHLLj93CEbWmiJ70f3BArpESrc7zLEqc/edit?usp=sharing"
                className="mt-2 text-xs ml-auto border p-1"
              >
                Link to sheet
              </a>
            </div>
          )}

          {/* Second Column */}

          {(email == "amar@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org" ||
            email == "preethi@vasudhaindia.org" ||
            email == "it@vasudhaindia.org") && (
            <div className="flex flex-col border p-3 max-w-sm flex-1.2">
              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <div
                  className="border transform duration-300 group-hover:shadow-2xl p-4 group   rounded-[7px]"
                  // style={{ backgroundColor: "#FADD67" }}
                >
                  <Link
                    // to="/create-project"
                    rel="noopener noreferrer"
                    className={`cursor-pointer flex  items-center justify-around py-1 text-md font-semibold border-b-[3px] border-b-transparent`}
                  >
                    {/* <img
                      // src={addProjects}
                      src={projectAllocation}
                      alt="Add Employee"
                      style={{ width: "15%" }}
                    /> */}
                    <p className="text-2xl  text-[#d6b015]">Project Database</p>
                    {/* <AiOutlinePlusCircle className="mt-2 ml-2 text-xl" /> */}
                  </Link>
                </div>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/create-project"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent bg-[#d6b015] mt-5`}
                  style={{
                    border: "2px solid #d6b015",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  Create Project {" >"}
                </Link>
              )}


                {/* revesion in budget */}


                {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/revesion-in-budget"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent bg-[#d6b015] mt-5`}
                  style={{
                    border: "2px solid #d6b015",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  Revesion in budget {" >"}
                </Link>
              )}

                {/* revesion in budget end */}
              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/all-projects-before-submit"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent bg-[#d6b015] mt-5`}
                  style={{
                    border: "2px solid #d6b015",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  Final Submission to database {" >"}
                </Link>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/Upload-Reporting-Timeline-attachments"
                  rel="noopener noreferrer"
                  className={`cursor-pointer py-1  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl text-sm font-semibold border-b-[3px] border-b-transparent bg-[#d6b015] mt-5`}
                  style={{
                    border: "2px solid #d6b015",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  Upload Reporting Attachments {" >"}
                </Link>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/upload-nCE-Attachments"
                  rel="noopener noreferrer"
                  className={`cursor-pointer py-1  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl text-sm font-semibold border-b-[3px] border-b-transparent bg-[#d6b015] mt-5`}
                  style={{
                    border: "2px solid #d6b015",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  Upload NCE Attachments {" >"}
                </Link>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/task-allocate"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent bg-[#d6b015] mt-5`}
                  style={{
                    border: "2px solid #d6b015",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  Manpower Allocation {" >"}
                </Link>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/project-wise-overview-before-submit"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent bg-[#d6b015] mt-5`}
                  style={{
                    border: "2px solid #d6b015",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  Manpower Allocation Before Submit {" >"}
                </Link>
              )}
            </div>
          )}

          {/* Third Column */}
          {(email == "amar@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org" ||
            email == "preethi@vasudhaindia.org" ||
            email == "raghav@vasudhaindia.org" ||
            email == "jaideep@vasudhaindia.org" ||
            email == "vrinda@vasudhaindia.org" ||
            email == "rini@vasudhaindia.org" ||
            email == "akinchan@vasudhaindia.org" ||
            email == "priya@vasudhaindia.org" ||
            email == "gunjan@vasudhaindia.org" ||
            email == "it@vasudhaindia.org") && (
            <div className="flex border p-3 flex-col flex-1 max-w-sm">
              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "raghav@vasudhaindia.org" ||
                email == "jaideep@vasudhaindia.org" ||
                email == "vrinda@vasudhaindia.org" ||
                email == "rini@vasudhaindia.org" ||
                email == "akinchan@vasudhaindia.org" ||
                email == "priya@vasudhaindia.org" ||
                email == "gunjan@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <div className="border transform duration-300 group-hover:shadow-2xl p-4 group rounded-[7px]">
                  <Link
                    rel="noopener noreferrer"
                    className={`cursor-pointer flex items-center justify-center py-1 text-md font-semibold border-b-[3px] border-b-transparent`}
                  >
                    {/* <img
                      src={addProjects}
                      alt="image"
                      style={{ width: "15%" }}
                    /> */}
                    <p className="text-2xl ml-4 text-[#398AA3]">Dashboards</p>
                  </Link>
                </div>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/employee-wise-overview"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent bg-[#398AA3] mt-5`}
                  style={{
                    border: "2px solid #398AA3",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  Employee-wise Overview {" >"}
                </Link>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/project-wise-overview"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300  group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent bg-[#398AA3] mt-5`}
                  style={{
                    border: "2px solid #398AA3",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  Project-wise Overview {" >"}
                </Link>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "gunjan@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/all-employee"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent mt-5 bg-[#398AA3]`}
                  style={{
                    border: "2px solid #398AA3",
                    padding: "10px",
                    color: " #ffffff",
                    borderRadius: "5px",
                    // boxShadow:"2px 2px 10px rgba(0,0,0,0.5)"
                  }}
                >
                  All Employees {" >"}
                </Link>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "raghav@vasudhaindia.org" ||
                email == "jaideep@vasudhaindia.org" ||
                email == "vrinda@vasudhaindia.org" ||
                email == "rini@vasudhaindia.org" ||
                email == "akinchan@vasudhaindia.org" ||
                email == "priya@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/all-projects"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent bg-[#398AA3] mt-5`}
                  style={{
                    border: "2px solid #398AA3",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  All Projects {" >"}
                </Link>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/month-wise-overview"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent bg-[#398AA3] mt-5`}
                  style={{
                    border: "2px solid #398AA3",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  month-wise-overview {" >"}
                </Link>
              )}
            </div>
          )}

          {/* Fourth Column */}
          {(email == "amar@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org" ||
            email == "preethi@vasudhaindia.org" ||
            email == "it@vasudhaindia.org") && (
            <div className="flex border p-3 flex-col flex-1 max-w-sm">
              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <div className="border transform duration-300 group-hover:shadow-2xl p-4 group rounded-[7px]">
                  <Link
                    rel="noopener noreferrer"
                    className={`cursor-pointer flex items-center justify-center py-1 text-md font-semibold border-b-[3px] border-b-transparent`}
                  >
                    {/* <img
                      src={addProjects}
                      alt="image"
                      style={{ width: "15%" }}
                    /> */}
                    <p className="text-2xl ml-4 text-[#6dc7e2]">
                      Collaborations
                    </p>
                  </Link>
                </div>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "gunjan@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/Collaboration"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent mt-5 bg-[#6dc7e2]`}
                  style={{
                    border: "2px solid #6dc7e2",
                    padding: "10px",
                    color: " #ffffff",
                    borderRadius: "5px",
                    // boxShadow:"2px 2px 10px rgba(0,0,0,0.5)"
                  }}
                >
                  Create Collaborations {" >"}
                </Link>
              )}

              {(email == "amar@vasudhaindia.org" ||
                email == "srinivas@vasudhaindia.org" ||
                email == "rachit@vasudhaindia.org" ||
                email == "mohit@vasudhaindia.org" ||
                email == "preethi@vasudhaindia.org" ||
                email == "it@vasudhaindia.org") && (
                <Link
                  to="/all-Collaboration"
                  rel="noopener noreferrer"
                  className={`cursor-pointer  transform duration-300 group-hover:shadow-2xl  hover:shadow-2xl py-1 text-sm font-semibold border-b-[3px] border-b-transparent bg-[#6dc7e2] mt-5`}
                  style={{
                    border: "2px solid #6dc7e2",
                    padding: "10px",
                    // color: " #d6b015",
                    color: " #ffffff",
                    borderRadius: "5px",
                  }}
                >
                  All Collaborations {" >"}
                </Link>
              )}
            </div>
          )}
        </div>
      </div>

      <br />
      <br />

      <hr style={{ background: "rgba(0,0,0,0.5)", height: "1.5px" }} />

      {/* <div style={{borderBottom:"2px solid red"}}>
                
        </div> */}
      <br />
      <br />
    </div>
  );
}