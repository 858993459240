import { useState } from "react";
import { Spinner } from "../components/Spinner";
import { toast } from "react-toastify";
import {
  getStorage,
  ref as storageReff,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { push, ref } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import { getAuth } from "firebase/auth";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
// import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
export default function AddProject() {
  const navigate = useNavigate();
  const auth = getAuth();

  const [currency, setCurrency] = useState("");

  const [budget, setBudget] = useState([{ name: "", value: "0" }]);

  const [uploading, setUploading] = useState(false);
  //   const navigate = useNavigate();

  // -----------------------------file uploading states-------------------

  const [proposalAttachmentsDocuments, setProposalAttachmentsDocuments] =
    useState([]);
  const [conceptNotesDocuments, setConceptNotesDocuments] = useState([]);
  const [grantAgreementsDocuments, setGrantAgreementsDocuments] = useState([]);
  const [budgetsDocuments, setBudgetsDocuments] = useState([]);
  // const [loading, setLoading] = useState(false);

  const proposalAttachmentsDocumentsNames = [];
  const proposalAttachmentsDocumentsUrls = [];

  const conceptNotesAttachmentsDocumentsNames = [];
  const conceptNotesAttachmentsDocumentsUrls = [];

  const grantAgreementAttachmentsDocumentsNames = [];
  const grantAgreementAttachmentsDocumentsUrls = [];

  const budgetDocumentsNames = [];
  const budgetDocumentsUrls = [];

  // ----------------------------file uploading states nd variables ---------------------

  const [loading, setLoading] = useState(false);
  const [formData2, setFormData2] = useState({
    ReportingTimeline1: "",
  });

  const [formData, setFormData] = useState({
    ProposalId: "",
    ProjectId: "",
    ProjectName: "",
    StartDate: "",
    EndDate: "",
    ReportingTimeline: {},
    ProjectStatus: "",
    ProjectManager: "",
    FunderName: "",
  });

  const {
    ProposalId,
    ProjectId,
    ProjectName,
    StartDate,
    EndDate,
    ProjectManager,
    FunderName,
  } = formData;

  function onChange(e) {
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.files,
      }));
    }

    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
    }
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData2((prevFormData2) => ({
      ...prevFormData2,
      [id]: value,
    }));
  };

  async function onSubmit(e) {
    e.preventDefault();

    // Call handleUpload() function
    await handleUpload();

    setLoading(true);
    // console.log(formData2);
    const formDataCopy = {
      ...formData,
      proposalAttachmentsDocumentsNames,
      proposalAttachmentsDocumentsUrls,
      conceptNotesAttachmentsDocumentsNames,
      conceptNotesAttachmentsDocumentsUrls,
      grantAgreementAttachmentsDocumentsNames,
      grantAgreementAttachmentsDocumentsUrls,
      budgetDocumentsNames,
      budgetDocumentsUrls,
      ReportingTimeline: { ...formData2 },
      ReportingTimelineHalfYearly: { ...formData3 },
      ReportingTimelineYearly: { ...formData4 },
      budget,
      currency,
      Total: calculateTotal(),
      isSubmitted: false,
    };

    const activity = {
      action: "create",
      nameOfLoggedInUser: auth.currentUser.displayName,
      dateAndTime: serverTimestamp(),
      forWhat: "Project",
      ID: formData.ProjectId,
      Name: formData.ProjectName,
    };

    try {
      const docRef = await addDoc(
        collection(db, "project_details"),
        formDataCopy
      );

      await addDoc(collection(db, "activity"), activity);

      setLoading(false);
      toast.success("Project created successfully");
      setFormData({});
      setFormData2({
        ReportingTimeline1: "",
      });
      setFormData3({
        ReportingTimeline1: "",
      });
      setFormData4({
        ReportingTimeline1: "",
      });
      setBudget([{ name: "", value: "0" }]);
      setCurrency("");
    } catch (error) {
      setLoading(false);
      // console.log(error);
      toast.error("Failed to save Project data to the database");
    }

    // console.log(formDataCopy);
  }

  const changeBudgetName = (index, newName) => {
    const updatedBudget = [...budget];
    updatedBudget[index] = { ...updatedBudget[index], name: newName };
    setBudget(updatedBudget);
  };

  const changeBudgetValue = (index, newValue) => {
    const updatedBudget = [...budget];
    updatedBudget[index] = { ...updatedBudget[index], value: newValue };
    setBudget(updatedBudget);
  };

  const addBudgetField = (e) => {
    e.preventDefault();
    setBudget([...budget, { name: "", value: "0" }]);
  };

  const deleteBudgetField = (index) => {
    // e.preventDefault();
    const updatedBudget = [...budget];
    updatedBudget.splice(index, 1);
    setBudget(updatedBudget);
  };

  const deleteLastTimeline = () => {
    setFormData2((prevFormData2) => {
      const updatedFormData2 = { ...prevFormData2 };
      const timelineKeys = Object.keys(updatedFormData2);
      const lastTimelineKey = timelineKeys[timelineKeys.length - 1];
      delete updatedFormData2[lastTimelineKey];
      return updatedFormData2;
    });
  };

  const showBudgets = (e) => {
    e.preventDefault();
    // console.log(budget);
  };

  const calculateTotal = () => {
    let total = 0;
    for (let i = 0; i < budget.length; i++) {
      total += parseFloat(budget[i].value);
    }
    return total;
  };

  // -----------------file uploading functions --------------

  const handleFileChange = (event, setDocuments) => {
    const files = event.target.files;
    const documentsArray = Array.from(files);
    setDocuments((prevDocuments) => [...prevDocuments, ...documentsArray]);
  };

  const handleUpload = async () => {
    try {
      setLoading(true);

      const storage = getStorage();

      const uploadDocument = async (documents, folderName, namesArray) => {
        const uploadPromises = documents.map(async (file) => {
          const fileId = uuidv4();
          const fileExtension = file.name.split(".").pop();
          const fileName = `${fileId}.${fileExtension}`;
          const storageRef = storageReff(
            storage,
            `${ProjectId}/${folderName}/${fileName}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Calculate the percentage of the upload
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const message = `Uploading ${file.name}: ${progress.toFixed(
                  2
                )}%`;

                // console.log(message);

                // Handle progress or monitoring if needed
              },
              (error) => {
                console.error(`Error uploading ${folderName} document:`, error);
                reject(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref)
                  .then((downloadURL) => {
                    // Store the file name and URL in the corresponding variables
                    namesArray.push(fileName);
                    if (folderName === "proposalAttachments") {
                      proposalAttachmentsDocumentsUrls.push(downloadURL);
                    } else if (folderName === "conceptNotesAttachments") {
                      conceptNotesAttachmentsDocumentsUrls.push(downloadURL);
                    } else if (folderName === "grantAgreementAttachments") {
                      grantAgreementAttachmentsDocumentsUrls.push(downloadURL);
                    } else if (folderName === "budgetDocuments") {
                      budgetDocumentsUrls.push(downloadURL);
                    }

                    resolve();
                  })
                  .catch((error) => {
                    console.error("Error getting download URL:", error);
                    reject(error);
                  });
              }
            );
          });
        });

        await Promise.all(uploadPromises);
      };

      // Upload proposalAttachmentsDocuments
      await uploadDocument(
        proposalAttachmentsDocuments,
        "proposalAttachments",
        proposalAttachmentsDocumentsNames
      );

      // Upload conceptNotesDocuments
      await uploadDocument(
        conceptNotesDocuments,
        "conceptNotesAttachments",
        conceptNotesAttachmentsDocumentsNames
      );

      // Upload grantAgreementsDocuments
      await uploadDocument(
        grantAgreementsDocuments,
        "grantAgreementAttachments",
        grantAgreementAttachmentsDocumentsNames
      );

      // Upload budgetsDocuments
      await uploadDocument(
        budgetsDocuments,
        "budgetDocuments",
        budgetDocumentsNames
      );

      // Reset the input fields to null
      setProposalAttachmentsDocuments([]);
      setConceptNotesDocuments([]);
      setGrantAgreementsDocuments([]);
      setBudgetsDocuments([]);

      toast.success("Documents uploaded successfully!");
    } catch (error) {
      console.error("Error uploading documents:", error);
      toast.error("Error uploading documents");
    } finally {
      setLoading(false);
    }

    // console.log(proposalAttachmentsDocumentsNames);
    // console.log(proposalAttachmentsDocumentsUrls);
    // console.log(conceptNotesAttachmentsDocumentsNames);
    // console.log(conceptNotesAttachmentsDocumentsUrls);
    // console.log(grantAgreementAttachmentsDocumentsNames);
    // console.log(grantAgreementAttachmentsDocumentsUrls);
    // console.log(budgetDocumentsNames);
    // console.log(budgetDocumentsUrls);
  };

  // -----------------------file uploading functions---------------

  const [formData3, setFormData3] = useState({
    ReportingTimeline1: "",
  });

  const handleChange3 = (e) => {
    const { id, value } = e.target;
    setFormData3((prevFormData3) => ({
      ...prevFormData3,
      [id]: value,
    }));
  };

  const deleteLastTimeline3 = () => {
    setFormData3((prevFormData3) => {
      const updatedFormData3 = { ...prevFormData3 };
      const timelineKeys = Object.keys(updatedFormData3);
      const lastTimelineKey = timelineKeys[timelineKeys.length - 1];
      delete updatedFormData3[lastTimelineKey];
      return updatedFormData3;
    });
  };

  const [formData4, setFormData4] = useState({
    ReportingTimeline1: "",
  });

  const handleChange4 = (e) => {
    const { id, value } = e.target;
    setFormData4((prevFormData4) => ({
      ...prevFormData4,
      [id]: value,
    }));
  };

  const deleteLastTimeline4 = () => {
    setFormData4((prevFormData4) => {
      const updatedFormData4 = { ...prevFormData4 };
      const timelineKeys = Object.keys(updatedFormData4);
      const lastTimelineKey = timelineKeys[timelineKeys.length - 1];
      delete updatedFormData4[lastTimelineKey];
      return updatedFormData4;
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="max-w-6xl mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">Create Projects</h1>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col md:flex-row">
          <div className="mr-1 flex-1">
            <p className="text-l mt-6 font-semibold">Proposal ID</p>
            <input
              type="text"
              id="ProposalId"
              value={ProposalId}
              onChange={onChange}
              placeholder="Proposal ID"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
          <div className="mr-1 flex-1">
            <p className="text-l mt-6 font-semibold">Funder Name</p>
            <input
              type="text"
              id="FunderName"
              value={FunderName}
              onChange={onChange}
              placeholder="Funder Name"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
          <div className="ml-1 flex-1">
            <p className="text-l mt-6 font-semibold">Project ID</p>
            <input
              type="text"
              id="ProjectId"
              value={ProjectId}
              onChange={onChange}
              required
              placeholder="Project ID"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          <div className="ml-1 flex-1">
            <p className="text-l mt-6 font-semibold">Project Name</p>
            <input
              type="text"
              id="ProjectName"
              value={ProjectName}
              onChange={onChange}
              placeholder="Project Name"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          <div className="ml-1 flex-1">
            <p className="text-l mt-6 font-semibold">Start Date</p>
            <input
              type="date"
              id="StartDate"
              value={StartDate}
              onChange={onChange}
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          <div className="ml-1 flex-1">
            <p className="text-l mt-6 font-semibold">End Date</p>
            <input
              type="date"
              id="EndDate"
              value={EndDate}
              onChange={onChange}
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
        </div>

        <hr></hr>

        {/* Reporting timeline */}
        <div className="my-3">
          <p className="text-l font-semibold my-5">Reporting Timeline</p>

          <div className="flex">
            <div className="flex flex-wrap w-11/12">
              {Object.keys(formData2).map((id, index) => (
                <div
                  key={id}
                  className="w-full sm:w-1/2 md:w-1/4 lg:w-1/5 pt-1 pr-1"
                >
                  <input
                    type="date"
                    id={id}
                    value={formData2[id] || ""}
                    onChange={handleChange}
                    placeholder={`Enter value for ${id}`}
                    className="w-full p-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
                  />
                </div>
              ))}
            </div>

            <div className="flex justify-end my-auto w-1/12">
              <div className="flex flex-col">
                <button
                  type="button"
                  onClick={() => deleteLastTimeline()}
                  className="rounded mb-2"
                  title="Delete"
                >
                  <AiOutlineMinusCircle className="text-4xl text-white border rounded-full bg-red-500 hover:bg-red-700" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const newId = `ReportingTimeline${
                      Object.keys(formData2).length + 1
                    }`;
                    setFormData2((prevFormData2) => ({
                      ...prevFormData2,
                      [newId]: "",
                    }));
                  }}
                  className=""
                >
                  <AiOutlinePlusCircle
                    className="text-4xl text-white border rounded-full bg-blue-500 hover:bg-blue-700"
                    title="Add more timelines"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr></hr>

        {/* Reporting timeline Half yearly*/}
        <div className="my-3">
          <p className="text-l font-semibold my-5">
            Reporting Timeline Half yearly
          </p>
          <div className="flex">
            <div className="flex flex-wrap w-11/12">
              {Object.keys(formData3).map((id, index) => (
                <div
                  key={id}
                  className="w-full sm:w-1/2 md:w-1/4 lg:w-1/5 pt-1 pr-1"
                >
                  <input
                    type="date"
                    id={id}
                    value={formData3[id] || ""}
                    onChange={handleChange3}
                    placeholder={`Enter value for ${id}`}
                    className="w-full p-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
                  />
                </div>
              ))}
            </div>

            <div className="flex justify-end my-auto w-1/12">
              <div className="flex flex-col">
                <button
                  type="button"
                  onClick={() => deleteLastTimeline3()}
                  className="rounded mb-2"
                  title="Delete"
                >
                  <AiOutlineMinusCircle className="text-4xl text-white border rounded-full bg-red-500 hover:bg-red-700" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const newId = `ReportingTimeline${
                      Object.keys(formData3).length + 1
                    }`;
                    setFormData3((prevFormData3) => ({
                      ...prevFormData3,
                      [newId]: "",
                    }));
                  }}
                  className=""
                >
                  <AiOutlinePlusCircle
                    className="text-4xl text-white border rounded-full bg-blue-500 hover:bg-blue-700"
                    title="Add more timelines"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr></hr>

        {/* Reporting Timeline Yearly*/}

        <div className="my-3">
          <p className="text-l font-semibold my-5">Reporting Timeline Yearly</p>
          <div className="flex">
            <div className="flex flex-wrap w-11/12">
              {Object.keys(formData4).map((id, index) => (
                <div
                  key={id}
                  className="w-full sm:w-1/2 md:w-1/4 lg:w-1/5 pt-1 pr-1"
                >
                  <input
                    type="date"
                    id={id}
                    value={formData4[id] || ""}
                    onChange={handleChange4}
                    placeholder={`Enter value for ${id}`}
                    className="w-full p-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
                  />
                </div>
              ))}
            </div>

            <div className="flex justify-end my-auto w-1/12">
              <div className="flex flex-col">
                <button
                  type="button"
                  onClick={() => deleteLastTimeline4()}
                  className="rounded mb-2"
                  title="Delete"
                >
                  <AiOutlineMinusCircle className="text-4xl text-white border rounded-full bg-red-500 hover:bg-red-700" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const newId = `ReportingTimeline${
                      Object.keys(formData4).length + 1
                    }`;
                    setFormData4((prevFormData4) => ({
                      ...prevFormData4,
                      [newId]: "",
                    }));
                  }}
                  className=""
                >
                  <AiOutlinePlusCircle
                    className="text-4xl text-white border rounded-full bg-blue-500 hover:bg-blue-700"
                    title="Add more timelines"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr></hr>

        {/* Submit button */}

        {/* Budget */}

        <div className="flex flex-row space-x-3  w-full">
          <div className="mt-6 flex-1 ">
            <p className="text-l font-semibold">Budget</p>
            <select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              className="px-4 w-full  py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6 mr-5"
            >
              <option value="">--Choose Currency--</option>
              <option value="INR">INR</option>
              <option value="USD">USD</option>
              <option value="INR">EURO</option>
              {/* Add more currency options as needed */}
            </select>

            {budget.map((item, index) => (
              <div key={index} className="flex">
                <div className="flex-1">
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) => changeBudgetName(index, e.target.value)}
                    placeholder="Head"
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>

                <div className="ml-1 flex-1">
                  <input
                    type="number"
                    value={item.value}
                    onChange={(e) => changeBudgetValue(index, e.target.value)}
                    placeholder="Value"
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>

                {budget.length > 1 && (
                  <button
                    type="button"
                    onClick={() => deleteBudgetField(index)}
                    className="rounded ml-1"
                    title="Delete this Budget"
                  >
                    <AiOutlineMinusCircle className="text-4xl mb-6 text-white border rounded-full bg-red-500 hover:bg-red-700" />
                  </button>
                )}
              </div>
            ))}
            {/* Total: {calculateTotal()} {currency} */}
            <div className="flex w-full items-center mb-6 ">
              <input
                value={`Total: ${calculateTotal()} ${currency}`}
                className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mr-2"
              />
              <button
                onClick={addBudgetField}
                title="Add more Budgets"
                className="py-2"
              >
                <AiOutlinePlusCircle className="text-white hover:bg-blue-700 text-4xl border rounded-full bg-blue-500" />
              </button>
            </div>

            {/* <button onClick={showBudgets}>Show Timelines</button> */}
          </div>

          <div className="flex-1 w-full ">{/* ..kjndkjhdi */}</div>
        </div>

        <div className="flex space-x-4">
          {/* Project Status */}
          <div className="flex-1">
            <p className="text-l font-semibold">Project Status</p>
            <select
              id="ProjectStatus"
              onChange={onChange}
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            >
              <option value="" className="text-gray-700">
                --Choose one--
              </option>
              <option value="ongoing" className="text-gray-700">
                Ongoing
              </option>
              <option value="completed" className="text-gray-700">
                Completed
              </option>
            </select>
          </div>

          {/* Project manager */}

          <div className="flex-1">
            <p className="text-l font-semibold">Project Incharge</p>
            <input
              type="text"
              id="ProjectManager"
              value={ProjectManager}
              onChange={onChange}
              placeholder="Project Incharge"
              className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
        </div>

        {/* file upload */}

        {/* ----------------------file uploa input fields------------ */}

        <>
          <div className="flex flex-wrap mb-4">
            <div className="w-1/2 pr-2 mb-4">
              <p className="mb-2">Proposal Attachments</p>
              <input
                className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                type="file"
                multiple
                onChange={(e) =>
                  handleFileChange(e, setProposalAttachmentsDocuments)
                }
              />
              {/* Render the uploaded proposal attachments files */}
              {proposalAttachmentsDocuments.map((file) => (
                <div key={file.name}>{file.name}</div>
              ))}
            </div>

            <div className="w-1/2 pl-2 mb-4">
              <h2 className="mb-2">Concept Notes</h2>
              <input
                className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                type="file"
                multiple
                onChange={(e) => handleFileChange(e, setConceptNotesDocuments)}
              />
              {/* Render the uploaded concept notes files */}
              {conceptNotesDocuments.map((file) => (
                <div key={file.name}>{file.name}</div>
              ))}
            </div>
          </div>

          <div className="flex flex-wrap mb-4">
            <div className="w-1/2 pr-2 mb-4">
              <h1 className="mb-2">Grant Agreements</h1>
              <input
                className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                type="file"
                multiple
                onChange={(e) =>
                  handleFileChange(e, setGrantAgreementsDocuments)
                }
              />
              {/* Render the uploaded grant agreements files */}
              {grantAgreementsDocuments.map((file) => (
                <div key={file.name}>{file.name}</div>
              ))}
            </div>

            <div className="w-1/2 pl-2 mb-4">
              <h1 className="mb-2">Budgets</h1>
              <input
                className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                type="file"
                multiple
                onChange={(e) => handleFileChange(e, setBudgetsDocuments)}
              />
              {/* Render the uploaded budgets files */}
              {budgetsDocuments.map((file) => (
                <div key={file.name}>{file.name}</div>
              ))}
            </div>
          </div>

          {/* <button
  type="button"
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    onClick={handleUpload}
  >
    Upload Documents
  </button> */}
        </>

        {/* --------------------file upload input fields------------------ */}

        <div className="mx-auto max-w-md">
          <button
            type="submit"
            className=" mb-6 w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mx-auto"
          >
            Save
          </button>
        </div>
      </form>
    </main>
  );
}
