import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { CgProfile } from 'react-icons/cg';
import { FaSignOutAlt } from 'react-icons/fa';
import BackButton from "./BackButton";


export default function Header() {
  const [pageState, setPageState] = useState("Sign in");
  const [logoutState, setLogoutState] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState("Profile");
        setLogoutState(true);
      } else {
        setPageState("Sign in");
        setLogoutState(false);
      }
    });
  }, [auth]);
  function pathMatchRoute(route) {
    if (route === location.pathname) {
      return true;
    }
  }

  function onLogout() {
    auth.signOut();
    navigate("/");
    window.location.reload();
  }

  return (
    <>
    <div className="bg-white border-b shadow-sm sticky top-0 z-50">
      <header className="flex justify-between items-center px-3 max-w-6xl mx-auto">
        <div>
          <img
            src="http://www.vasudha-foundation.org/wp-content/uploads/Untitled-13-1.jpg"
            alt="logo"
            className="h-20 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <div>
          <ul className="flex space-x-10">
            {/* {logoutState &&(<li
              className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                pathMatchRoute("/") && "text-black border-b-red-500"
              }`}
              onClick={() => navigate("/")}
            >
              Home
            </li>)} */}
            {logoutState && (<li
              className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                pathMatchRoute("/all-employee") &&
                "text-black border-b-red-500"
              }`}
              onClick={() => navigate("/all-employee")}
            >
             Employees
            </li>)}

            {logoutState && (<li
              className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                pathMatchRoute("/all-Projects") &&
                "text-black border-b-red-500"
              }`}
              onClick={() => navigate("/all-Projects")}
            >
             Projects
            </li>)}

            {logoutState &&(<li
              className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                (pathMatchRoute("/sign-in") || pathMatchRoute("/profile")) &&
                "text-black border-b-red-500"
              }`}
              onClick={() => navigate("/profile")}
            >
              {/* Profile */}
              <CgProfile className=" text-2xl  "/>
            </li>)}

            {logoutState && (
              <li
                className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                  pathMatchRoute("/create-employee")
                    ? "text-black border-b-red-500"
                    : ""
                }`}
                onClick={onLogout}
              >
                {/* Logout */}
                <FaSignOutAlt className=" text-2xl  "/>
              </li>
            )}
          </ul>
        </div>
      </header>
    </div>
    {(logoutState && location.pathname !== "/" )&&(<div>
      <BackButton/>
    </div>)}
    </>
  );
}
