import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import XLSX from "xlsx/dist/xlsx.full.min.js";

import { Spinner } from "../components/Spinner";

const DownloadEmployee = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "employee_details"));
        const data = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setEmployeeData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
      }
    };
    fetchEmployeeData();
  }, []);

  const handleEmployeeSelection = (event) => {
    const employeeId = event.target.value;
    if (event.target.checked) {
      setSelectedEmployees((prevSelected) => [...prevSelected, employeeId]);
    } else {
      setSelectedEmployees((prevSelected) =>
        prevSelected.filter((id) => id !== employeeId)
      );
    }
    setSelectAll(false); // Deselect "Select All" when individual checkboxes are modified
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allEmployeeIds = employeeData.map((employee) => employee.id);
      setSelectedEmployees(allEmployeeIds);
    } else {
      setSelectedEmployees([]);
    }
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  const generateExcel = () => {
    const selectedEmployeeData = employeeData.filter((employee) =>
      selectedEmployees.includes(employee.id)
    );
    const worksheet = XLSX.utils.json_to_sheet(selectedEmployeeData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

    const workbookBlob = new Blob(
      [s2ab(XLSX.write(workbook, { bookType: "xlsx", type: "binary" }))],
      {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    );

    const url = URL.createObjectURL(workbookBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "selected_employees.xlsx";
    a.click();
    URL.revokeObjectURL(url);
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-white flex flex-col items-center justify-center rounded-lg ">
      <div className="mb-2">
        <input
          type="checkbox"
          value="all"
          checked={selectAll}
          onChange={handleSelectAll}
          className="mr-2"
        />
        Select All
      </div>

      <hr></hr>
      <div className="grid grid-cols-3 gap-4">
        {employeeData.map((employee) => (
          <div key={employee.id} className="flex items-center">
            <input
              type="checkbox"
              value={employee.id}
              onChange={handleEmployeeSelection}
              checked={selectedEmployees.includes(employee.id)}
              className="mr-2"
            />
            {employee.EmployeeName}
          </div>
        ))}
      </div>
      <button
        onClick={generateExcel}
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
      >
        Download Excel
      </button>
    </div>
  );
};

export default DownloadEmployee;
