import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highcharts";
import highchartsSankey from "highcharts/modules/sankey";
import highchartsOrgChart from "highcharts/modules/organization";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsAccessibility from "highcharts/modules/accessibility";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
// Initialize Highcharts modules
highchartsSankey(Highcharts);
highchartsOrgChart(Highcharts);
highchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);

const OrgChart = () => {
  const [employeeData, setEmployeeData] = useState([]);

  const [reducedEmployee, setReducedEmployee] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // employee call

    const fetchEmployeeData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "employee_details"));
        const data = querySnapshot.docs.map((doc) => {
          return {
            //   id: doc.id,
            ...doc.data(),
          };
        });
        setEmployeeData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
      }
    };
    fetchEmployeeData();

    const extractedDataaa = employeeData.map((employee) => [
      employee.ReportingManager,
      employee.EmployeeName,
    ]);

    // Your extracted data
    const extractedData = employeeData.map((employee) => ({
      id: employee.EmployeeName,
      name: employee.EmployeeName,
      reportingManager: employee.ReportingManager,
      // Add other properties as needed
    }));

    // Merge additionalNodes with extractedData
    const allNodes = [...extractedData];

    // Now, use allNodes in your nodes array
    const nodes = allNodes.map((node) => ({
      id: node.id,
      title: node.title,
      name: node.name,
      // Add other properties from node as needed
    }));

    //   ---0

    const chartConfig = {
      chart: {
        height: 600,
        nodeWidth: "500",
        inverted: true,
        renderTo: "container", // Use renderTo to target the div
      },
      title: {
        text: "Highcharts Org Chart",
      },
      accessibility: {
        point: {
          descriptionFormat:
            "{add index 1}. {toNode.name}" +
            "{#if (ne toNode.name toNode.id)}, {toNode.id}{/if}, " +
            "reports to {fromNode.id}",
        },
      },
      series: [
        {
          type: "organization",
          name: "Highsoft",
          keys: ["from", "to"],
          data: [...extractedDataaa],
          levels: [
            {
              level: 0,
              color: "silver",
              dataLabels: {
                color: "black",
              },
              height: 25,
            },
            {
              level: 1,
              color: "silver",
              dataLabels: {
                color: "black",
              },
              height: 25,
            },
            {
              level: 2,
              color: "#980104",
            },
            {
              level: 3,
              color: "#359154",
            },
            {
              level: 4,
              color: "pink",
            },
          ],
          nodes: nodes,

          colorByPoint: false,
          color: "#007ad0",
          dataLabels: {
            color: "white",
          },
          borderColor: "white",
          nodeWidth: 65,
        },
      ],
      tooltip: {
        outside: true,
      },

      exporting: {
        allowHTML: true,
        sourceWidth: 800,
        sourceHeight: 600,
      },
    };

    // Create the chart
    let chart = null; // Initialize chart variable

    chart = Highcharts.chart(chartConfig);

    return () => {
      if (chart) {
        chart.destroy(); // Destroy the chart and free up resources
      }

      // Clean up the chart on unmount if necessary
      // Highcharts charts can sometimes need cleanup
    };
  }, []);

  console.log(employeeData);

  return (
    <div>
      <figure
        className="highcharts-figure"
        style={{ backgroundColor: "red!important" }}
      >
        <div id="container"></div>
      </figure>
    </div>
  );
};

export default OrgChart;
