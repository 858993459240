import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import XLSX from "xlsx/dist/xlsx.full.min.js";

import { Spinner } from "../components/Spinner";

const DownloadProjects = () => {
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "project_details"));
        const data = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setProjectData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };

    fetchProjectData();
  }, []);

  const handleProjectSelection = (event) => {
    const projectId = event.target.value;
    if (event.target.checked) {
      setSelectedProjects((prevSelected) => [...prevSelected, projectId]);
    } else {
      setSelectedProjects((prevSelected) =>
        prevSelected.filter((id) => id !== projectId)
      );
    }
    setSelectAll(false);
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allProjectIds = projectData.map((project) => project.id);
      setSelectedProjects(allProjectIds);
    } else {
      setSelectedProjects([]);
    }
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  const generateExcel = () => {
    const selectedProjectData = projectData.filter((project) =>
      selectedProjects.includes(project.id)
    );
    const worksheet = XLSX.utils.json_to_sheet(selectedProjectData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Project Data");

    const workbookBlob = new Blob(
      [s2ab(XLSX.write(workbook, { bookType: "xlsx", type: "binary" }))],
      {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    );

    const url = URL.createObjectURL(workbookBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "selected_projects.xlsx";
    a.click();
    URL.revokeObjectURL(url);
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-white flex flex-col items-center justify-center rounded-lg">
      <div className="mb-2">
        <input
          type="checkbox"
          value="all"
          checked={selectAll}
          onChange={handleSelectAll}
          className="mr-2"
        />
        Select All
      </div>

      <hr />

      <div className="grid grid-cols-3 gap-4">
        {projectData.map((project) => (
          <div key={project.id} className="flex items-center">
            <input
              type="checkbox"
              value={project.id}
              onChange={handleProjectSelection}
              checked={selectedProjects.includes(project.id)}
              className="mr-2"
            />
            {project.ProjectName}
          </div>
        ))}
      </div>

      <button
        onClick={generateExcel}
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
      >
        Download Excel
      </button>
    </div>
  );
};

export default DownloadProjects;
