import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { AiFillCaretDown } from "react-icons/ai";
import { Spinner } from "../components/Spinner";
import { Bar, Pie } from "react-chartjs-2";
import { Chart } from "chart.js"; // Import Chart from 'chart.js'
import {
  BarController,
  LinearScale,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js"; // Import BarElement
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS file for styling

const EmployeeWiseOverview = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState("");
  const [taskAllocationDetails, setTaskAllocationDetails] = useState([]);
  const [reducedTaskAllocation, setReducedTaskAllocation] = useState([]);
  const [
    monthlyTotalOfReducedTaskAllocation,
    setMonthlyTotalOfReducedTaskAllocation,
  ] = useState();



  // const [selectedDate, setSelectedDate] = useState("");

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const initialSelectedDate = `${currentYear}-${currentMonth}`;

  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);






  const [selectedMonthYearEmployee, setSelectedMonthYearEmployee] =
    useState("");

  const [isEditing, setIsEditing] = useState(false);

  const [detailsOfRowWhichIsEditing, setDetailsOfRowWhichIsEditing] = useState(
    {}
  );

  useEffect(() => {
    setSelectedMonthYearEmployee(
      `${formatDate(selectedDate)}_${selectedEmployee}`
    );
  }, [selectedEmployee, selectedDepartment, selectedDate]);

  useEffect(() => {
    // Assuming you have an array called `employeeData` that contains the data of all employees
    // Filter the array to find the data for the selected employee
    const selectedEmployeeData = employeeData.filter(
      (item) => item.EmployeeName === selectedEmployee
    );

    // Now `selectedEmployeeData` will contain an array with items that have the selected employee's data
    // You can access the first item (if it exists) using selectedEmployeeData[0]
    // For example, if you want to log the data for the selected employee, you can do:
    if (selectedEmployeeData.length > 0) {
      // console.log("Selected Employee Data:", selectedEmployeeData[0]);
    }

    // Rest of your code...

    setSelectedEmployeeDetails(selectedEmployeeData);
  }, [selectedEmployee]);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "employee_details"));
        const data = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setEmployeeData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
      }
    };

    fetchEmployeeData();
  }, []);

  // console.log(employeeData);
  // useEffect(() => {
  //   const fetchTaskAllocation = async () => {
  //     try {
  //       const querySnapshot = await getDocs(collection(db, "taskAllocation"));
  //       const data = querySnapshot.docs.map((doc) => {
  //         return {
  //           id: doc.id,
  //           ...doc.data(),
  //         };
  //       });
  //       setTaskAllocationDetails(data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Failed to fetch employee data:", error);
  //     }
  //   };
  //   fetchTaskAllocation();
  // }, []);

  const fetchTaskAllocation = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "taskAllocation"));
      const data = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setTaskAllocationDetails(data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch employee data:", error);
    }
  };

  useEffect(() => {
    fetchTaskAllocation();
  }, []); // Empty dependency array ensures it runs only once

  // console.log(taskAllocationDetails);

  useEffect(() => {
    // Update the reducedTaskAllocation and calculate the sum whenever taskAllocationDetails or selectedMonthYearEmployee changes
    if (selectedEmployee.length > 0 && selectedDate.length > 0) {
      // Perform the reduction only if selectedEmployee and selectedDate have valid values
      if (taskAllocationDetails.length > 0 && selectedMonthYearEmployee) {
        const reducedData = taskAllocationDetails
          .map(
            ({
              id,
              [selectedMonthYearEmployee]: value,
              ProjectName,
              FunderName,
            }) => ({
              id,
              [selectedMonthYearEmployee]: value,
              ProjectName,
              FunderName,
            })
          )
          .filter((item) => item[selectedMonthYearEmployee] !== undefined);

        // Calculate the sum of all values of [selectedMonthYearEmployee]: value
        const sum = reducedData.reduce((accumulator, item) => {
          const value = parseFloat(item[selectedMonthYearEmployee]);
          return isNaN(value) ? accumulator : accumulator + value;
        }, 0);

        setReducedTaskAllocation(reducedData);
        setMonthlyTotalOfReducedTaskAllocation(sum);
      }
    }
  }, [taskAllocationDetails, selectedMonthYearEmployee]);

  // console.log(reducedTaskAllocation);

  // Handle month selection change
  // const handleMonthChange = (e) => {
  //   setSelectedMonth(e.target.value);
  // };

  // Get unique departments from employeeData
  const departments = [
    ...new Set(employeeData.map((employee) => employee.Department)),
  ];

  // Filter employees based on the selected department
  const employeesInSelectedDepartment = employeeData.filter(
    (employee) => employee.Department === selectedDepartment
  );

  // Handle department selection change
  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
    setSelectedEmployee(""); // Reset selected employee when the department changes
    setSelectedDate("");
    setReducedTaskAllocation([]);
  };

  // Handle employee selection change
  const handleEmployeeChange = (e) => {
    setSelectedEmployee(e.target.value);
  };

  // Handle date input change
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  // Helper function to convert the selected date to the custom format (e.g., MAY-23, APR-24)
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString().substring(2);
    return `${month}-${year}`;
  };

  // Create data for the chart
  const chartData = {
    labels: reducedTaskAllocation.map((item) => item.id),
    datasets: [
      {
        label: selectedMonthYearEmployee,
        data: reducedTaskAllocation.map(
          (item) => item[selectedMonthYearEmployee]
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  // Register the "category" scale and "bar" element
  useEffect(() => {
    Chart.register(
      BarController,
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip
    ); // Register BarElement
  }, []);

  // Prepare data for the pie chart
  const pieChartData = {
    chart: {
      type: "pie",
      backgroundColor: "transparent", // Set the background color to transparent
    },
    
    // title: {
    //   text: `Percentage Allocated for month ${formatDate(
    //     selectedDate
    //   )} for employee ${selectedEmployee}`,
    //   // margin:-50,
    // },
   
    plotOptions: {
      pie: {
        size: "50%", // Set the size to a constant value (e.g., "100%")
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    colors: [
      // Define colors for the slices based on the condition
      monthlyTotalOfReducedTaskAllocation > 100
        ? "red"
        : "rgba(75, 192, 192, 0.7)",
      "rgba(255, 99, 132, 0.7)",
    ],
    series: [
      {
        name: "Task Allocation",
        data: [
          {
            name: "Allocated",
            y: monthlyTotalOfReducedTaskAllocation,
          },
          {
            name: "Remaining",
            y: 100 - monthlyTotalOfReducedTaskAllocation,
          },
        ],
      },
    ],
  };

  // const editPercentage = (itemId) => {
  //   // Do something with itemId and selectedDate
  //   console.log("Edit button clicked for Item ID:", itemId);
  //   {reducedTaskAllocation}
  //   setIsEditing(true);
  // };

  // const editPercentage = (itemId) => {
  //   // Find the array with the matching id
  //   const selectedArray = reducedTaskAllocation.find(
  //     (item) => item.id === itemId
  //   );

  //   if (selectedArray) {
  //     console.log("Array with matching id:", selectedArray);
  //   } else {
  //     console.log("No array found with matching id.");
  //   }
  //   setDetailsOfRowWhichIsEditing(selectedArray);
  //   setIsEditing(true);
  // };

  const editPercentage = (itemId) => {
    // Find the array with the matching id
    const selectedArray = reducedTaskAllocation.find(
      (item) => item.id === itemId
    );

    if (selectedArray) {
      // console.log("Array with matching id:", selectedArray);
    } else {
      // console.log("No array found with matching id.");
    }
    setDetailsOfRowWhichIsEditing(selectedArray || {}); // Initialize as an object if no array is found
    setIsEditing(true);
  };

  const handleClose = () => {
    setIsEditing(false);
  };

  // const handleSave = () => {
  //   // Check if the required data is available
  //   if (
  //     detailsOfRowWhichIsEditing &&
  //     selectedEmployee &&
  //     selectedDate &&
  //     reducedTaskAllocation.length > 0
  //   ) {
  //     // Convert the edited percentage value to a number
  //     const editedPercentage = parseFloat(
  //       detailsOfRowWhichIsEditing[`${formatDate(selectedDate)}_${selectedEmployee}`]
  //     );

  //     console.log("Edited Percentage:", editedPercentage);

  //     // Create a new array with the updated value
  //     const updatedTaskAllocation = reducedTaskAllocation.map((item) => {
  //       // Check if the current item matches the item being edited
  //       if (
  //         item.id === detailsOfRowWhichIsEditing.id &&
  //         item[`${formatDate(selectedDate)}_${selectedEmployee}`] !== undefined
  //       ) {
  //         console.log("Updating item:", item.id);
  //         // Update the value with the edited percentage
  //         return {
  //           ...item,
  //           [`${formatDate(selectedDate)}_${selectedEmployee}`]: editedPercentage,
  //         };
  //       } else {
  //         // Return the item as it is (no change)
  //         return item;
  //       }
  //     });

  //     console.log("Updated Task Allocation:", updatedTaskAllocation);
  //     // Replace the old reducedTaskAllocation array with the updated one
  //     setReducedTaskAllocation(updatedTaskAllocation);
  //   }
  //   setIsEditing(false); // Reset the isEditing state
  // };

  // const handleSave = async () => {
  //   // Check if the required data is available
  //   if (
  //     detailsOfRowWhichIsEditing &&
  //     selectedEmployee &&
  //     selectedDate &&
  //     reducedTaskAllocation.length > 0
  //   ) {
  //     // Get the edited percentage value as a string
  //     const editedPercentage = detailsOfRowWhichIsEditing[`${formatDate(selectedDate)}_${selectedEmployee}`];

  //     console.log("Edited Percentage:", editedPercentage);

  //     // Create a new array with the updated value
  //     const updatedTaskAllocation = reducedTaskAllocation.map((item) => {
  //       // Check if the current item matches the item being edited
  //       if (
  //         item.id === detailsOfRowWhichIsEditing.id &&
  //         item[`${formatDate(selectedDate)}_${selectedEmployee}`] !== undefined
  //       ) {
  //         console.log("Updating item:", item.id);
  //         // Update the value with the edited percentage as a string
  //         return {
  //           ...item,
  //           [`${formatDate(selectedDate)}_${selectedEmployee}`]: editedPercentage,
  //         };
  //       } else {
  //         // Return the item as it is (no change)
  //         return item;
  //       }
  //     });

  //     console.log("Updated Task Allocation:", updatedTaskAllocation);
  //     // Replace the old reducedTaskAllocation array with the updated one
  //     setReducedTaskAllocation(updatedTaskAllocation);

  //     const sum = updatedTaskAllocation.reduce((accumulator, item) => {
  //       const value = parseFloat(item[selectedMonthYearEmployee]);
  //       return isNaN(value) ? accumulator : accumulator + value;
  //     }, 0);

  //     setMonthlyTotalOfReducedTaskAllocation(sum);

  //   }

  //   try {
  //     // Get the reference to the document
  //     const docRef = doc(db, "taskAllocation", detailsOfRowWhichIsEditing.id);

  //     // Retrieve the current document data
  //     const docSnap = await getDoc(docRef);
  //     const editedPercentage = detailsOfRowWhichIsEditing[`${formatDate(selectedDate)}_${selectedEmployee}`];

  //     // Check if the document exists
  //     if (docSnap.exists()) {
  //       const dataToUpdate = {
  //         ...docSnap.data(), // Get the existing data
  //         [`${formatDate(selectedDate)}_${selectedEmployee}`]: editedPercentage, // Update the specific field
  //       };

  //       // Update the document with the new data
  //       await updateDoc(docRef, dataToUpdate);

  //       console.log("Project data updated successfully");
  //       // Reset the editing state
  //       setIsEditing(false);
  //       // localStorage.removeItem("projectIdOfDataToBeEdited");
  //   fetchTaskAllocation();

  //     } else {
  //       console.log("Document does not exist");
  //     }
  //   } catch (error) {
  //     console.error("Failed to upload edited project data:", error);
  //   }

  //   setIsEditing(false); // Reset the isEditing state
  // };

  const handleSave = async () => {
    // Set loading to true to indicate that the update is in progress

    // Check if the required data is available
    if (
      detailsOfRowWhichIsEditing &&
      selectedEmployee &&
      selectedDate &&
      reducedTaskAllocation.length > 0
    ) {
      // Get the edited percentage value as a string
      const editedPercentage =
        detailsOfRowWhichIsEditing[
          `${formatDate(selectedDate)}_${selectedEmployee}`
        ];

      // console.log("Edited Percentage:", editedPercentage);

      // Create a new array with the updated value
      const updatedTaskAllocation = reducedTaskAllocation.map((item) => {
        // Check if the current item matches the item being edited
        if (
          item.id === detailsOfRowWhichIsEditing.id &&
          item[`${formatDate(selectedDate)}_${selectedEmployee}`] !== undefined
        ) {
          // console.log("Updating item:", item.id);
          // Update the value with the edited percentage as a string
          return {
            ...item,
            [`${formatDate(selectedDate)}_${selectedEmployee}`]:
              editedPercentage,
          };
        } else {
          // Return the item as it is (no change)
          return item;
        }
      });

      // console.log("Updated Task Allocation:", updatedTaskAllocation);
      // Replace the old reducedTaskAllocation array with the updated one
      setReducedTaskAllocation(updatedTaskAllocation);

      const sum = updatedTaskAllocation.reduce((accumulator, item) => {
        const value = parseFloat(item[selectedMonthYearEmployee]);
        return isNaN(value) ? accumulator : accumulator + value;
      }, 0);

      setMonthlyTotalOfReducedTaskAllocation(sum);
    }

    try {
      // setLoading(true);

      
      // Get the reference to the document
      const docRef = doc(db, "taskAllocation", detailsOfRowWhichIsEditing.id);

      // Retrieve the current document data
      const docSnap = await getDoc(docRef);
      const editedPercentage =
        detailsOfRowWhichIsEditing[
          `${formatDate(selectedDate)}_${selectedEmployee}`
        ];

      // Check if the document exists
      if (docSnap.exists()) {
        const dataToUpdate = {
          ...docSnap.data(), // Get the existing data
          [`${formatDate(selectedDate)}_${selectedEmployee}`]: editedPercentage, // Update the specific field
        };

        // Update the document with the new data
        await updateDoc(docRef, dataToUpdate);

        // console.log("Project data updated successfully");
        // Reset the editing state
        setIsEditing(false);
        // localStorage.removeItem("projectIdOfDataToBeEdited");
        fetchTaskAllocation();
      } else {
        // console.log("Document does not exist");
      }
    } catch (error) {
      console.error("Failed to upload edited project data:", error);
    }

    // Reset loading to false after the update is complete
    setLoading(false);
  };

  useEffect(() => {
    // console.log("reducedTaskAllocation updated:", reducedTaskAllocation);
  }, [reducedTaskAllocation]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="max-w-6xl mx-auto">
      <h1 className="text-3xl text-center mt-6 m-6 font-bold">
        Employee engagment in projects
      </h1>

      {/* <div className="grid grid-cols-2 gap-4 mt-6 mx-6">
        <div>
          <label
            htmlFor="department"
            className="block text-sm font-medium text-gray-700"
          >
            Select Department:
          </label>
          <select
            id="department"
            value={selectedDepartment}
            onChange={handleDepartmentChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">--Select Department--</option>
            {departments.map((dept) => (
              <option key={dept} value={dept}>
                {dept}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="employee"
            className="block text-sm font-medium text-gray-700"
          >
            Select Employee:
          </label>
          <select
            id="employee"
            value={selectedEmployee}
            onChange={handleEmployeeChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">--Select Employee--</option>
            {selectedDepartment
              ? employeesInSelectedDepartment.map((employee) => (
                  <option key={employee.id} value={employee.EmployeeName}>
                    {employee.EmployeeName}
                  </option>
                ))
              : employeeData.map((employee) => (
                  <option key={employee.id} value={employee.EmployeeName}>
                    {employee.EmployeeName}
                  </option>
                ))}
          </select>
        </div>

        <div>
          <label
            htmlFor="customDate"
            className="block text-sm font-medium text-gray-700"
          >
            Select Month and Year:
          </label>
          <input
            type="date"
            id="customDate"
            value={selectedDate}
            onChange={handleDateChange}
            max={`${new Date().getFullYear()}-${(
              "0" +
              (new Date().getMonth() + 1)
            ).slice(-2)}`}
            min="2010-01"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
      </div> */}

      <div className="flex gap-4 mt-6 mx-6">
        <div className="w-full sm:w-1/2 md:w-1/3">
          <label
            htmlFor="department"
            className="block text-sm font-medium text-gray-700"
          >
            Select Department:
          </label>
          <select
            id="department"
            value={selectedDepartment}
            onChange={handleDepartmentChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">--Select Department--</option>
            {departments.map((dept) => (
              <option key={dept} value={dept}>
                {dept}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3">
          <label
            htmlFor="employee"
            className="block text-sm font-medium text-gray-700"
          >
            Select Employee:
          </label>
          <select
            id="employee"
            value={selectedEmployee}
            onChange={handleEmployeeChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">--Select Employee--</option>
            {selectedDepartment
              ? employeesInSelectedDepartment.map((employee) => (
                  <option key={employee.id} value={employee.EmployeeName}>
                    {employee.EmployeeName}
                  </option>
                ))
              : employeeData.map((employee) => (
                  <option key={employee.id} value={employee.EmployeeName}>
                    {employee.EmployeeName}
                  </option>
                ))}
          </select>
        </div>

        {/* Months and Year input */}
        <div className="w-full sm:w-1/2 md:w-1/3">
          <label
            htmlFor="customDate"
            className="block text-sm font-medium text-gray-700"
          >
            Select Month and Year:
          </label>

          <input
            type="month"
            id="customDate"
            value={selectedDate}
            onChange={handleDateChange}
            placeholder=""
            min="2010-01"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
      </div>

      {reducedTaskAllocation.length > 0 && (
        <div className="mt-6 mx-6 flex ">
          {/* Table */}
          <div className="flex-1 md-1/2">
            {/* <h2 className="text-xl font-bold">Reduced Task Allocation:</h2> */}
            {/* <h2 className="text-xl font-bold">
              Task Allocation Project wise for the for month{" "}
              {formatDate(selectedDate)} for employee {selectedEmployee}:
            </h2> */}
            <h3 className="text-l"> Name: {selectedEmployee}</h3>
            <h3 className="text-l">
              {" "}
              Department: {selectedEmployeeDetails[0].Department}
            </h3>
            <h3 className="text-l">
              {" "}
              Designation: {selectedEmployeeDetails[0].Designation}
            </h3>

            <table className="table-auto w-full mt-10">
              <thead>
                <tr>
                  <th className="px-4 border py-2">Project Id</th>
                  <th className="px-4 border py-2">Project Name</th>
                  <th className="px-4 border py-2">Funding Agency</th>
                  <th className="px-4 border py-2">
                    {" "}
                    Budgeted for month {formatDate(selectedDate)}
                  </th>
                  <th className="px-4 border py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {reducedTaskAllocation.map((item) => (
                  <React.Fragment key={item.id}>
                    <tr>
                      <td className="border px-4 py-2">{item.id || "N/A"}</td>
                      <td className="border px-4 py-2">
                        {item.ProjectName || "N/A"}
                      </td>
                      <td className="border px-4 py-2">
                        {item.FunderName || "N/A"}
                      </td>
                      <td className="border px-4 py-2">
                        {item[selectedMonthYearEmployee]}%
                      </td>
                      <td className="border px-4 py-2">
                        <button
                          className="text-blue-500 hover:text-blue-700"
                          onClick={() => editPercentage(item.id)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                    {item ===
                      reducedTaskAllocation[
                        reducedTaskAllocation.length - 1
                      ] && (
                      <tr>
                        <td className="px-4 py-2"></td>
                        <td className="px-4 py-2"></td>
                        <td className="px-4 py-2"></td>
                        <td className="px-4 py-2 ">
                          Total: {monthlyTotalOfReducedTaskAllocation}%
                        </td>
                        <td className="px-4 py-2"></td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          {/* <div style={{ width: "400px", height: "300px" }}>
            <h2 className="text-xl font-bold">
              Task Allocation Project wise for the for month{" "}
              {formatDate(selectedDate)} for employee {selectedEmployee}:
            </h2>
            <Bar
              data={chartData}
              options={{
                scales: {
                  x: {
                    type: "category", // Use the "category" scale explicitly
                    beginAtZero: true,
                  },
                  y: {
                    beginAtZero: true,
                    min: 0, // Set the minimum value of the y-axis to 0
                    max: 100, // Set the maximum value of the y-axis to 100
                  },
                },
              }}
            />
          </div> */}

          <div className="flex-1 md-1/2 ">
            {/* <h2 className="text-xl font-bold">
              {" "}
              {`Percentage Allocated for month ${formatDate(
                selectedDate
              )} for employee ${selectedEmployee}`}
            </h2> */}
            <div
              className="flex justify-center items-center mt-20"
              // style={{ width: "400px", height: "300px" }}
            >
              <HighchartsReact highcharts={Highcharts} options={pieChartData} />
            </div>
          </div>
        </div>
      )}

      {isEditing && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg">
            <h3 className="text-xl font-bold mb-4">Change Percentage</h3>
            <div className="f">
              <input
                // value={`${detailsOfRowWhichIsEditing}.${formatDate(selectedDate)}_${selectedEmployee}`}
                value={
                  detailsOfRowWhichIsEditing
                    ? detailsOfRowWhichIsEditing[
                        `${formatDate(selectedDate)}_${selectedEmployee}`
                      ]
                    : ""
                }
                className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                onChange={(e) => {
                  // setEditedPercentage(e.target.value);
                  const value = e.target.value;
                  const propertyKey = `${formatDate(
                    selectedDate
                  )}_${selectedEmployee}`;

                  setDetailsOfRowWhichIsEditing((prevDetails) => ({
                    ...prevDetails,
                    [propertyKey]: value,
                  }));
                }}
              />

              <br />
              <button className="text-red-500 mr-2" onClick={handleClose}>
                Close
              </button>
              <button
                className="text-blue-500 hover:text-blue-700"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeWiseOverview;
