import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { addDoc, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import { BsPencil, BsTrash } from "react-icons/bs";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { db } from "../firebase";
import {
  getStorage,
  ref as storageReff,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Spinner } from "./Spinner";

const UploadReportingAttachmentsHalfYearly = () => {
  const auth = getAuth();

  const [projectData, setProjectData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedReportingTimelineHalfYearly, setSelectedReportingTimelineHalfYearly] =
    useState(" ");
  const financialAttachmentURLs = [];
  const NarrativeAttachmentURLs = [];

  const [financeDocuments, setFinanceDocuments] = useState([]);
  const [narrativeDocuments, setNarrativeDocuments] = useState([]);

  const projectId = selectedProject.ProjectId;
  const id = selectedProject.id;
  // console.log('----------------------', id);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "project_details"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProjectData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };
    fetchProjectData();
  }, []);

  const handleSearchInputChange = (event) => {
    const input = event.target.value.toLowerCase();
    // console.log("searchInput:", input);
    setSearchInput(input);
    setSelectedProject(""); // Clear the selected project when search input changes
    setSelectedReportingTimelineHalfYearly(" ");
  };

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setSearchInput("");
  };

  const filteredProjects = projectData.filter(
    (project) =>
      project.ProjectId.toLowerCase().includes(searchInput) ||
      project.ProjectName.toLowerCase().includes(searchInput)
  );

  // console.log("filteredProjects:", filteredProjects);
  // console.log("selectedProject:", selectedProject);

  const handleFileChange = (event, setDocuments) => {
    const files = event.target.files;
    const documentsArray = Array.from(files);
    setDocuments((prevDocuments) => [...documentsArray]);
  };

  const handleUpload = async () => {
    try {
      setLoading(true);

      const storage = getStorage();

      const uploadDocument = async (documents, folderName) => {
        const uploadPromises = documents.map(async (file) => {
          const fileId = uuidv4();
          const fileExtension = file.name.split(".").pop();
          const fileName = `${file.name}-${fileId}.${fileExtension}`;
          const storageRef = storageReff(
            storage,
            `${projectId}/${`halfYearly_${selectedReportingTimelineHalfYearly}`}/${folderName}/${fileName}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Calculate the percentage of the upload
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const message = `Uploading ${file.name}: ${progress.toFixed(
                  2
                )}%`;

                // console.log(message);

                // Handle progress or monitoring if needed
              },
              (error) => {
                console.error(`Error uploading ${folderName} document:`, error);
                reject(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref)
                  .then((downloadURL) => {
                    // Store the file name and URL in the corresponding variables
                    // namesArray.push(fileName);
                    if (folderName === "financeDocuments") {
                      financialAttachmentURLs.push(downloadURL);
                    } else if (folderName === "narrativeDocuments") {
                      NarrativeAttachmentURLs.push(downloadURL);
                    }

                    resolve();
                  })
                  .catch((error) => {
                    console.error("Error getting download URL:", error);
                    reject(error);
                  });
              }
            );
          });
        });

        await Promise.all(uploadPromises);
      };

      // Upload proposalAttachmentsDocuments
      await uploadDocument(financeDocuments, "financeDocuments");
      await uploadDocument(narrativeDocuments, "narrativeDocuments");

      // console.log(financialAttachmentURLs);
      // console.log(NarrativeAttachmentURLs);

      setFinanceDocuments([]);
      setNarrativeDocuments([]);

      toast.success("Documents uploaded successfully!");
    } catch (error) {
      console.error("Error uploading documents:", error);
      toast.error("Error uploading documents");
    } finally {
      setLoading(false);
    }
  };

  const updateDocument = async () => {
    try {
      await handleUpload();

      const copiedData = {
        ...selectedProject,
        [`halfYearly_${selectedReportingTimelineHalfYearly}`]: {
          financialURLs: [
            ...(selectedProject[`halfYearly_${selectedReportingTimelineHalfYearly}`]?.financialURLs ||
              []),
            ...financialAttachmentURLs,
          ],
          narrativeURLs: [
            ...(selectedProject[`halfYearly_${selectedReportingTimelineHalfYearly}`]?.narrativeURLs ||
              []),
            ...NarrativeAttachmentURLs,
          ],
        },
      };

      if (id) {
        const docRef = doc(db, "project_details", id);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          await updateDoc(docRef, copiedData);
          // console.log("Document updated successfully");
          // console.log("Updated data:", copiedData);
          setSelectedProject(copiedData);

          const activity = {
            action: "upload",
            nameOfLoggedInUser: auth.currentUser.displayName,
            dateAndTime: serverTimestamp(),
            forWhat: "UploadReportingAttachment",
            ID: copiedData.ProjectId,
            Name: copiedData.ProjectName,
          };

          await addDoc(collection(db, "activity"), activity);

          setSelectedReportingTimelineHalfYearly(" ");
        } else {
          // console.log("Project not found");
        }
      } else {
        // console.log("Invalid project ID");
      }
    } catch (error) {
      console.error("Failed to update document:", error);
    }
  };

  const [ReportingTimelineHalfYearlyKeys, setReportingTimelineHalfYearlyKeys] = useState([]);



  const getReportingTimelineHalfYearlyKeys = () => {
    const keys = Object.keys(selectedProject)
      .filter((key) => key.startsWith("ReportingTimelineHalfYearly"))
      .filter((key) => Object.keys(selectedProject[key]).length > 0);

    setReportingTimelineHalfYearlyKeys(keys);
  };

  // console.log(ReportingTimelineHalfYearlyKeys);

  useEffect(() => {
    getReportingTimelineHalfYearlyKeys();
  }, [selectedProject]);

  async function handleDeleteReportingTimelineHalfYearly(ReportingTimelineHalfYearlyKey) {
    try {
      const docRef = doc(db, "project_details", id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        // console.log(data[ReportingTimelineHalfYearlyKey]);
        delete data[ReportingTimelineHalfYearlyKey].financialURLs;
        delete data[ReportingTimelineHalfYearlyKey].narrativeURLs;

        // console.log("Before deletion:", data);
        // delete data[ReportingTimelineHalfYearlyKey];
        // console.log("After deletion:", data);

        await updateDoc(docRef, data);

        const activity = {
          action: "delete",
          nameOfLoggedInUser: auth.currentUser.displayName,
          dateAndTime: serverTimestamp(),
          forWhat: "UploadReportingAttachment",
          ID: data.ProjectId,
          Name: data.ProjectName,
        };

        await addDoc(collection(db, "activity"), activity);

        setSelectedProject(data);
        ReportingTimelineHalfYearlyKeys.push(ReportingTimelineHalfYearlyKey);
        // console.log("Reporting timeline data deleted successfully");
        // console.log("Updated data:", data);

        const storage = getStorage();
        const subfolders = [`${ReportingTimelineHalfYearlyKey}`];

        for (const subfolder of subfolders) {
          const storageRef = storageReff(storage, `${projectId}/${subfolder}`);
          await deleteFolder(storageRef);
          // console.log(`Deleted ${subfolder} folder and its contents`);
        }

        // toast.success("Project deleted successfully");
        // console.log("Project deleted successfully:");
      } else {
        // console.log("Project not found");
      }

      toast.success("Reporting timeline deleted successfully");
      // console.log("Reporting timeline deleted successfully");
    } catch (error) {
      console.error("Failed to delete reporting timeline:", error);
    }
  }

  async function deleteFolder(storageRef) {
    const listResult = await listAll(storageRef);
    const fileDeletions = listResult.items.map((item) => deleteObject(item));
    await Promise.all(fileDeletions);
    const subfolderDeletions = listResult.prefixes.map((prefix) =>
      deleteFolder(prefix)
    );
    await Promise.all(subfolderDeletions);
  }

  async function handleDeleteURL(ReportingTimelineHalfYearlyKey, other) {
    try {
      setLoading(true); // Set loading to true

      const docRef = doc(db, "project_details", id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();

        // console.log('Data:', data);
        // console.log('ReportingTimelineHalfYearlyKey:', ReportingTimelineHalfYearlyKey);
        // console.log('other:', other);

        const confirmation = await Swal.fire({
          title: "Are you sure?",
          text: `Are you sure you want to delete the ${ReportingTimelineHalfYearlyKey} ${other}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "Cancel",
        });

        if (confirmation.isConfirmed) {
          const fileDeletionConfirmation = await Swal.fire({
            title: "Are you sure?",
            text: "Deleted files cannot be retrieved. Are you sure you want to proceed?",
            icon: "error",
            showCancelButton: true,
            confirmButtonText: "Yes, delete them!",
            cancelButtonText: "Cancel",
          });

          if (fileDeletionConfirmation.isConfirmed) {
            delete data[ReportingTimelineHalfYearlyKey][other];
            // console.log('Updated Data:', data);

            const activity = {
              action: "delete",
              nameOfLoggedInUser: auth.currentUser.displayName,
              dateAndTime: serverTimestamp(),
              forWhat: "UploadReportingAttachment",
              ID: data.ProjectId,
              Name: data.ProjectName,
            };

            await addDoc(collection(db, "activity"), activity);

            // console.log(data)

            await updateDoc(docRef, data);
            setSelectedProject(data);
            ReportingTimelineHalfYearlyKeys.push(ReportingTimelineHalfYearlyKey);
            // console.log('Reporting timeline data deleted successfully');

            const storage = getStorage();
            let convertedOther;
            if (other === "financialURLs") {
              convertedOther = "financeDocuments";
            } else if (other === "narrativeURLs") {
              convertedOther = "narrativeDocuments";
            }
            const storageRef = storageReff(
              storage,
              `${projectId}/${ReportingTimelineHalfYearlyKey}/${convertedOther}`
            );
            // console.log('Storage Reference:', storageRef);

            const listResult = await listAll(storageRef);
            // console.log('List Result:', listResult);

            const fileDeletions = listResult.items.map((item) => {
              // console.log('Deleting file:', item.name);
              return deleteObject(item);
            });

            await Promise.all(fileDeletions);

            // console.log(`Deleted files within ${ReportingTimelineHalfYearlyKey}/${convertedOther} folder`);

            toast.success("URL deleted successfully");
            // console.log('URL deleted successfully');
          } else {
            // console.log('Deletion of files canceled');
          }
        } else {
          // console.log('Deletion canceled');
        }
      } else {
        // console.log('Project not found');
      }
    } catch (error) {
      console.error("Failed to delete URL:", error);
    } finally {
      setLoading(false); // Set loading back to false after deletion
    }
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="mt-5 max-w-6xl mx-auto">
      <input
        type="text"
        className="w-96"
        value={searchInput}
        onChange={handleSearchInputChange}
        placeholder="Search project by Project Name or Project ID"
      />
      <br></br>
      <br></br>
      <hr></hr>

      {selectedProject ? (
        <div>
          <div key={selectedProject.id} className="flex mt-4 justify-between">
            <div>
              <h3>Project Name is : {selectedProject.ProjectName}</h3>
              <p>Project Incharge is: {selectedProject.ProjectManager}</p>
              <p>Project ID is: {selectedProject.ProjectId}</p>
              <p>Total is: {selectedProject.Total}</p>

              {Object.entries(selectedProject)
                .filter(
                  ([key, value]) =>
                    key.startsWith("halfYearly") &&
                    (value.financialURLs || value.narrativeURLs)
                )
                .sort()
                .map(([key, value]) => {
                  const financialURLs = value.financialURLs || [];
                  const narrativeURLs = value.narrativeURLs || [];
                  return (
                    <React.Fragment key={key}>
                      <div className="flex items-center my-1">
                        <p>
                          {key}-financialURLs :{" "}
                          {financialURLs.length > 0 ? (
                            financialURLs.map((url, index) => (
                              <React.Fragment key={`financialURL-${index}`}>
                                <a
                                  href={url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title={`${key}-financialURL${index + 1}`}
                                  className="mr-2"
                                >
                                  {/* {index + 1}{" "} */}
                                  <AiOutlineCloudDownload
                                    style={{ display: "inline" }}
                                    className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-500"
                                  />
                                </a>
                              </React.Fragment>
                            ))
                          ) : (
                            <span>No URLs</span>
                          )}
                          {financialURLs.length > 0 && (
                            <button
                              onClick={() =>
                                handleDeleteURL(key, "financialURLs")
                              }
                              className="ml-2"
                            >
                              <BsTrash className="text-2xl border rounded-full bg-white ml-1 hover:bg-red-500" />
                            </button>
                          )}
                        </p>
                      </div>
                      <div className="flex items-center my-1">
                        <p>
                          {key}-narrativeURLs :{" "}
                          {narrativeURLs.length > 0 ? (
                            narrativeURLs.map((url, index) => (
                              <React.Fragment key={`narrativeURL-${index}`}>
                                <a
                                  href={url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title={`${key}-narrativeURL${index + 1}`}
                                  className="mr-2"
                                >
                                  {/* {index + 1}{" "} */}
                                  <AiOutlineCloudDownload
                                    style={{ display: "inline" }}
                                    className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-500"
                                  />
                                </a>
                              </React.Fragment>
                            ))
                          ) : (
                            <span>No URLs</span>
                          )}
                          {narrativeURLs.length > 0 && (
                            <button
                              onClick={() =>
                                handleDeleteURL(key, "narrativeURLs")
                              }
                              className="ml-2"
                            >
                              <BsTrash className="text-2xl border rounded-full bg-white ml-1 hover:bg-red-500" />
                            </button>
                          )}
                        </p>
                      </div>
                      <button
                        onClick={() => handleDeleteReportingTimelineHalfYearly(key)}
                        className="ml-2"
                      >
                        <BsTrash className="text-3xl border rounded-full  p-1 bg-red-200 hover:bg-red-500" />
                      </button>
                      <hr />
                    </React.Fragment>
                  );
                })}

            </div>

            <select
              value={selectedReportingTimelineHalfYearly}
              onChange={(event) =>
                setSelectedReportingTimelineHalfYearly(event.target.value)
              }
              className="h-10"
            >
              {
                // !selectedReportingTimelineHalfYearly &&
                <option value=" ">--choose one timeline--</option>
              }
              {/* <option value="aj"></option> */}

              {Object.keys(selectedProject.ReportingTimelineHalfYearly)
                .sort()
                .map((key) => (
                  <option
                    key={key}
                    value={key}
                    // disabled={ReportingTimelineHalfYearlyKeys.includes(key)}
                  >
                    {key} - {selectedProject.ReportingTimelineHalfYearly[key]}
                  </option>
                ))}
            </select>
          </div>
          {/* <hr /> */}

          <div>
            <div>
              {selectedReportingTimelineHalfYearly.length > 1 && (
                <div className="mt-2">
                  <p>
                    Selected Reporting Timeline : {selectedReportingTimelineHalfYearly}
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* {selectedReportingTimelineHalfYearly.length} */}

          {selectedReportingTimelineHalfYearly.length > 1 && (
            <>
              <div className="flex flex-wrap mb-4">
                <div className="w-1/2 pr-2 mb-4">
                  <p className="mb-2">Financial Attachments</p>
                  <input
                    className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                    type="file"
                    multiple
                    onChange={(e) => handleFileChange(e, setFinanceDocuments)}
                  />
                  {/* Render the uploaded proposal attachments files */}
                  {financeDocuments.map((file) => (
                    <div key={file.name}>{file.name}</div>
                  ))}
                </div>

                <div className="w-1/2 pl-2 mb-4">
                  <h2 className="mb-2">Narratives</h2>
                  <input
                    className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                    type="file"
                    multiple
                    onChange={(e) => handleFileChange(e, setNarrativeDocuments)}
                  />
                  {/* Render the uploaded concept notes files */}
                  {narrativeDocuments.map((file) => (
                    <div key={file.name}>{file.name}</div>
                  ))}
                </div>
              </div>

              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={updateDocument}
              >
                Upload Documents
              </button>
            </>
          )}
        </div>
      ) : (
        searchInput.length > 0 &&
        filteredProjects.map((project) => (
          <div
            key={project.id}
            className="w-full mr-5"
            onClick={() => handleProjectSelect(project)}
            style={{
              cursor: "pointer",
              border: "1px solid #ccc",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <h3>{project.ProjectName}</h3>
            <p>Project Manager: {project.ProjectManager}</p>
            <p>Project ID: {project.ProjectId}</p>
            <p>Total: {project.Total}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default UploadReportingAttachmentsHalfYearly;