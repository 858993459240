import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword";
import Header from "./components/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddEmployee from "./pages/AddEmployee";
import AllEmployee from "./pages/AllEmployee";
import AddProject from "./pages/AddProjects";
import Testing from "./pages/Testing";
import AllProjects from "./pages/AllProjects";
import TaskAllocate from "./pages/TaskAllocate";
import TestComponent from "./components/TestComponent";
import UploadReportingAttachments from "./components/UploadReportingAttachments";
// import ProjectAllocationOverview from "./pages/TaskAllocationOverview";
import TaskAllocationOverview from "./pages/TaskAllocationOverview";
import EmployeeWiseOverview from "./pages/EmployeeWiseOverview";
import ProjectWiseOverview from "./pages/ProjectWiseOverview";
import { getAuth } from "firebase/auth";
import OrganisationTree from "./components/OrganisationTree";
import { useState, useEffect } from "react";
import UploadExcel from "./components/UploadExcel";
import DownloadEmployee from "./components/DownloadEmployee";
import DownloadProjects from "./components/DownloadProjects";
import UploadNCEAttachments from "./pages/UploadNCEAttachments";
import Collaboration from "./pages/Collaboration";
import AllCollaboration from "./pages/AllCollaboratio";
import AllEmployeeBeforeSubmit from "./pages/AllEmployeeBeforeSubmit";
import AllProjectsBeforeSubmit from "./pages/AllProjectsBeforeSubmit";
import ProjectWiseOverviewBeforeSubmit from "./pages/ProjectWiseOverviewBeforeSubmit";
import MonthWiseOverview from "./pages/MonthWiseOverview";
import OrgChart from "./components/OrgChart";
import UploadReportingAttachmentsHalfYearly from "./components/UploadReportingAttachmentHalfYearly";
import UploadReportingAttachmentsYearly from "./components/UploadReportingAttachmentsYearly";
import UploadReportingAttachmentt from "./pages/UploadReportingAttachmentt";
import RevesionInBudget from "./pages/RevesionInBudget";

function App() {
  const auth = getAuth();
  // const email = auth.currentUser?.email;
  const [email, setEmail] = useState(null);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now()); // Initialize with the current time


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setEmail(user.email);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleActivity = () => {
      setLastActivityTime(Date.now());
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Clean up the event listeners
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, []);


  useEffect(() => {
    const logoutInactiveUser = () => {
      const currentTime = Date.now();
      const inactiveTime = currentTime - lastActivityTime;

      if (inactiveTime >= 15*60* 1000 && auth) {
        // 5 minutes of inactivity
        auth.signOut(); // Trigger logout
        // window.location.reload();
        window.location.href ='./sign-in'
      }
    };

    const interval = setInterval(logoutInactiveUser, 10000); // Check every 10 seconds

    return () => clearInterval(interval);
  }, [lastActivityTime]);


  return (
    <>
      <Router>
        <Header />

        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
          </Route>

          <Route path="/profile" element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>

          <Route path="/sign-in" element={<SignIn />} />

          <Route path="/testing" element={<Testing />} />

          <Route path="/test-component" element={<TestComponent />} />

          {/* <Route path="/sign-up" element={<SignUp />} /> */}

          <Route path="/sign-up" element={<PrivateRoute />}>
            {email === "ajay@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route path="/sign-up" element={<SignUp />} />
            ) : (
              <Route
                path="/sign-up"
                element={
                  <h1 className="my-auto text-lg  text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>

          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* <Route path="/all-employee" element={<AllEmployee />} /> */}

          <Route path="/all-employee" element={<PrivateRoute />}>
            {email == "amar@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org" ||
            email == "preethi@vasudhaindia.org" ||
            email == "gunjan@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route path="/all-employee" element={<AllEmployee />} />
            ) : (
              <Route
                path="/all-employee"
                element={
                  <h1 className="my-auto text-lg text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>



          



          {/* <Route path="/create-employee" element={<PrivateRoute />}>
            <Route path="/create-employee" element={<AddEmployee />} />
          </Route> */}

          <Route path="/create-employee" element={<PrivateRoute />}>
            {email === "srinivas@vasudhaindia.org" ||
            email === "gunjan@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route path="/create-employee" element={<AddEmployee />} />
            ) : (
              <Route
                path="/create-employee"
                element={
                  <h1 className="my-auto text-lg text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>

          {/* <Route path="/create-Project" element={<PrivateRoute />}>
            <Route path="/create-Project" element={<AddProject />} />
          </Route> */}

          <Route path="/create-Project" element={<PrivateRoute />}>
            {email == "amar@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org" ||
            email == "preethi@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route path="/create-Project" element={<AddProject />} />
            ) : (
              <Route
                path="/create-Project"
                element={
                  <h1 className="my-auto text-lg text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>

          {/* <Rfoute path="/all-Projects" element={<PrivateRoute />}>
            <Route path="/all-Projects" element={<AllProjects />} />
          </Rfoute> */}

          <Route path="/all-Projects" element={<PrivateRoute />}>
            {email == "amar@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org" ||
            email == "preethi@vasudhaindia.org" ||
            email == "raghav@vasudhaindia.org" ||
            email == "jaideep@vasudhaindia.org" ||
            email == "vrinda@vasudhaindia.org" ||
            email == "rini@vasudhaindia.org" ||
            email == "akinchan@vasudhaindia.org" ||
            email == "priya@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route path="/all-Projects" element={<AllProjects />} />
            ) : (
              <Route
                path="/all-Projects"
                element={
                  <h1 className="my-auto text-lg text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>

          {/* <Route path="/upload-excel" element={<PrivateRoute />}>
            <Route path="/upload-excel" element={<UploadExcel />} />
          </Route> */}

          <Route path="/upload-excel" element={<PrivateRoute />}>
            {email === "ajay@vasudhaindia.org" ||
            email === "srinivas@vasudhaindia.org"||
            email === "gunjan@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route path="/upload-excel" element={<UploadExcel />} />
            ) : (
              <Route
                path="/upload-excel"
                element={
                  <h1 className="my-auto text-lg text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>

          {/* <Route path="/download-employee-excel" element={<PrivateRoute />}>
            <Route
              path="/download-employee-excel"
              element={<DownloadEmployee />}
            />
          </Route>

          <Route path="/download-project-excel" element={<PrivateRoute />}>
            <Route
              path="/download-project-excel"
              element={<DownloadProjects />}
            />
          </Route> */}

          <Route path="/task-allocate" element={<PrivateRoute />}>
            {email == "amar@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org" ||
            email == "preethi@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route path="/task-allocate" element={<TaskAllocate />} />
            ) : (
              <Route
                path="/task-allocate"
                element={
                  <h1 className="my-auto text-lg  text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>

          {/* <Route
            path="/Upload-Reporting-Timeline-attachments"
            element={<PrivateRoute />}
          >
            <Route
              path="/Upload-Reporting-Timeline-attachments"
              element={<UploadReportingAttachments />}
            />
          </Route> */}

          <Route
            path="/Upload-Reporting-Timeline-attachments"
            element={<PrivateRoute />}
          >
            {email == "amar@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org" ||
            email == "preethi@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route
                path="/Upload-Reporting-Timeline-attachments"
                element={<UploadReportingAttachmentt/>}
              />
            ) : (
              <Route
                path="/Upload-Reporting-Timeline-attachments"
                element={
                  <h1 className="my-auto text-lg  text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>

          {/* <Route
            path="/task-allocation-overview"
            element={<TaskAllocationOverview />}
          /> */}

          {/* <Route
            path="/employee-wise-overview"
            element={<EmployeeWiseOverview />}
          /> */}

          <Route path="/employee-wise-overview" element={<PrivateRoute />}>
            {email == "amar@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org" ||
            email == "preethi@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route
                path="/employee-wise-overview"
                element={<EmployeeWiseOverview />}
              />
            ) : (
              <Route
                path="/employee-wise-overview"
                element={
                  <h1 className="my-auto text-lg  text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>

          {/* <Route
            path="/project-wise-overview"
            element={<ProjectWiseOverview />}
          /> */}

          <Route path="/project-wise-overview" element={<PrivateRoute />}>
            {email == "amar@vasudhaindia.org" ||
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org" ||
            email == "preethi@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route
                path="/project-wise-overview"
                element={<ProjectWiseOverview />}
              />
            ) : (
              <Route
                path="/project-wise-overview"
                element={
                  <h1 className="my-auto text-lg  text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>


          <Route path="/all-employee-before-submit" element={<PrivateRoute />}>
            {
            email == "srinivas@vasudhaindia.org" ||
            email == "gunjan@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route path="/all-employee-before-submit" element={<AllEmployeeBeforeSubmit/> } />
            ) : (
              <Route
                path="/all-employee-before-submit"
                element={
                  <h1 className="my-auto text-lg text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>



          <Route path="/all-projects-before-submit" element={<PrivateRoute />}>
            {
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "it@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org"||
            email == "amar@vasudhaindia.org"||
            email == "preethi@vasudhaindia.org"? (
              <Route path="/all-projects-before-submit" element={<AllProjectsBeforeSubmit/> } />
            ) : (
              <Route
                path="/all-projects-before-submit"
                element={
                  <h1 className="my-auto text-lg text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>











          <Route path="/revesion-in-budget" element={<PrivateRoute />}>
            {
            email == "srinivas@vasudhaindia.org" ||
            email == "rachit@vasudhaindia.org" ||
            email == "it@vasudhaindia.org" ||
            email == "mohit@vasudhaindia.org"||
            email == "amar@vasudhaindia.org"||
            email == "preethi@vasudhaindia.org"? (
              <Route path="/revesion-in-budget" element={<RevesionInBudget/> } />
            ) : (
              <Route
                path="/revesion-in-budget"
                element={
                  <h1 className="my-auto text-lg text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>














          <Route path="/project-wise-overview-before-submit" element={<PrivateRoute />}>
            {
            email == "srinivas@vasudhaindia.org" ||
            email == "gunjan@vasudhaindia.org" ||
            email == "it@vasudhaindia.org"? (
              <Route path="/project-wise-overview-before-submit" element={<ProjectWiseOverviewBeforeSubmit/>} />
            ) : (
              <Route
                path="/project-wise-overview-before-submit"
                element={
                  <h1 className="my-auto text-lg text-center">
                    You are not allowed to access this page
                  </h1>
                }
              />
            )}
          </Route>
          

          {/* <Route path="/tree" element={<OrganisationTree />} /> */}
          <Route path="/tree" element={<OrgChart/>} />
          <Route path="/Upload-NCE-Attachments" element={<UploadNCEAttachments/>} />
          <Route path="/Collaboration" element={<Collaboration/>} />
          <Route path="/All-Collaboration" element={<AllCollaboration/>} />
          <Route path="/month-wise-overview" element={<MonthWiseOverview/>} />
          <Route path="/UploadReportingAttachmentsHalfYearly" element={<UploadReportingAttachmentsHalfYearly/>} />
          <Route path="/UploadReportingAttachmentsYearly" element={<UploadReportingAttachmentsYearly/>} />
         
          <Route path="/UploadReportingAttachments" element={<UploadReportingAttachments/>} />
         
        
         
          {/* 404 route */}
       
       
       
          <Route
            path="*"
            element={
              <h1 className="my-auto text-lg  text-center">
                No such page is there
              </h1>
            }
          />
        </Routes>
      </Router>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
