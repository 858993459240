import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { Spinner } from "../components/Spinner";

const MonthWiseOverview = () => {
  const [loading, setLoading] = useState(false);
  const [taskAllocationDetails, setTaskAllocationDetails] = useState([]);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const initialSelectedDate = `${currentYear}-${currentMonth}`;
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [allotedNames, setAllotedNames] = useState("");

  const fetchTaskAllocation = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "taskAllocation"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Filter and keep only the id and selectedDate keys
      const filteredData = data.map((item) => {
        const filteredItem = {
          id: item.id,
        };
        let count = 0; // Initialize a count variable to keep track of filtered keys
        for (const key in item) {
          if (key.startsWith(formatDateToMMMYY(selectedDate))) {
            filteredItem[key] = item[key];
            count++; // Increment the count for each matching key
          }
        }
        filteredItem.count = count; // Store the count in the filtered item
        return filteredItem;
      });

      // Check the length of objects inside the array
      const filteredDataWithCount = filteredData.filter(
        (item) => item.count > 1
      );

      if (filteredDataWithCount.length > 0) {
        setTaskAllocationDetails(filteredDataWithCount);
        setLoading(false);
      } else {
        // Handle the case when there are no objects with a count greater than 1
        console.warn("No objects with a count greater than 1:", filteredData);
        // You can set some default value or handle this situation as needed
      }
    } catch (error) {
      console.error("Failed to fetch employee data:", error);
    }
  };

  useEffect(() => {
    // Define a Set to store unique names
    const uniqueNamesSet = new Set();

    // Iterate through taskAllocationDetails to extract unique names
    taskAllocationDetails.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key.startsWith(formatDateToMMMYY(selectedDate) + "_")) {
          const name = key.split("_")[1]; // Extract the name after the prefix
          uniqueNamesSet.add(name); // Add the name to the Set to ensure uniqueness
        }
      });
    });

    // Convert the Set to an array of unique names
    const uniqueNamesArray = [...uniqueNamesSet];

    // Convert the array to a comma-separated string
    const allotedNamesString = uniqueNamesArray.join(", ");

    // Set the allotedNames state variable with the string
    setAllotedNames(allotedNamesString);
  }, [taskAllocationDetails, selectedDate]);

  useEffect(() => {
    console.log(allotedNames);
  }, [allotedNames, taskAllocationDetails]);

  useEffect(() => {
    fetchTaskAllocation();
  }, [selectedDate]);

  useEffect(() => {
    console.log(taskAllocationDetails);
  }, [taskAllocationDetails]);

  function formatDateToMMMYY(dateString) {
    const dateParts = dateString.split("-");
    if (dateParts.length === 2) {
      const year = dateParts[0].substring(2); // Get the last two digits of the year
      const month = dateParts[1];

      const monthNames = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];

      if (month >= 1 && month <= 12) {
        const formattedMonth = monthNames[parseInt(month, 10) - 1];
        return `${formattedMonth}-${year}`;
      }
    }
    return dateString; // Return the input string if it's not in the expected format
  }

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="max-w-6xl mx-auto">
      <h1 className="text-3xl text-center mt-6 m-6 font-bold">
        Month wise Engagement
      </h1>
      <div className="flex gap-4 mt-6 mx-6">
        <div className="w-full sm:w-1/2 md:w-1/3">
          <label
            htmlFor="customDate"
            className="block text-sm font-medium text-gray-700"
          >
            Select Month and Year : {formatDateToMMMYY(selectedDate)}
          </label>
          <input
            type="month"
            id="customDate"
            value={selectedDate}
            onChange={handleDateChange}
            placeholder=""
            min="2010-01"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
        <br></br>
      </div>

      <div className="overflow-x-scroll">
        <table className="table-auto  ml-5 ">
          <thead>
            <tr>
              <th className="px-4 py-2">ID</th>
              {allotedNames.split(", ").map((name) => (
                <th key={name} className="px-4 py-2">
                  {name?.toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {taskAllocationDetails.map((item, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{item.id}</td>
                {allotedNames.split(", ").map((name) => (
                  <td key={name} className="border px-4 py-2">
                    {item[`${formatDateToMMMYY(selectedDate)}_${name}`] || "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="border px-4 py-2">Total</td>
              {allotedNames.split(", ").map((name) => {
                const total = taskAllocationDetails.reduce((total, item) => {
                  const value =
                    item[`${formatDateToMMMYY(selectedDate)}_${name}`];
                  return total + (value ? parseFloat(value) : 0);
                }, 0);
                const isRed = total >= 100; // Check if the total is greater than 100
                return (
                  <td
                    key={name}
                    className={`border px-4 py-2 ${isRed ? "bg-red-500" : ""}`}
                  >
                    {total || "-"}
                  </td>
                );
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default MonthWiseOverview;
