import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageReff,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

import { addDoc, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import { BsPencil, BsTrash } from "react-icons/bs";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { db } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Spinner } from "../components/Spinner";

const RevesionInBudget = () => {
  const auth = getAuth();

  const [projectData, setProjectData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [loading, setLoading] = useState(true);

  const [currency, setCurrency] = useState("");
  const [budget, setBudget] = useState([{ name: "", value: "0" }]);
  const [revisionInBudgetDocuments, setRevisionInBudgetDocuments] = useState(
    []
  );

  const revisionInBudgetDocumentsNames = [];
  const revisionInBudgetDocumentsUrls = [];

  // const { ProjectId } = selectedProject.ProjectId || "";

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "project_details"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProjectData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };
    fetchProjectData();
  }, []);

  const handleSearchInputChange = (event) => {
    const input = event.target.value?.toLowerCase();
    setSearchInput(input);
    setSelectedProject("");
  };

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setSearchInput("");
  };
  console.log(selectedProject);

  const filteredProjects = projectData.filter(
    (project) =>
      project.ProjectId?.toLowerCase().includes(searchInput) ||
      project.ProjectName?.toLowerCase().includes(searchInput)
  );

  async function onSubmit(e) {
    e.preventDefault();

    await handleUpload();

    setLoading(true);

    const formDataCopy = {
      revisionInBudgetDocumentsNames: revisionInBudgetDocumentsNames,
      revisionInBudgetDocumentsUrls: revisionInBudgetDocumentsUrls,
      RevisedBudget: budget,
      RevisedCurrency: currency,
      RevisedTotal: calculateTotal(),
    };

    try {
      const projectDocRef = doc(db, "project_details", selectedProject.id);

      // Use updateDoc instead of addDoc
      await updateDoc(projectDocRef, formDataCopy);

      // Add activity details if needed
      const activity = {
        action: "update", // or "edit" based on your preference
        nameOfLoggedInUser: auth.currentUser.displayName,
        dateAndTime: serverTimestamp(),
        forWhat: "Project",
        ID: selectedProject.ProjectId,
        Name: selectedProject.ProjectName,
      };

      await addDoc(collection(db, "activity"), activity);

      setLoading(false);
      toast.success("Project updated successfully");

      // Reset other state variables as needed
      setBudget([{ name: "", value: "0" }]);
      setCurrency("");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update Project data in the database");
    }

    // ...
  }

  const changeBudgetName = (index, newName) => {
    const updatedBudget = [...budget];
    updatedBudget[index] = { ...updatedBudget[index], name: newName };
    setBudget(updatedBudget);
  };

  const changeBudgetValue = (index, newValue) => {
    const updatedBudget = [...budget];
    updatedBudget[index] = { ...updatedBudget[index], value: newValue };
    setBudget(updatedBudget);
  };

  const addBudgetField = (e) => {
    e.preventDefault();
    setBudget([...budget, { name: "", value: "0" }]);
  };

  const deleteBudgetField = (index) => {
    const updatedBudget = [...budget];
    updatedBudget.splice(index, 1);
    setBudget(updatedBudget);
  };

  const calculateTotal = () => {
    let total = 0;
    for (let i = 0; i < budget.length; i++) {
      total += parseFloat(budget[i].value);
    }
    return total;
  };

  const handleFileChange = (event, setDocuments) => {
    const files = event.target.files;
    const documentsArray = Array.from(files);
    setDocuments((prevDocuments) => [...prevDocuments, ...documentsArray]);
  };

  const handleUpload = async () => {
    try {
      setLoading(true);

      const storage = getStorage();

      const uploadDocument = async (documents, folderName, namesArray) => {
        const uploadPromises = documents.map(async (file) => {
          const fileId = uuidv4();
          const fileExtension = file.name.split(".").pop();
          const fileName = `${fileId}.${fileExtension}`;
          const storageRef = storageReff(
            storage,
            `${"Project ID 1"}/${folderName}/${fileName}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // Handle progress or monitoring if needed
              },
              (error) => {
                console.error(`Error uploading ${folderName} document:`, error);
                reject(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref)
                  .then((downloadURL) => {
                    namesArray.push(fileName);
                    if (folderName === "revisionInBudgetDocuments") {
                      revisionInBudgetDocumentsUrls.push(downloadURL);
                    }
                    resolve();
                  })
                  .catch((error) => {
                    console.error("Error getting download URL:", error);
                    reject(error);
                  });
              }
            );
          });
        });

        await Promise.all(uploadPromises);
      };

      await uploadDocument(
        revisionInBudgetDocuments,
        "revisionInBudgetDocuments",
        revisionInBudgetDocumentsNames
      );

      setRevisionInBudgetDocuments([]);

      toast.success("Documents uploaded successfully!");
    } catch (error) {
      console.error("Error uploading documents:", error);
      toast.error("Error uploading documents");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="mt-5 max-w-6xl mx-auto">
      <input
        type="text"
        className="w-96"
        value={searchInput}
        onChange={handleSearchInputChange}
        placeholder="Search project by Project Name or Project ID"
      />
      <br></br>
      <br></br>
      <hr></hr>

      {selectedProject ? (
        <div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y mx-auto divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Project Details
                    <br></br>
                    {/* {selectedProject.id} */}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Reporting Timeline
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Budget
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Project Incharge
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Attachments
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {
                  <tr key={""}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <p className="text-sm text-gray-900">
                        Project Name : {selectedProject.ProjectName}
                      </p>
                      <p className="text-sm text-gray-900">
                        Project Id : {selectedProject.ProjectId}
                      </p>
                      <p className="text-sm text-gray-900">
                        Proposal Id : {selectedProject.ProposalId}
                      </p>
                      <p className="text-sm text-gray-900">
                        Start Date : {selectedProject.StartDate}
                      </p>
                      <p className="text-sm text-gray-900">
                        End Date : {selectedProject.EndDate}
                      </p>
                      <p className="text-sm text-gray-900">
                        Project Status: {selectedProject.ProjectStatus}
                      </p>

                      <p className="text-sm text-gray-900">
                        Project Funder Name: {selectedProject.FunderName}
                      </p>

                      {selectedProject.no_cost_extension_dates &&
                        selectedProject.no_cost_extension_dates.length > 0 && (
                          <p className="text-sm text-gray-900">
                            Last No Cost Extension Date :{" "}
                            {
                              selectedProject.no_cost_extension_dates[
                                selectedProject.no_cost_extension_dates.length -
                                  1
                              ]
                            }
                          </p>
                        )}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <p>Monthly</p>
                      {selectedProject.ReportingTimeline &&
                        Object.keys(selectedProject.ReportingTimeline)
                          .sort()
                          .map((key, index) => (
                            <div key={key}>
                              <p className="text-sm text-gray-900">
                                Reporting Timeline {index + 1}:{" "}
                                {selectedProject.ReportingTimeline[key]}
                              </p>
                            </div>
                          ))}

                      <hr></hr>
                      <p>Half Yearly</p>
                      {selectedProject.ReportingTimelineHalfYearly &&
                        Object.keys(selectedProject.ReportingTimelineHalfYearly)
                          .sort()
                          .map((key, index) => (
                            <div key={key}>
                              <p className="text-sm text-gray-900">
                                Reporting Timeline {index + 1}:{" "}
                                {
                                  selectedProject.ReportingTimelineHalfYearly[
                                    key
                                  ]
                                }
                              </p>
                            </div>
                          ))}
                      <hr></hr>
                      <p>Yearly</p>
                      {selectedProject.ReportingTimelineYearly &&
                        Object.keys(selectedProject.ReportingTimelineYearly)
                          .sort()
                          .map((key, index) => (
                            <div key={key}>
                              <p className="text-sm text-gray-900">
                                Reporting Timeline {index + 1}:{" "}
                                {selectedProject.ReportingTimelineYearly[key]}
                              </p>
                            </div>
                          ))}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      {/* <p className="text-sm text-gray-900">Professional Fees:</p> */}
                      {selectedProject.budget.map((item, index) => (
                        <div key={index}>
                          <p className="text-sm text-gray-900">{`${item.name}: ${item.value} ${selectedProject.currency}`}</p>
                        </div>
                      ))}

                      <p className="text-sm">
                        {" "}
                        Total: {selectedProject.Total}{" "}
                        {selectedProject.currency}
                      </p>

                      <hr></hr>

                      {selectedProject.RevisedBudget?.map((item, index) => (
                        <div key={index}>
                          <p className="text-sm text-gray-900">{`${item.name}: ${item.value} ${selectedProject?.RevisedCurrency}`}</p>
                        </div>
                      ))}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <p className="text-sm text-gray-900">
                        {selectedProject.ProjectManager}
                      </p>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <p
                        className="text-sm text-gray-900"
                        style={{ display: "inline" }}
                      >
                        Proposal attachments:
                        {selectedProject.proposalAttachmentsDocumentsUrls.map(
                          (url, index) => (
                            <a
                              key={index}
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <AiOutlineCloudDownload
                                style={{ display: "inline" }}
                                className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                              />
                            </a>
                          )
                        )}
                      </p>

                      <br />
                      <p
                        className="text-sm text-gray-900"
                        style={{ display: "inline" }}
                      >
                        Grant Agreement attachments:
                        {selectedProject.grantAgreementAttachmentsDocumentsUrls.map(
                          (url, index) => (
                            <a
                              key={index}
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <AiOutlineCloudDownload
                                style={{ display: "inline" }}
                                className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                              />
                            </a>
                          )
                        )}
                      </p>

                      <br />
                      <p
                        className="text-sm text-gray-900"
                        style={{ display: "inline" }}
                      >
                        Concept Notes attachments:
                        {selectedProject.conceptNotesAttachmentsDocumentsUrls.map(
                          (url, index) => (
                            <a
                              key={index}
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <AiOutlineCloudDownload
                                style={{ display: "inline" }}
                                className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                              />
                            </a>
                          )
                        )}
                      </p>

                      <br />
                      <p
                        className="text-sm text-gray-900"
                        style={{ display: "inline" }}
                      >
                        Budget Document attachments:
                        {selectedProject.budgetDocumentsUrls.map(
                          (url, index) => (
                            <a
                              key={index}
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <AiOutlineCloudDownload
                                style={{ display: "inline" }}
                                className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                              />
                            </a>
                          )
                        )}
                      </p>

                      <hr></hr>

                      {selectedProject.revisionInBudgetDocumentsUrls && (
                        <p
                          className="text-sm text-gray-900"
                          style={{ display: "inline" }}
                        >
                          Revied Budget Document attachments:
                          {selectedProject.revisionInBudgetDocumentsUrls?.map(
                            (url, index) => (
                              <a
                                key={index}
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <AiOutlineCloudDownload
                                  style={{ display: "inline" }}
                                  className="text-2xl border rounded-full bg-white ml-1 hover:bg-blue-200"
                                />
                              </a>
                            )
                          )}
                        </p>
                      )}
                    </td>
                  </tr>
                }
              </tbody>
            </table>

            
          </div>

          <form onSubmit={onSubmit}>
              <div className="flex flex-row space-x-3  w-full">
                <div className="mt-6 flex-1 ">
                  <p className="text-l font-semibold">Budget</p>
                  <select
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    className="px-4 w-full  py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6 mr-5"
                  >
                    <option value="">--Choose Currency--</option>
                    <option value="INR">INR</option>
                    <option value="USD">USD</option>
                    <option value="EURO">EURO</option>
                  </select>

                  {budget.map((item, index) => (
                    <div key={index} className="flex">
                      <div className="flex-1">
                        <input
                          type="text"
                          value={item.name}
                          onChange={(e) =>
                            changeBudgetName(index, e.target.value)
                          }
                          placeholder="Head"
                          className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                        />
                      </div>

                      <div className="ml-1 flex-1">
                        <input
                          type="number"
                          value={item.value}
                          onChange={(e) =>
                            changeBudgetValue(index, e.target.value)
                          }
                          placeholder="Value"
                          className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                        />
                      </div>

                      {budget.length > 1 && (
                        <button
                          type="button"
                          onClick={() => deleteBudgetField(index)}
                          className="rounded ml-1"
                          title="Delete this Budget"
                        >
                          <AiOutlineMinusCircle className="text-4xl mb-6 text-white border rounded-full bg-red-500 hover:bg-red-700" />
                        </button>
                      )}
                    </div>
                  ))}

                  <div className="flex w-full items-center mb-6 ">
                    <input
                      value={`Total: ${calculateTotal()} ${currency}`}
                      className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mr-2"
                    />
                    <button
                      onClick={addBudgetField}
                      title="Add more Budgets"
                      className="py-2"
                    >
                      <AiOutlinePlusCircle className="text-white hover:bg-blue-700 text-4xl border rounded-full bg-blue-500" />
                    </button>
                  </div>
                </div>

                <div className="flex-1 w-full ">{/* ..kjndkjhdi */}</div>
              </div>

              <div className="flex flex-wrap mb-4">
                <div className="w-1/2 pr-2 mb-4">
                  <p className="mb-2">Revision In Budget Documents</p>
                  <input
                    className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                    type="file"
                    multiple
                    onChange={(e) =>
                      handleFileChange(e, setRevisionInBudgetDocuments)
                    }
                  />
                  {revisionInBudgetDocuments.map((file) => (
                    <div key={file.name}>{file.name}</div>
                  ))}
                </div>
              </div>

              <div className="mx-auto max-w-md">
                <button
                  type="submit"
                  className=" mb-6 w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mx-auto"
                >
                  Save
                </button>
              </div>
            </form>
        </div>
      ) : (
        searchInput.length > 0 &&
        filteredProjects.map((project) => (
          <div
            key={project.id}
            className="w-full mr-5"
            onClick={() => handleProjectSelect(project)}
            style={{
              cursor: "pointer",
              border: "1px solid #ccc",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <h3>{project.ProjectName}</h3>
            <p>Project Manager: {project.ProjectManager}</p>
            <p>Project ID: {project.ProjectId}</p>
            <p>Total: {project.Total}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default RevesionInBudget;