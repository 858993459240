// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// ---------------------- Firabase config for real Project start --------------

const firebaseConfig = {
  apiKey: "AIzaSyAonhpX0GZoO-du2oaMHIqoW1e46LhR4uY",
  authDomain: "manpower-allocation.firebaseapp.com",
  projectId: "manpower-allocation",
  storageBucket: "manpower-allocation.appspot.com",
  messagingSenderId: "692845905217",
  appId: "1:692845905217:web:251ea4c196d58d8df1cdc0",
  measurementId: "G-WHGBM713HH"
};


// ------------------------ Firebase config for real project end ------------



// const firebaseConfig = {
//   apiKey: "AIzaSyDo2sRhOSxCY_S6xrGs2CV296UnL6dbj28",
//   authDomain: "manpower-applocation.firebaseapp.com",
//   databaseURL: "https://manpower-applocation-default-rtdb.firebaseio.com",
//   projectId: "manpower-applocation",
//   storageBucket: "manpower-applocation.appspot.com",
//   messagingSenderId: "415667511040",
//   appId: "1:415667511040:web:db0e59f2cedcc5e4e5dde5"
// };


// Initialize Firebase
initializeApp(firebaseConfig);

export const db = getFirestore();
// export const storage = getStorage(app);
