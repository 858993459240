import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";


export default function UploadReportingAttachmentt() {
  const auth = getAuth();

  const [email, setEmail] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setEmail(user?.email);
      }
    });

    return () => unsubscribe();
  }, []);

  const currentDate = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <div className="mt-5 w-[73%] mx-auto over ">
      <div className="flex justify-center mt-16">
        <div className="flex space-x-4 ">
          <div className="flex flex-col  p-3 max-w-sm flex-1.2">
            <div className="border transform duration-300 group-hover:shadow-2xl p-4 group   rounded-[7px]">
              <Link
                to="/UploadReportingAttachments"
                rel="noopener noreferrer"
                className={`cursor-pointer flex justify-around  items-center py-1 text-md font-semibold border-b-[3px] border-b-transparent`}
              >
                <p className=" text-2xl text-[#739b3f]">Quartely</p>
              </Link>
            </div>
          </div>

          <div className="flex flex-col  p-3 max-w-sm flex-1.2">
            <div className="border transform duration-300 group-hover:shadow-2xl p-4 group   rounded-[7px]">
              <Link
                to="/UploadReportingAttachmentsHalfYearly"
                rel="noopener noreferrer"
                className={`cursor-pointer flex justify-around  items-center py-1 text-md font-semibold border-b-[3px] border-b-transparent`}
              >
                <p className=" text-2xl text-[#739b3f]">Half Yearly</p>
              </Link>
            </div>
          </div>

          
          <div className="flex flex-col  p-3 max-w-sm flex-1.2">
            <div className="border transform duration-300 group-hover:shadow-2xl p-4 group   rounded-[7px]">
              <Link
                to="/UploadReportingAttachmentsYearly"
                rel="noopener noreferrer"
                className={`cursor-pointer flex justify-around  items-center py-1 text-md font-semibold border-b-[3px] border-b-transparent`}
              >
                <p className=" text-2xl text-[#739b3f]">Yearly</p>
              </Link>
            </div>
          </div>

        </div>
      </div>

      <br />
      <br />


      <br />
      <br />
    </div>
  );
}
