import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { addDoc, serverTimestamp } from "firebase/firestore";

import { db } from "../firebase";

import {
  getStorage,
  ref as storageReff,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";

import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

import { AiOutlineCloudDownload } from "react-icons/ai";
import { BsPencil, BsTrash } from "react-icons/bs";
import { Spinner } from "../components/Spinner";
import { toast } from "react-toastify";

import { getAuth } from "firebase/auth";

export default function TaskAllocate() {
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedProjectData, setSelectedProjectData] = useState({});
  const [rows, setRows] = useState([]);
  const [employeeSelected, setEmployeeSelected] = useState(false);
  const [alreadyAllocatedProjects, setAlreadyAllocatedProjects] = useState([]);
  const [isDisabled, setIsDisabled] = useState("false");

  const [addEmployeeButtonClicked, setAddEmployeeButtonClicked] =
    useState(false);
  const [rowCovered, setRowCovered] = useState([]);
  const [months, setMonths] = useState([]); // State array to store months
  const [
    percentageOfWorkOfEmployeeMonthWise,
    setPercentageOfWorkOfEmployeeMonthWise,
  ] = useState();

  //  to disable the buttons
  const employeeValues = rows.map((row) => row.employee);

  useEffect(() => {
    const fetchAllocatedProjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "taskAllocation"));
        const data = querySnapshot.docs.map((doc) => doc.id); // Extract the "id" property
        setAlreadyAllocatedProjects(data);
        // console.log(
        //   "-----------------------------alreadyAllocatedProjects-----"
        // );
        // console.log(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
      }
    };

    fetchAllocatedProjects();
  }, []);

  useEffect(() => {
    const fetchprojectData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "project_details"));
        const data = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setProjectData(data);
        // console.log(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
      }
    };

    fetchprojectData();
  }, []);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "employee_details"));
        const data = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setEmployeeData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
      }
    };

    fetchEmployeeData();
  }, []);

  useEffect(() => {
    if (selectedProjectData.StartDate) {
      const startDate = new Date(selectedProjectData.StartDate);
      const startYearString = startDate.toLocaleString("default", {
        year: "2-digit",
      });
      const startMonthString = startDate
        .toLocaleString("default", {
          month: "short",
        })
        .toUpperCase();
      setMonths([`${startMonthString}-${startYearString}`]);
    }
  }, [selectedProjectData]);

  const auth = getAuth();

  // function onChange(e) {

  //   if (!e.target.files) {
  //     const { id, value } = e.target;
  //     const selectedProject = projectData.find(
  //       (project) => project.ProjectName === value
  //     );

  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [id]: value,
  //       ProjectId: selectedProject?.ProjectId || "",
  //       id: selectedProject?.id || "",
  //       StartDate: selectedProject?.StartDate || "",
  //       EndDate: selectedProject?.EndDate || "",
  //     }));

  //     setRows([]);
  //     setAddEmployeeButtonClicked(false);

  //     setSelectedProjectData(selectedProject || {});

  //     if (id === "ProjectName") {
  //       setShowDiv(true);
  //     } else {
  //       setShowDiv(false);
  //     }
  //   }
  // }

  // function onChange(e) {
  //   // Check if the selected project's value is already in the allocated projects array

  //   if (!e.target.files) {
  //     const { id, value } = e.target;
  //     const selectedProject = projectData.find(
  //       (project) => project.ProjectName === value
  //     );

  //     console.log(alreadyAllocatedProjects)
  //     console.log(alreadyAllocatedProjects.includes(selectedProject?.ProjectId));
  //     console.log("value from input "+ selectedProject?.ProjectId)

  //     if (alreadyAllocatedProjects.includes(selectedProject?.ProjectId)) {
  //       // If the value is already allocated, redirect the user to a specific route
  //       window.location.href = "/project-wise-overview";
  //       return; // Return from the function after redirection
  //     }

  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [id]: value,
  //       ProjectId: selectedProject?.ProjectId || "",
  //       id: selectedProject?.id || "",
  //       StartDate: selectedProject?.StartDate || "",
  //       EndDate: selectedProject?.EndDate || "",
  //     }));

  //     setRows([]);
  //     setAddEmployeeButtonClicked(false);

  //     setSelectedProjectData(selectedProject || {});

  //     if (id === "ProjectName") {
  //       setShowDiv(true);
  //     } else {
  //       setShowDiv(false);
  //     }
  //   }
  // }

  // function onChange(e) {
  //   if (!e.target.files) {
  //     const { id, value } = e.target;
  //     const selectedProject = projectData.find(
  //       (project) => project.ProjectName === value
  //     );

  //     if (alreadyAllocatedProjects.includes(selectedProject?.ProjectId)) {
  //       // Show a warning alert if the project is already allocated
  //       Swal.fire({
  //         icon: 'warning',
  //         title: 'Project Already Allocated',
  //         text: 'This project is already allocated want to allocate more months?.',
  //         confirmButtonColor: '#3085d6',
  //         confirmButtonText: 'Go to project wise allocation',
  //         cancelButtonText: 'Cancel',
  //       }).then((result) => {
  //         // Redirect the user to a specific route after the alert is closed
  //         if (result.isConfirmed) {
  //           window.location.href = "/project-wise-overview";
  //         }
  //       });

  //       return; // Return from the function after showing the alert
  //     }

  //     // Rest of your code
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [id]: value,
  //       ProjectId: selectedProject?.ProjectId || "",
  //       id: selectedProject?.id || "",
  //       StartDate: selectedProject?.StartDate || "",
  //       EndDate: selectedProject?.EndDate || "",
  //     }));

  //     setRows([]);
  //     setAddEmployeeButtonClicked(false);

  //     setSelectedProjectData(selectedProject || {});

  //     if (id === "ProjectName") {
  //       setShowDiv(true);
  //     } else {
  //       setShowDiv(false);
  //     }
  //   }
  // }

  function onChange(e) {
    if (!e.target.files) {
      const { id, value } = e.target;
      const selectedProject = projectData.find(
        (project) => project.ProjectName === value
      );

      if (alreadyAllocatedProjects.includes(selectedProject?.ProjectId)) {
        // Show a custom alert if the project is already allocated
        Swal.fire({
          icon: "warning",
          title: "Project Already Allocated",
          text: "This project is already allocated. Do you want to allocate for more months?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Go to project wise allocation",
          cancelButtonText: "Cancel",
        }).then((result) => {
          // Redirect the user to a specific route after the alert is closed
          if (result.isConfirmed) {
            window.location.href = "/project-wise-overview";
          }
        });

        return; // Return from the function after showing the alert
      }

      // Rest of your code
      setFormData((prevState) => ({
        ...prevState,
        [id]: value,
        ProjectId: selectedProject?.ProjectId || "",
        id: selectedProject?.id || "",
        StartDate: selectedProject?.StartDate || "",
        EndDate: selectedProject?.EndDate || "",
      }));

      setRows([]);
      setAddEmployeeButtonClicked(false);

      setSelectedProjectData(selectedProject || {});

      if (id === "ProjectName") {
        setShowDiv(true);
      } else {
        setShowDiv(false);
      }
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    // console.log(formData);
    // console.log(rows);
    // console.log(employeeValues);
    // console.log(selectedProjectData);
    // console.log(
    //   " selectedProjectData.ProjectId",
    //   selectedProjectData.ProjectId
    // );
    // console.log(percentageOfWorkOfEmployeeMonthWise);

    try {
      // Set loading to true before adding the document
      setLoading(true);
      const formDataCopy = {
        ...percentageOfWorkOfEmployeeMonthWise,
        completedTill: months,
        ProjectName: selectedProjectData.ProjectName,
        FunderName: selectedProjectData.FunderName || "",
        isSubmitted:false
      };

      

      // Add the document to the Firestore database
      await setDoc(
        doc(db, "taskAllocation", selectedProjectData.ProjectId),
        formDataCopy
      );

      const activity = {
        action: "create",
        nameOfLoggedInUser: auth.currentUser?.displayName,
        dateAndTime: serverTimestamp(),
        forWhat: "TaskAllocate",
        ID: selectedProjectData.ProjectId,
        Name: selectedProjectData.ProjectName,
      };

      await addDoc(collection(db, "activity"), activity);

      // Document added successfully, set loading back to false
      setSelectedProjectData("");
      // window.location.reload();
      window.location.reload();
      setLoading(false);
    } catch (error) {
      console.error("Error adding document:", error);
      // In case of an error, set loading back to false
      setLoading(false);
    }
  }

  const addRow = () => {
    setRows((prevRows) => [...prevRows, { employee: "" }]);
    setAddEmployeeButtonClicked(true);
    // setAddEmployeeButtonClicked(false);
  };

  const deleteRow = (event, index) => {
    event.preventDefault();
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows.splice(index, 1);
      return updatedRows;
    });
    if (employeeValues.length === 0) {
      setAddEmployeeButtonClicked(false);
    }
  };

  const handleEmployeeChange = (index, selectedEmployee) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].employee = selectedEmployee;
      return updatedRows;
    });
    setEmployeeSelected(true);
  };

  // console.log(months);

  const monthAbbreviations = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const addColumn = (e) => {
    e.preventDefault();

    if (months.length > 0) {
      const lastMonth = months[months.length - 1];
      const [month, year] = lastMonth.split("-");
      let nextYear = parseInt(year, 10);
      let nextMonthIndex = monthAbbreviations.indexOf(month) + 1;

      if (nextMonthIndex === 12) {
        nextMonthIndex = 0;
        nextYear += 1;
      }

      const nextMonth = monthAbbreviations[nextMonthIndex];
      const nextYearString = nextYear.toString().slice(-2);

      setMonths((prevMonths) => [
        ...prevMonths,
        `${nextMonth}-${nextYearString}`,
      ]);
    }
  };

  const deleteColumn = (e) => {
    e.preventDefault();

    if (months.length > 1) {
      const newMonths = [...months];
      newMonths.pop();
      setMonths(newMonths);
    }
  };

  const onChangeOfcellValues = (rowIndex, columnIndex, value) => {
    // Use the rowIndex and columnIndex as needed
    // console.log("Row Index:", rowIndex);
    // console.log("Column Index:", columnIndex);

    // Update the state with the value and id (constructed from the indexes)
    setPercentageOfWorkOfEmployeeMonthWise((prevState) => ({
      ...prevState,
      [`${months[columnIndex]}_${rows[rowIndex]?.employee}`]: value,
    }));
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="max-w-6xl px-2 mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">Allocate Task</h1>
      <form onSubmit={onSubmit}>
        <p className="text-l mt-6 font-semibold">Project Name</p>
        <select
          id="ProjectName"
          onChange={onChange}
          required
          className="lg:w-1/4 md:w-1/2 px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
          value={formData.ProjectName || ""}
        >
          <option value="">Select a Project</option>
          {projectData.map((project) => (
            <option key={project.id} value={project.ProjectName}>
              {project.ProjectName}
            </option>
          ))}
        </select>

        {showDiv && (
          <div>
            <p>Selected Project Id is {formData.ProjectId}</p>
            <p>Selected Project Name is {formData.ProjectId}</p>

            <button
              type="button"
              className="bg-green-500 text-white py-2 px-4 rounded mr-2 mt-3"
              onClick={addRow}
            >
              Add Employee
            </button>
          </div>
        )}

        {addEmployeeButtonClicked && (
          <div>
            <div className="max-w-screen overflow-x-auto">
              <div className="table-scrollable">
                <table className="table-auto min-w-max">
                  <thead>
                    <tr>
                      <th className="table-fixed border-r bg-gray-100">
                        Employee Name
                      </th>
                      {months.map((monthYear, index) => (
                        <th key={index} className="border-r">
                          {monthYear}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="table-fixed bg-white overflow-y-hidden">
                          <div className="flex space-x-1">
                            <select
                              value={row.employee}
                              onChange={(e) => {
                                handleEmployeeChange(rowIndex, e.target.value);
                                setIsDisabled(true);
                              }}
                              disabled={
                                isDisabled &&
                                employeeValues.includes(row.employee) &&
                                row.employee != ""
                              }
                            >
                              <option
                                value=""
                                disabled={employeeValues.includes("")}
                              >
                                Select an Employee
                              </option>
                              {employeeData.map((employee, employeeIndex) => (
                                <option
                                  key={employeeIndex}
                                  value={employee.EmployeeName}
                                  disabled={employeeValues.includes(
                                    employee.EmployeeName
                                  )}
                                >
                                  {employee.EmployeeName}
                                </option>
                              ))}
                            </select>

                            <button
                              className="button"
                              onClick={(e) => deleteRow(e, rowIndex)}
                            >
                              <AiOutlineMinusCircle className="text-4xl  text-white border rounded-full bg-red-500 hover:bg-red-700" />
                            </button>
                          </div>
                        </td>

                        {months.map((_, columnIndex) => (
                          <td key={columnIndex} className="min-w-[1/6]">
                            {/* {months[columnIndex]}-{rows[rowIndex]?.employee} -- {columnIndex} */}
                            <input
                              id={`${months[columnIndex]}-${rows[rowIndex]?.employee}`}
                              // placeholder={`${rows[rowIndex]?.employee}`}
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              className="w-full"
                              onChange={(e) =>
                                onChangeOfcellValues(
                                  rowIndex,
                                  columnIndex,
                                  e.target.value
                                )
                              }
                              
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {addEmployeeButtonClicked && (
          <>
            <button
              onClick={addColumn}
              className="bg-green-500 text-white py-2 px-4 rounded mr-2 mt-3"
            >
              Add Column
            </button>
            <button
              onClick={deleteColumn}
              className="bg-red-500 text-white py-2 px-4 rounded mt-3"
            >
              Delete Column
            </button>
          </>
        )}

        <br></br>

        {addEmployeeButtonClicked && (
          <div className="flex items-center">
            <button
              type="submit"
              className="mb-6 mt-2 mx-auto w-auto px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        )}
      </form>
    </main>
  );
}