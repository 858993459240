// import React, { useState } from 'react';

// const Testing = () => {
//   const [formData2, setFormData2] = useState({});

//   const handleChange = (e) => {
//     const { id, value } = e.target;
//     setFormData2((prevFormData2) => ({
//       ...prevFormData2,
//       [id]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(formData2);
//     // Perform form submission or further processing with formData2
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       {/* Render input fields dynamically */}
//       {Object.keys(formData2).map((id) => (
//         <input
//           key={id}
//           type="text"
//           id={id}
//           value={formData2[id] || ''}
//           onChange={handleChange}
//           placeholder={`Enter value for ${id}`}
//           required
//           className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
//         />
//       ))}

//       {/* Add button to add new input field */}
//       <button
//         type="button"
//         onClick={() => {
//           const newId = `input${Object.keys(formData2).length + 1}`;
//           setFormData2((prevFormData2) => ({
//             ...prevFormData2,
//             [newId]: '',
//           }));
//         }}
//         className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//       >
//         Add Input Field
//       </button>

//       {/* Submit button */}
//       <button
//         type="submit"
//         className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4"
//       >
//         Submit
//       </button>
//     </form>
//   );
// };

// export default Testing;

// pagination

// import { useState } from 'react';

// // Assuming the initial page number is 1 and there are 5 projects per page
// const itemsPerPage = 5;

// const ProjectTable = ({ projectData }) => {
//   const [currentPage, setCurrentPage] = useState(1);

//   // Calculate the total number of pages based on the projectData length and itemsPerPage
//   const totalPages = Math.ceil(projectData.length / itemsPerPage);

//   // Calculate the index range of projects to be displayed on the current page
//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;

//   // Function to handle page change
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // Slice the projectData array based on the current page
//   const projectsToShow = projectData.slice(startIndex, endIndex);

//   return (
//     <>
//       {projectsToShow.length > 0 ? (
//         <table className="min-w-full divide-y divide-gray-200">
//           {/* Table header */}
//           {/* ... */}

//           <tbody className="bg-white divide-y divide-gray-200">
//             {projectsToShow.map((project, index) => (
//               <tr key={index}>
//                 {/* Project details */}
//                 {/* ... */}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       ) : (
//        <h2>No projects found.</h2>
//       )}

//       {/* Pagination */}
//       {totalPages > 1 && (
//         <div className="flex justify-center mt-4">
//           <nav className="inline-flex rounded-md shadow">
//             <ul className="flex space-x-2">
//               {/* Previous page button */}
//               <li>
//                 <button
//                   disabled={currentPage === 1}
//                   onClick={() => handlePageChange(currentPage - 1)}
//                   className={`px-3 py-1 rounded-md ${
//                     currentPage === 1
//                       ? 'bg-gray-200 cursor-not-allowed'
//                       : 'bg-white hover:bg-gray-100'
//                   }`}
//                 >
//                   Previous
//                 </button>
//               </li>

//               {/* Page buttons */}
//               {Array.from({ length: totalPages }, (_, i) => i + 1).map(
//                 (pageNumber) => (
//                   <li key={pageNumber}>
//                     <button
//                       onClick={() => handlePageChange(pageNumber)}
//                       className={`px-3 py-1 rounded-md ${
//                         currentPage === pageNumber
//                           ? 'bg-gray-200 cursor-not-allowed'
//                           : 'bg-white hover:bg-gray-100'
//                       }`}
//                     >
//                       {pageNumber}
//                     </button>
//                   </li>
//                 )
//               )}

//               {/* Next page button */}
//               <li>
//                 <button
//                   disabled={currentPage === totalPages}
//                   onClick={() => handlePageChange(currentPage + 1)}
//                   className={`px-3 py-1 rounded-md ${
//                     currentPage === totalPages
//                       ? 'bg-gray-200 cursor-not-allowed'
//                       : 'bg-white hover:bg-gray-100'
//                   }`}
//                 >
//                   Next
//                 </button>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       )}
//     </>
//   );
// };

// export default ProjectTable;

// uploading multiple file

// import { useState } from "react";
// import { Spinner } from "../components/Spinner";
// import { toast } from "react-toastify";
// import {
//   getStorage,
//   ref as storageReff,
//   uploadBytesResumable,
//   getDownloadURL,
// } from "firebase/storage";
// import { push, ref } from "firebase/database";
// import { v4 as uuidv4 } from "uuid";
// import { getAuth } from "firebase/auth";
// import { addDoc, collection, serverTimestamp } from "firebase/firestore";
// import { db } from "../firebase";
// import { useNavigate } from "react-router-dom";
// import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

// const FileUploader = () => {
//   const [proposalAttachmentsDocuments, setProposalAttachmentsDocuments] =
//     useState([]);
//   const [conceptNotesDocuments, setConceptNotesDocuments] = useState([]);
//   const [grantAgreementsDocuments, setGrantAgreementsDocuments] = useState([]);
//   const [budgetsDocuments, setBudgetsDocuments] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const proposalAttachmentsDocumentsNames = [];
//   const proposalAttachmentsDocumentsUrls = [];

//   const conceptNotesAttachmentsDocumentsNames = [];
//   const conceptNotesAttachmentsDocumentsUrls = [];

//   const grantAgreementAttachmentsDocumentsNames = [];
//   const grantAgreementAttachmentsDocumentsUrls = [];

//   const budgetDocumentsNames = [];
//   const budgetDocumentsUrls = [];

//   const handleFileChange = (event, setDocuments) => {
//     const files = event.target.files;
//     const documentsArray = Array.from(files);
//     setDocuments((prevDocuments) => [...prevDocuments, ...documentsArray]);
//   };

//   const handleUpload = async () => {
//     try {
//       setLoading(true);
  
//       const storage = getStorage();
  
//       const uploadDocument = async (documents, folderName) => {
//         const uploadPromises = documents.map(async (file) => {
//           const fileId = uuidv4();
//           const fileExtension = file.name.split(".").pop();
//           const storageRef = storageReff(
//             storage,
//             `${folderName}/${fileId}.${fileExtension}`
//           );
//           const uploadTask = uploadBytesResumable(storageRef, file);
  
//           return new Promise((resolve, reject) => {
//             uploadTask.on(
//               "state_changed",
//               (snapshot) => {
//                 // Handle progress or monitoring if needed
//               },
//               (error) => {
//                 console.error(`Error uploading ${folderName} document:`, error);
//                 reject(error);
//               },
//               () => {
//                 getDownloadURL(uploadTask.snapshot.ref)
//                   .then((downloadURL) => {
//                     // Store the file name and URL in the corresponding variables
//                     if (folderName === "proposalAttachments") {
//                       proposalAttachmentsDocumentsNames.push(file.name);
//                       proposalAttachmentsDocumentsUrls.push(downloadURL);
//                     } else if (folderName === "conceptNotesAttachments") {
//                       conceptNotesAttachmentsDocumentsNames.push(file.name);
//                       conceptNotesAttachmentsDocumentsUrls.push(downloadURL);
//                     } else if (folderName === "grantAgreementAttachments") {
//                       grantAgreementAttachmentsDocumentsNames.push(file.name);
//                       grantAgreementAttachmentsDocumentsUrls.push(downloadURL);
//                     } else if (folderName === "budgetDocuments") {
//                       budgetDocumentsNames.push(file.name);
//                       budgetDocumentsUrls.push(downloadURL);
//                     }
  
//                     resolve();
//                   })
//                   .catch((error) => {
//                     console.error("Error getting download URL:", error);
//                     reject(error);
//                   });
//               }
//             );
//           });
//         });
  
//         await Promise.all(uploadPromises);
//       };
  
//       // Upload proposalAttachmentsDocuments
//       await uploadDocument(proposalAttachmentsDocuments, "proposalAttachments");
  
//       // Upload conceptNotesDocuments
//       await uploadDocument(conceptNotesDocuments, "conceptNotesAttachments");
  
//       // Upload grantAgreementsDocuments
//       await uploadDocument(
//         grantAgreementsDocuments,
//         "grantAgreementAttachments"
//       );
  
//       // Upload budgetsDocuments
//       await uploadDocument(budgetsDocuments, "budgetDocuments");
  
//       // Reset the input fields to null
//       setProposalAttachmentsDocuments([]);
//       setConceptNotesDocuments([]);
//       setGrantAgreementsDocuments([]);
//       setBudgetsDocuments([]);
  
//       toast.success("Documents uploaded successfully!");
//     } catch (error) {
//       console.error("Error uploading documents:", error);
//       toast.error("Error uploading documents");
//     } finally {
//       setLoading(false);
//     }
  
//     console.log(proposalAttachmentsDocumentsNames);
//     console.log(proposalAttachmentsDocumentsUrls);
//     console.log(conceptNotesAttachmentsDocumentsNames);
//     console.log(conceptNotesAttachmentsDocumentsUrls);
//     console.log(grantAgreementAttachmentsDocumentsNames);
//     console.log(grantAgreementAttachmentsDocumentsUrls);
//     console.log(budgetDocumentsNames);
//     console.log(budgetDocumentsUrls);
//   };

//   if (loading) {
//     return <Spinner />;
//   }
//   return (
   
   
// <>
//   <div className="flex flex-wrap mb-4">
//     <div className="w-1/2 pr-2 mb-4">
//       <p className="mb-2">Proposal Attachments</p>
//       <input
//         className="border border-gray-300 py-2 px-4 rounded-lg w-full"
//         type="file"
//         multiple
//         onChange={(e) => handleFileChange(e, setProposalAttachmentsDocuments)}
//       />
//       {/* Render the uploaded proposal attachments files */}
//       {proposalAttachmentsDocuments.map((file) => (
//         <div key={file.name}>{file.name}</div>
//       ))}
//     </div>

//     <div className="w-1/2 pl-2 mb-4">
//       <h2 className="mb-2">Concept Notes</h2>
//       <input
//         className="border border-gray-300 py-2 px-4 rounded-lg w-full"
//         type="file"
//         multiple
//         onChange={(e) => handleFileChange(e, setConceptNotesDocuments)}
//       />
//       {/* Render the uploaded concept notes files */}
//       {conceptNotesDocuments.map((file) => (
//         <div key={file.name}>{file.name}</div>
//       ))}
//     </div>
//   </div>

//   <div className="flex flex-wrap mb-4">
//     <div className="w-1/2 pr-2 mb-4">
//       <h1 className="mb-2">Grant Agreements</h1>
//       <input
//         className="border border-gray-300 py-2 px-4 rounded-lg w-full"
//         type="file"
//         multiple
//         onChange={(e) => handleFileChange(e, setGrantAgreementsDocuments)}
//       />
//       {/* Render the uploaded grant agreements files */}
//       {grantAgreementsDocuments.map((file) => (
//         <div key={file.name}>{file.name}</div>
//       ))}
//     </div>

//     <div className="w-1/2 pl-2 mb-4">
//       <h1 className="mb-2">Budgets</h1>
//       <input
//         className="border border-gray-300 py-2 px-4 rounded-lg w-full"
//         type="file"
//         multiple
//         onChange={(e) => handleFileChange(e, setBudgetsDocuments)}
//       />
//       {/* Render the uploaded budgets files */}
//       {budgetsDocuments.map((file) => (
//         <div key={file.name}>{file.name}</div>
//       ))}
//     </div>
//   </div>

//   <button
//     className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//     onClick={handleUpload}
//   >
//     Upload Documents
//   </button>
// </>




//   );
// };

// export default FileUploader;
















import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore'; // Assuming you're using Firebase Firestore
import { db } from '../firebase';
const Testing = () => {
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "project_details")); // Replace "project_details" with your collection name
        const data = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setProjectData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch project data:", error);
      }
    };

    fetchProjectData();
  }, []);

  const renderObjectContent = (data) => {
    return Object.entries(data).map(([key, value]) => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        // Nested object, recursively render its content
        return (
          <div key={key}>
            <p>{key}:</p>
            <div style={{ marginLeft: '1rem' }}>{renderObjectContent(value)}</div>
          </div>
        );
      } else if (Array.isArray(value)) {
        // Array, render its content
        return (
          <div key={key}>
            <p>{key}:</p>
            <div style={{ marginLeft: '1rem' }}>
              {value.map((item, index) => (
                <div key={index}>
                  {typeof item === 'object' ? (
                    // Nested object inside the array, recursively render its content
                    renderObjectContent(item)
                  ) : (
                    // Non-object item in the array, render it as-is
                    <p>{item}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      } else {
        // Non-object value, render it as-is
        return (
          <p key={key}>
            {key}: {value}
          </p>
        );
      }
    });
  };

  return (
    <div>
      {loading ? (
        <div>Loading project data...</div>
      ) : (
        <div>
          <h1>Object Content:</h1>
          {renderObjectContent(projectData[0])}
        </div>
      )}
    </div>
  );
};

export default Testing;

