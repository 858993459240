import React from "react";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from 'react-icons/bi';

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigate back to the previous route
  };

  return (
    <div className="px-3 max-w-6xl mx-auto">
      <button onClick={goBack} className="text-gray-400 py-2 rounded">
        <BiArrowBack  className=" text-2xl  "/> 
      </button>
    </div>
  );
};

export default BackButton;
