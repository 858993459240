import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  deleteDoc,
  updateDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import {
  BsPencil,
  BsTrash,
  BsArrowDown,
  BsArrowUp,
  BsSortNumericUp,
} from "react-icons/bs";
import { getAuth } from "firebase/auth";

import { AiOutlineSortAscending, AiFillCaretDown } from "react-icons/ai";
import Swal from "sweetalert2";
import { Spinner } from "../components/Spinner";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import DownloadEmployee from "../components/DownloadEmployee";
import XLSX from "xlsx/dist/xlsx.full.min.js";
import BackButton from "../components/BackButton";

const AllEmployeeBeforeSubmit = () => {
  const auth = getAuth();

  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [isDownloadExcelClicked, setIsDownloadExcelClicked] = useState(false);
  const [filter, setFilter] = useState("all");

  const [email, setEmail] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setEmail(user?.email);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "employee_details"));
        const data = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setEmployeeData(data);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
      }
    };

    fetchEmployeeData();
    // console.log(employeeData);
  }, [dataToEdit]);

//   console.log(employeeData);


  const handleDeleteEmployee = async (id, empName, empId) => {
    try {
      setLoading(true); // Set isLoading to true

      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this employee?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (!confirmation.isConfirmed) {
        setLoading(false); // Set isLoading to false
        // console.log("Deletion cancelled");
        return;
      }

      const fileDeletionConfirmation = await Swal.fire({
        title: "Are you sure?",
        text: "Deleted Employee cannot be retrieved. Are you sure you want to proceed?",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Yes, delete them!",
        cancelButtonText: "Cancel",
      });

      if (!fileDeletionConfirmation.isConfirmed) {
        setLoading(false); // Set isLoading to false
        // console.log("File deletion cancelled");
        return;
      }

      await deleteDoc(doc(db, "employee_details", id));

      const activity = {
        action: "delete",
        nameOfLoggedInUser: auth.currentUser?.displayName,
        dateAndTime: serverTimestamp(),
        forWhat: "EmployeeBeforeSubmit",
        ID: empId,
        Name: empName,
      };

      await addDoc(collection(db, "activity"), activity);

      setEmployeeData((prevData) => prevData.filter((emp) => emp.id !== id));
      // console.log("Employee deleted successfully:", id);
    } catch (error) {
      console.error("Failed to delete employee:", error);
    } finally {
      setLoading(false); // Set isLoading to false in the finally block
    }
  };

  const handleEditEmployee = async (employeeid) => {
    localStorage.setItem("IdOfDataToBeEdited", employeeid);
    try {
      const docRef = doc(db, "employee_details", employeeid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const employeeData = docSnap.data();
        setDataToEdit(employeeData);
        // console.log("Employee data:", employeeData);
        setIsEditing(true);
      } else {
        // console.log("Employee not found");
      }
    } catch (error) {
      console.error("Failed to fetch employee data:", error);
    }
  };

  const UploadEditedDataToDatabase = async (e) => {
    e.preventDefault();
    const IdToEdit = localStorage.getItem("IdOfDataToBeEdited");
    try {
      const docRef = doc(db, "employee_details", IdToEdit);

      const dataToUpload = {
        ...dataToEdit,
        Total: calculateTotal(),
      };

      await updateDoc(docRef, dataToUpload);

      const activity = {
        action: "edit",
        nameOfLoggedInUser: auth.currentUser.displayName,
        dateAndTime: serverTimestamp(),
        forWhat: "EmployeeBeforeSubmit",
        ID: dataToUpload.EmployeeID,
        Name: dataToUpload.EmployeeName,
      };

      await addDoc(collection(db, "activity"), activity);


      setDataToEdit({});
      setIsEditing(false);
      localStorage.removeItem("IdOfDataToBeEdited");
    } catch (error) {
      console.error("Failed to upload edited employee data:", error);
    }
  };

  const FinalSubmit = async(id,name,employeeID)=>{
    console.log(id);

    const IdToEdit = id;
    try {
      const activity = {
        action: "submit",
        nameOfLoggedInUser: auth.currentUser.displayName,
        dateAndTime: serverTimestamp(),
        forWhat: "EmployeeBeforeSubmit",
        ID: employeeID,
        Name: name,
      };

      await addDoc(collection(db, "activity"), activity);


      const docRef = doc(db, "employee_details", IdToEdit);
      const dataToUpload = {
        isSubmitted:true
      };
      await updateDoc(docRef, dataToUpload);
      window.location.reload();
    } catch (error) {
      console.error("Failed to upload edited employee data:", error);
    }
  }

 

  const calculateTotal = () => {
    let total = 0;
    const fieldsSum =
      +dataToEdit.Basic +
      +dataToEdit.HRA +
      +dataToEdit.MedicalAllowance +
      +dataToEdit.SpecialAllowance +
      +dataToEdit.EmployeePfContribution +
      +dataToEdit.EmployerPfContribution +
      +dataToEdit.Gratuity +
      +dataToEdit.GroupInsurance +
      +dataToEdit.MedicalInsurance;
    for (let i = 0; i < dataToEdit.salary?.length; i++) {
      total += parseFloat(dataToEdit.salary[i].value);
    }

    return total + fieldsSum;
  };

  // console.log(calculateTotal());

  // Function to add a new budget field
  const addBudgetField = () => {
    if (Array.isArray(dataToEdit.salary)) {
      setDataToEdit({
        ...dataToEdit,
        salary: [...dataToEdit.salary, { name: "", value: "0" }],
      });
    } else {
      // Handle the case when dataToEdit.salary is not an array
      setDataToEdit({
        ...dataToEdit,
        salary: [{ name: "", value: "0" }],
      });
    }
  };

  const deleteBudgetField = (index) => {
    const updatedBudget = [...dataToEdit.salary];
    updatedBudget.splice(index, 1);
    setDataToEdit({
      ...dataToEdit,
      salary: updatedBudget,
    });
  };

  const filteredEmployeeData = employeeData.filter((employee) => {
    if (filter === "currentlyWorking") {
      return employee.LeavingDate === "";
    }
    return true; // For 'all' option, show all employees
  });


  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      {/* <BackButton/> */}
      <h1 className="text-3xl text-center mt-6 m-6 font-bold">All Employees Before submit</h1>

      {filteredEmployeeData.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 border-collapse  border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center">
                    <span className="mr-1">Employee number</span>
                    
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center">
                    <span className="mr-1">Name</span>
                    
                  </div>
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center">
                    <span className="mr-1">Email</span>
                    
                  </div>
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ">
                  <div className="flex items-center">
                    <span className="mr-2">Department</span>
                    
                  </div>
                 
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center">
                    <span className="mr-1">Line manager</span>
                   
                  </div>
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex items-center">
                    <span className="mr-1">Total Years of experience</span>
                    
                  </div>
                </th>

                {(email == "srinivas@vasudhaindia.org" ||
                  email == "gunjan@vasudhaindia.org") && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <div className="flex items-center">
                      <span className="mr-1">Actions</span>
                    </div>
                  </th>
                )}
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {filteredEmployeeData
                .filter((employee) =>
                  selectedDepartment
                    ? employee.Department === selectedDepartment
                    : true
                )
                .map((employee, index) => (
                     !(employee.isSubmitted) && (
                        <tr key={index}  className="border-b border-gray-300">
                        <td className=" px-6 py-6 whitespace-nowrap border-r border-gray-300">
                          <p className="text-sm text-gray-900 text-center">
                            {employee.EmployeeID}
                          </p>
                        </td>
                        <td className=" px-6 py-6 whitespace-nowrap border-r border-gray-300">
                          <p className="text-sm text-gray-900">
                            {employee.EmployeeName.split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" ")}
                          </p>
                        </td>
    
                        <td className=" px-6 py-6 whitespace-nowrap border-r border-gray-300">
                          <p className="text-sm text-gray-900">
                            {employee.EmployeeEmail}
                          </p>
                        </td>
    
                        <td className=" px-6 py-6 whitespace-nowrap border-r border-gray-300">
                          <p className="text-sm text-gray-900">
                            {employee.Department}
                          </p>
                        </td>
    
                        <td className=" px-6 py-6 whitespace-nowrap border-r border-gray-300">
                          <p className="text-sm text-gray-900">
                            {employee.ReportingManager}
                          </p>
                        </td>
    
                       
    
                        <td className=" px-6 py-6 whitespace-nowrap border-r border-gray-300">
                          <p className="text-sm text-gray-900">
                            {"Previous Year of experience: " +
                              employee.YearOfExperience}
                            <br />
                            {"Joining date: " + employee.JoiningDate}
                            <br />
    
                            {(() => {
                              const today = new Date();
                              const year = today.getFullYear();
                              const month = String(today.getMonth() + 1).padStart(
                                2,
                                "0"
                              );
                              const day = String(today.getDate()).padStart(2, "0");
                              const formattedDate = `${year}-${month}-${day}`;
                              // return "Todays date: " + formattedDate;
                            })()}
                            {/* <br /> */}
                            {(() => {
                              const joiningDate = new Date(employee.JoiningDate);
                              const today = new Date();
                              const timeDiff = today - joiningDate;
    
                              // Calculate the difference in years
                              const yearsDiff =
                                timeDiff / (1000 * 60 * 60 * 24 * 365.25);
    
                              const sumOfYearAndExperience =
                                yearsDiff + parseFloat(employee.YearOfExperience);
    
                              const years = Math.floor(sumOfYearAndExperience);
                              const months = Math.floor(
                                (sumOfYearAndExperience - years) * 12
                              );
    
                              let result = "";
                              if (years > 0) {
                                result += years === 1 ? "1 year" : `${years} years`;
                              }
                              if (months > 0) {
                                result += result ? " " : "";
                                result +=
                                  months === 1 ? "1 month" : `${months} months`;
                              }
    
                              // return result;
                              return `Total years of experience: ${result}`;
                            })()}
                          </p>
                        </td>
    
                        
    
                        {(email == "srinivas@vasudhaindia.org" ||
                          email == "gunjan@vasudhaindia.org"||
                          email == "it@vasudhaindia.org") && (
                          <td className=" px-6 py-6 whitespace-nowrap border-r border-gray-300">
                            <div className="flex items-center">
                              <button
                                className="text-blue-600 hover:text-blue-900 mr-2"
                                onClick={() => handleEditEmployee(employee.id)}
                              >
                                <BsPencil />
                              </button>
                              <button
                                className="text-red-600 hover:text-red-900"
                                onClick={() =>
                                  handleDeleteEmployee(
                                    employee.id,
                                    employee.EmployeeName,
                                    employee.EmployeeID
                                  )
                                }
                              >
                                <BsTrash />
                              </button>
                              <button
                              className="bg-blue-500 text-white py-2 px-4 rounded ml-2"
                              onClick={()=>FinalSubmit(employee.id,employee.EmployeeName,employee.EmployeeID)}
                              >
                                SUBMIT
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                      )
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-lg">No Employee found.</p>
      )}

      {isEditing && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg overflow-y-scroll max-h-96">
            <h2 className="text-lg font-bold mb-4">Edit Employee</h2>
            <form>
              {/* Render the input fields here */}

              <div className="flex space-x-4">
                <div className="w-1/4">
                  <div className="mb-4">
                    <label
                      htmlFor="employeeId"
                      className="block font-bold mb-1"
                    >
                      Employee ID
                    </label>
                    <input
                      type="text"
                      id="employeeId"
                      value={dataToEdit.EmployeeID}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          EmployeeID: e.target.value,
                        })
                      }
                      className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="w-1/4">
                  <div className="mb-4">
                    <label
                      htmlFor="employeename"
                      className="block font-bold mb-1"
                    >
                      Employee Name
                    </label>
                    <input
                      type="text"
                      id="employeename"
                      value={dataToEdit.EmployeeName}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          EmployeeName: e.target.value,
                        })
                      }
                      className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="w-1/4">
                  <div className="mb-4">
                    <label
                      htmlFor="employeeemail"
                      className="block font-bold mb-1"
                    >
                      Employee Email
                    </label>
                    <input
                      type="text"
                      id="employeeemail"
                      value={dataToEdit.EmployeeEmail}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          EmployeeEmail: e.target.value,
                        })
                      }
                      className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="w-1/4">
                  <div className="mb-4">
                    <label
                      htmlFor="ContactNumber"
                      className="block font-bold mb-1"
                    >
                      Contact number
                    </label>
                    <input
                      type="text"
                      id="ContactNumber"
                      value={dataToEdit.ContactNumber}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          ContactNumber: e.target.value,
                        })
                      }
                      className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
              </div>

              <div className="flex space-x-4">
                <div className="w-1/4">
                  <div className="mb-4">
                    <label
                      htmlFor="Designation"
                      className="block font-bold mb-1"
                    >
                      Designation
                    </label>
                    <input
                      type="text"
                      id="Designation"
                      value={dataToEdit.Designation}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          Designation: e.target.value,
                        })
                      }
                      className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="w-1/4">
                  <div className="mb-4">
                    <label
                      htmlFor="HighestQualification"
                      className="block font-bold mb-1"
                    >
                      Qualification
                    </label>
                    <input
                      type="text"
                      id="HighestQualification"
                      value={dataToEdit.HighestQualification}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          HighestQualification: e.target.value,
                        })
                      }
                      className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="w-1/4">
                  <div className="mb-4">
                    <label
                      htmlFor="YearOfExperience"
                      className="block font-bold mb-1"
                    >
                      Year Of Experience
                    </label>
                    <input
                      type="number"
                      id="YearOfExperience"
                      value={dataToEdit.YearOfExperience}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          YearOfExperience: e.target.value,
                        })
                      }
                      className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="department" className="block font-bold mb-1">
                    Department
                  </label>
                  <select
                    id="department"
                    value={dataToEdit.Department}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        Department: e.target.value,
                      })
                    }
                    className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="Climate">Climate</option>
                    <option value="Communication">Communication</option>
                    <option value="Communication">Consultant</option>
                    <option value="Energy & Power">Energy & Power</option>
                    <option value="Finance">Finance</option>
                    <option value="GIS">GIS</option>
                    <option value="HR">HR</option>
                    <option value="IT">IT</option>
                    <option value="IT">Logistic & Others</option>
                    <option value="SMT">SMT (Senior Management Team)</option>
                  </select>
                </div>
              </div>

              <div className="flex space-x-4">
                <div className="w-1/3">
                  <div className="mb-4">
                    <label
                      htmlFor="ReportingManager"
                      className="block font-bold mb-1"
                    >
                      Reporting Manager
                    </label>
                    <input
                      type="text"
                      id="ReportingManager"
                      value={dataToEdit.ReportingManager}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          ReportingManager: e.target.value,
                        })
                      }
                      className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="w-1/3">
                  <div className="mb-4">
                    <label
                      htmlFor="joiningDate"
                      className="block font-bold mb-1"
                    >
                      Joining Date
                    </label>
                    <input
                      type="date"
                      id="joiningDate"
                      value={dataToEdit.JoiningDate}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          JoiningDate: e.target.value,
                        })
                      }
                      className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="w-1/3">
                  <div className="mb-4">
                    <label
                      htmlFor="leavingDate"
                      className="block font-bold mb-1"
                    >
                      Leaving Date
                    </label>
                    <input
                      type="date"
                      id="leavingDate"
                      value={dataToEdit.LeavingDate}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          LeavingDate: e.target.value,
                        })
                      }
                      className="w-full border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                
              </div>

              <div>
                <p className="text-l font-semibold">Salary</p>

                <div className="flex space-x-2">
                  <div className="w-1/2">
                    <p className="text-l mt-3 text-gray-600 font-semibold">
                      Basic
                    </p>
                    <input
                      type="text"
                      id="Basic"
                      value={dataToEdit.Basic}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          Basic: e.target.value,
                          HRA: e.target.value / 2,
                          EmployeePfContribution: e.target.value * 0.12,
                          EmployerPfContribution: e.target.value * 0.12,
                        })
                      }
                      placeholder="Basic"
                      className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                    />
                  </div>
                  <div className="w-1/2">
                    <p className="text-l mt-3 text-gray-600 font-semibold">
                      HRAs
                    </p>
                    <input
                      type="text"
                      id="HRA"
                      disabled
                      value={dataToEdit.Basic / 2}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          HRA: e.target.value,
                        })
                      }
                      placeholder="HRA"
                      className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                    />
                  </div>
                  <div className="w-1/2">
                    <p className="text-l mt-3 text-gray-600 font-semibold">
                      Medical Allowance
                    </p>
                    <input
                      type="text"
                      id="MedicalAllowance"
                      value={dataToEdit.MedicalAllowance}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          MedicalAllowance: e.target.value,
                        })
                      }
                      placeholder="Medical Allowance"
                      required
                      className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                    />
                  </div>
                </div>
              </div>

              <div className="flex space-x-2">
                <div className="w-1/2">
                  <p className="text-l text-gray-600 font-semibold">
                    Special Allowance
                  </p>
                  <input
                    type="text"
                    id="SpecialAllowance"
                    value={dataToEdit.SpecialAllowance}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        SpecialAllowance: e.target.value,
                      })
                    }
                    placeholder="Special Allowance"
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>
                <div className="w-1/2">
                  <p className="text-l text-gray-600 font-semibold">
                    Employee PF{" "}
                  </p>
                  <input
                    type="text"
                    id="EmployeePfContribution"
                    disabled
                    value={dataToEdit.Basic * 0.12}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        EmployeePfContribution: e.target.value,
                      })
                    }
                    placeholder="Employee PF Contribution"
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>
                <div className="w-1/2">
                  <p className="text-l text-gray-600 font-semibold">
                    Employer PF
                  </p>
                  <input
                    type="text"
                    id="EmployerPfContribution"
                    value={dataToEdit.Basic * 0.12}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        EmployerPfContribution: e.target.value,
                      })
                    }
                    disabled
                    placeholder="Employer PF Contribution"
                    required
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>
              </div>

              <div className="flex space-x-2">
                <div className="w-1/2">
                  <p className="text-l text-gray-600 font-semibold">Gratuity</p>
                  <input
                    type="text"
                    id="Gratuity"
                    value={dataToEdit.Gratuity}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        Gratuity: e.target.value,
                      })
                    }
                    placeholder="Gratuity"
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>
                <div className="w-1/2">
                  <p className="text-l text-gray-600 font-semibold">
                    Group Insurance{" "}
                  </p>
                  <input
                    type="text"
                    id="GroupInsurance"
                    value={dataToEdit.GroupInsurance}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        GroupInsurance: e.target.value,
                      })
                    }
                    placeholder="Group Insurance"
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>
                <div className="w-1/2">
                  <p className="text-l text-gray-600 font-semibold">
                    Medical Insurance
                  </p>
                  <input
                    type="text"
                    id="MedicalInsurance"
                    value={dataToEdit.MedicalInsurance}
                    onChange={(e) =>
                      setDataToEdit({
                        ...dataToEdit,
                        MedicalInsurance: e.target.value,
                      })
                    }
                    placeholder="Medical Insurance"
                    required
                    className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                  />
                </div>
              </div>

              {dataToEdit.salary.length > 0 && (
                <label className="block font-bold mb-1">Other</label>
              )}
              {dataToEdit?.salary?.map((item, index) => (
                <div key={index} className="flex">
                  <div className="">
                    <input
                      type="text"
                      value={item.name}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          salary: dataToEdit.salary.map((b, i) =>
                            i === index ? { ...b, name: e.target.value } : b
                          ),
                        })
                      }
                      placeholder="Name"
                      className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                    />
                  </div>

                  <div className="ml-1">
                    <input
                      type="number"
                      value={item.value}
                      onChange={(e) =>
                        setDataToEdit({
                          ...dataToEdit,
                          salary: dataToEdit.salary.map((b, i) =>
                            i === index ? { ...b, value: e.target.value } : b
                          ),
                        })
                      }
                      placeholder="Value"
                      className="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
                    />
                  </div>

                  {dataToEdit.salary.length > 0 && (
                    <button
                      type="button"
                      onClick={() => deleteBudgetField(index)}
                      className="rounded ml-1"
                      title="Delete this Budget"
                    >
                      <AiOutlineMinusCircle className="text-4xl mb-6 text-white border rounded-full bg-red-500 hover:bg-red-700" />
                    </button>
                  )}
                </div>
              ))}
              <div className="flex items-center mb-6">
                <input
                  value={`Total: ${calculateTotal()} `}
                  className="w-md px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mr-2"
                />
                <button
                  onClick={addBudgetField}
                  type="button"
                  title="Add more Budgets"
                  className="py-2"
                >
                  <AiOutlinePlusCircle className="text-white hover:bg-blue-700 text-4xl border rounded-full bg-blue-500" />
                </button>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 mr-2 text-gray-600 border rounded hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={UploadEditedDataToDatabase}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllEmployeeBeforeSubmit;
