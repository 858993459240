import { useState } from "react";
import { Spinner } from "../components/Spinner";
import { toast } from "react-toastify";
import {
  getStorage,
  ref as storageReff,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { push, ref } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import { getAuth } from "firebase/auth";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
// import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
export default function Collaboration() {
  const navigate = useNavigate();
  const auth = getAuth();

  // -----------------------------file uploading states-------------------

  const [CollaborationAttachmentsDocuments, setCollaborationAttachmentsDocuments] =
    useState([]);

  const CollaborationAttachmentsDocumentsNames = [];
  const CollaborationAttachmentsDocumentsUrls = [];

  // ----------------------------file uploading states nd variables ---------------------

  const [loading, setLoading] = useState(false);
  const [formData2, setFormData2] = useState({
    Partner1: "",
    Partner2: "",
  });

  const [formData, setFormData] = useState({
    SigningDate: "",
    ExpiringDate: "",
    ZeroDate: "",
    Duration: "",
    CollaborationId:""
  });

  const { CollaborationId, SigningDate, ExpiringDate, ZeroDate, Duration } = formData;

  function onChange(e) {
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.files,
      }));
    }

    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
    }
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData2((prevFormData2) => ({
      ...prevFormData2,
      [id]: value,
    }));
  };

  async function onSubmit(e) {
    e.preventDefault();

    // Call handleUpload() function
    await handleUpload();

    setLoading(true);
    // console.log(formData2);
    const formDataCopy = {
      ...formData,
      CollaborationAttachmentsDocumentsNames,
      CollaborationAttachmentsDocumentsUrls,
      Partner: { ...formData2 },
      //   Total: calculateTotal(),
    };

    const activity = {
      action: "create",
      nameOfLoggedInUser: auth.currentUser.displayName,
      dateAndTime: serverTimestamp(),
      forWhat: "Collaboration",
      ID: formData.CollaborationId,
      // Name: formData.CollaborationName,
    };

    try {
      const docRef = await addDoc(
        collection(db, "collaboration"),
        formDataCopy
      );

      await addDoc(collection(db, "activity"), activity);

      setLoading(false);
      toast.success("Collaboration created successfully");
      setFormData({});
      setFormData2({
        Partner1: "",
        Partner2: "",
      });
      //   setBudget([{ name: "", value: "0" }]);
      //   setCurrency("");
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Failed to save Collaboration data to the database");
    }

    // console.log(formDataCopy);
  }

  const deleteLastTimeline = () => {
    setFormData2((prevFormData2) => {
      const updatedFormData2 = { ...prevFormData2 };
      const timelineKeys = Object.keys(updatedFormData2);
      const lastTimelineKey = timelineKeys[timelineKeys.length - 1];
      delete updatedFormData2[lastTimelineKey];
      return updatedFormData2;
    });
  };

  // -----------------file uploading functions --------------

  const handleFileChange = (event, setDocuments) => {
    const files = event.target.files;
    const documentsArray = Array.from(files);
    setDocuments((prevDocuments) => [...prevDocuments, ...documentsArray]);
  };

  const handleUpload = async () => {
    try {
      setLoading(true);

      const storage = getStorage();

      const uploadDocument = async (documents, folderName, namesArray) => {
        const uploadPromises = documents.map(async (file) => {
          const fileId = uuidv4();
          const fileExtension = file.name.split(".").pop();
          const fileName = `${fileId}.${fileExtension}`;
          const storageRef = storageReff(
            storage,
            `${folderName}/${CollaborationId}/${fileName}`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Calculate the percentage of the upload
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const message = `Uploading ${file.name}: ${progress.toFixed(
                  2
                )}%`;

                // console.log(message);

                // Handle progress or monitoring if needed
              },
              (error) => {
                console.error(`Error uploading ${folderName} document:`, error);
                reject(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref)
                  .then((downloadURL) => {
                    // Store the file name and URL in the corresponding variables
                    namesArray.push(fileName);
                    if (folderName === "collaborationAttachments") {
                      CollaborationAttachmentsDocumentsUrls.push(downloadURL);
                    }

                    resolve();
                  })
                  .catch((error) => {
                    console.error("Error getting download URL:", error);
                    reject(error);
                  });
              }
            );
          });
        });

        await Promise.all(uploadPromises);
      };

      // Upload CollaborationAttachmentsDocuments
      await uploadDocument(
        CollaborationAttachmentsDocuments,
        "collaborationAttachments",
        CollaborationAttachmentsDocumentsNames
      );

      setCollaborationAttachmentsDocuments([]);

      toast.success("Documents uploaded successfully!");
    } catch (error) {
      console.error("Error uploading documents:", error);
      toast.error("Error uploading documents");
    } finally {
      setLoading(false);
    }
  };

  // -----------------------file uploading functions---------------

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="max-w-6xl mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">
        Create Collaboration
      </h1>
      <form onSubmit={onSubmit}>
        {/* Reporting timeline */}
        <div class="w-full md:w-1/5">
            <p class="text-l mt-6 font-semibold">Collaboration ID</p>
            <input
              type="text"
              id="CollaborationId"
              value={CollaborationId}
              onChange={onChange}
              placeholder="Collaboration ID"
              class="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
        <div>
          <p className="text-l font-semibold">Collaboration between</p>

          <div className="flex">
            <div className="flex flex-wrap w-11/12">
              {Object.keys(formData2).map((id, index) => (
                <div
                  key={id}
                  className="w-full sm:w-1/2 md:w-1/4 lg:w-1/5 pt-1 pr-1"
                >
                  <input
                    type="text"
                    id={id}
                    value={formData2[id] || ""}
                    onChange={handleChange}
                    placeholder={`Enter Name Partner ${index + 1} `}
                    className="w-full p-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
                  />
                </div>
              ))}
            </div>

            <div className="flex justify-end my-auto w-1/12">
              <div className="flex flex-col">
                <button
                  type="button"
                  onClick={() => deleteLastTimeline()}
                  className="rounded mb-2"
                  title="Delete"
                >
                  <AiOutlineMinusCircle className="text-4xl text-white border rounded-full bg-red-500 hover:bg-red-700" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const newId = `Partner${
                      Object.keys(formData2).length + 1
                    }`;
                    setFormData2((prevFormData2) => ({
                      ...prevFormData2,
                      [newId]: "",
                    }));
                  }}
                  className=""
                >
                  <AiOutlinePlusCircle
                    className="text-4xl text-white border rounded-full bg-blue-500 hover:bg-blue-700"
                    title="Add more timelines"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col md:flex-row md:flex-wrap space-x-1">

          <div class="w-full md:w-1/5 ">
            <p class="text-l mt-6 font-semibold">Signing Date</p>
            <input
              type="date"
              id="SigningDate"
              value={SigningDate}
              onChange={onChange}
              class="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          <div class="w-full md:w-1/5 ">
            <p class="text-l mt-6 font-semibold">Expiring Date</p>
            <input
              type="date"
              id="ExpiringDate"
              value={ExpiringDate}
              onChange={onChange}
              class="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          <div class="w-full md:w-1/5 ">
            <p class="text-l mt-6 font-semibold">Zero Date</p>
            <input
              type="date"
              id="ZeroDate"
              value={ZeroDate}
              onChange={onChange}
              class="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>

          <div class="w-full md:w-1/5 ">
            <p class="text-l mt-6 font-semibold">Duration</p>
            <input
              type="number"
              id="Duration"
              value={Duration}
              onChange={onChange}
              placeholder="Duration in month"
              class="w-full px-4 py-2 text-l text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
            />
          </div>
        </div>

        {/* file upload */}

        {/* ----------------------file uploa input fields------------ */}

        <>
          <div className="flex flex-wrap mb-4">
            <div className="w-1/2 pr-2 mb-4">
              <p className="mb-2">Collaboration Attachments</p>
              <input
                className="border border-gray-300 py-2 px-4 rounded-lg w-full"
                type="file"
                multiple
                onChange={(e) =>
                  handleFileChange(e, setCollaborationAttachmentsDocuments)
                }
              />
              {/* Render the uploaded proposal attachments files */}
              {CollaborationAttachmentsDocuments.map((file) => (
                <div key={file.name}>{file.name}</div>
              ))}
            </div>
          </div>
        </>

        {/* --------------------file upload input fields------------------ */}

        <div className="mx-auto max-w-md">
          <button
            type="submit"
            className=" mb-6 w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mx-auto"
          >
            Save
          </button>
        </div>
      </form>
    </main>
  );
}